import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 14px;
  align-items: flex-end;
  grid-column: span 2;

  @media only screen and (max-width: 500px) {
    grid-column: unset;
  }
`;
