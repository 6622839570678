import { IInputMask, InputMask } from '../InputMask';

import React from 'react';

export const CurrencyInput = React.forwardRef((props: IInputMask, ref) => {
  return (
    <InputMask
      {...props}
      placeholder={props.placeholder || 'R$0.00'}
      thousandSeparator={true}
      prefix={'R$'}
      ref={ref}
    />
  );
});

CurrencyInput.displayName = 'CurrencyInput';
export default CurrencyInput;
