import styled from 'styled-components';

export interface IColorButton {
  background: string;
  textColor: string;
}

export const ColorButton = styled.button<IColorButton>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${({ background }) => background || '#7764e4'};
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;

  font-family: Roboto;
  font-weight: bold;
  font-size: 10px;
  color: ${({ textColor }) => textColor || '#fff'};
`;

interface IButtonOption {
  active?: boolean;
}

export const ButtonOption = styled.button<IButtonOption>`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #fff;
  border: 0.5px solid ${(props) => (props.active ? '#7764e4' : '#F5F5F5')};
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 10px;
    height: 10px;
    color: #6e6e6e;
  }

  & * {
    pointer-events: none;
  }
`;

export const MoreOptionsContainer = styled.div`
  position: relative;
`;

interface IFloatOptionsProps {
  isMoreActionsMenuInverted?: boolean;
  showMoreActions?: boolean;
}

export const FloatOptions = styled.div<IFloatOptionsProps>`
  display: ${(props) => (props.showMoreActions ? 'flex' : 'none')};
  width: 120px;
  padding: 6px;
  flex-direction: column;
  position: absolute;
  ${({ isMoreActionsMenuInverted }) => (isMoreActionsMenuInverted ? 'bottom: 25px;' : 'top: -5px;')}
  right: 0;
  background: #fff;
  z-index: 1;
  border-radius: 6px;
  border: 0.5px solid #eae7e7;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  margin-top: 30px;

  & button {
    width: 100%;
    border: none;
    display: flex;
    gap: 6px;
    font-family: Roboto;
    font-weight: normal;
    font-size: 10px;
    color: #6e6e6e;
    background: #fff;
    border-radius: 4px;
    padding: 6px;
    & svg {
      width: 10px;
      height: 10px;
    }
  }
  & button:hover {
    background: #f5f5f5;
  }
  & button:last-child {
    color: #ef4343;
  }
`;
