import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import { Container, Label, ContainerInput, ContainerItems, SelectItem, IContainer } from './style';

export interface IEvent {
  text: string;
  itemsFiltered: any[];
  type: 'click' | 'typing';
  item?: any;
}

interface ILocalValue {
  type: 'click' | 'typing';
  value: string;
  item?: any;
}

interface IFrameInput extends IContainer {
  label: string | number;
  items: any[];
  itemText: string;
  value?: string;
  onChange?: (event: IEvent) => void;
  type?: string;
  placeholder?: string;
  name?: string;
  required?: boolean;
  pattern?: string;
  mask?: string;
}

export const FrameAutoComplete = ({
  label,
  type,
  value,
  size,
  onChange,
  margin,
  placeholder,
  name,
  required,
  pattern,
  mask,
  items,
  itemText,
}: IFrameInput) => {
  const [focused, setFocused] = useState(false);
  const [localValue, setLocalValue] = useState<ILocalValue>({
    type: 'typing',
    value: value || '',
  });
  const [filterItems, setFilterItems] = useState(items);

  function handleSetValue(event: React.ChangeEvent<HTMLInputElement>) {
    const { value: vLocal } = event.target;

    setLocalValue({
      value: vLocal,
      type: 'typing',
    });
  }

  function handleClick(item: any) {
    setLocalValue({
      type: 'click',
      value: item[itemText],
      item,
    });
  }

  useEffect(() => {
    const filter = items.filter((item) => `${item[itemText]}`.startsWith(`${localValue}`));

    if (onChange) {
      onChange({
        text: localValue.value,
        type: localValue.type,
        itemsFiltered: filter,
        item: localValue.item,
      });
    }

    setFilterItems(filter);

    return () => setFilterItems([]);
  }, [localValue]);

  useEffect(() => {
    setFilterItems(items);
  }, [items]);

  return (
    <Container size={size} margin={margin}>
      <Label>{label}</Label>

      <ContainerInput>
        {mask ? (
          <InputMask
            mask={mask}
            required={required}
            name={name}
            placeholder={placeholder}
            value={localValue.value}
            onChange={handleSetValue}
            pattern={pattern}
            onFocus={() => setFocused(true)}
            onBlur={() => {
              setTimeout(() => {
                setFocused(false);
              }, 800);
            }}
          />
        ) : (
          <input
            required={required}
            name={name}
            placeholder={placeholder}
            value={localValue.value}
            onChange={handleSetValue}
            pattern={pattern}
            type={type || 'text'}
            onFocus={() => setFocused(true)}
            onBlur={() => {
              setTimeout(() => {
                setFocused(false);
              }, 800);
            }}
          />
        )}

        {focused && (
          <ContainerItems>
            {filterItems.map((item, index) => (
              <SelectItem onClick={() => handleClick(item)} key={index}>
                {item[itemText]}
              </SelectItem>
            ))}
          </ContainerItems>
        )}
      </ContainerInput>
    </Container>
  );
};
