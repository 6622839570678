import React from 'react';
import { Container, ContentContainer, InfoText } from './styles';
import { IoShapesOutline } from 'react-icons/io5';

interface IEmptyData {
  icon?: () => JSX.Element;
  noPadding?: boolean;
  small?: boolean;
}

export const EmptyData: React.FC<IEmptyData> = (props) => {
  const { children, icon: Icon, noPadding, small } = { ...props };
  return (
    <ContentContainer noPadding={noPadding}>
      <Container>
        {!!Icon ? <Icon /> : <IoShapesOutline />}
        <InfoText small={small}>{children}</InfoText>
      </Container>
    </ContentContainer>
  );
};
