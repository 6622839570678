import Input, { IInput } from '../..';

import React from 'react';

export const CNPJInput = React.forwardRef((props: IInput, ref) => {
  return (
    <Input
      {...props}
      mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      ref={ref}
    />
  );
});

CNPJInput.displayName = 'CNPJInput';
export default CNPJInput;
