import styled from 'styled-components';
import { props_place_4, props_place_5 } from '../../methods';

interface IContainer {
  margin?: string;
  place?: string;
  stretch?: number;
  type: string;
}

export const Container = styled.div<IContainer>`
  color: #fff;
  ${(props) => (props.margin ? `margin: ${props.margin};` : '')}
  grid-column: ${(props) => (props.place ? props.place : '')} ${(props) =>
    props.stretch ? `span ${props.stretch}` : ''};
  @media (max-width: 1610px) {
    grid-column: ${(props) => (props.place ? props_place_5(props.place) : '')};
    ${(props) => (props.stretch ? `span ${props.stretch}` : '')};
  }
  @media (max-width: 1030px) {
    grid-column: ${(props) => (props.place ? props_place_4(props.place) : '')};
    ${(props) => (props.stretch ? `span ${props.stretch}` : '')};
  }
  @media only screen and (max-width: 500px) {
    grid-column: unset;
  }
  align-self: end;
  ${({ type }) => (type === 'textarea' ? 'grid-row: span 2' : '')};

  position: relative;
`;

export const Label = styled.label`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: #6e6e6e;
  font-weight: normal;
  display: inline-block;
  margin-bottom: 4px;

  @media (max-width: 1610px) {
    font-size: 10px;
  }
`;

interface IContainerInput {
  type: string;
  invert?: boolean;
  error?: boolean;
  disabled?: boolean;
}

export const ContainerInput = styled.div<IContainerInput>`
  margin-bottom: unset !important;
  background-color: #f5f5f5;
  color: #a8a8a8;
  margin-top: unset !important;
  display: flex !important;
  height: ${({ type }) => (type === 'textarea' ? 118 : 40)}px;
  font-size: 12px;
  align-items: center;
  position: relative;
  ${(props) => props.error && `border: 1px solid #EF4343`};
  border: 0.8px solid #eae7e7;

  &,
  & input {
    border-radius: 10px;
  }

  &:hover {
    border-color: ${({ disabled }) => (disabled ? '#eae7e7' : '#7764e4')};
  }

  & input {
    padding-left: 10px;
    padding-right: 10px;
    font-family: 'Roboto', sans-serif !important;
    font-size: 12px;
    margin-bottom: unset !important;
    color: #6e6e6e;
    background-color: transparent;
    width: 100%;
    height: 100%;
    border: none;
    flex: 1;
  }

  & input:disabled {
    cursor: not-allowed !important;
  }
  & input::placeholder {
    color: #a8a8a8;
  }
  & input:focus {
    outline: none;
    border: none;
  }
  & input[type='text'] {
    border-bottom: unset !important;
    box-shadow: unset !important;
  }
  & input[type='text']:focus {
    border-bottom: unset !important;
    box-shadow: unset !important;
  }
  & input::-webkit-calendar-picker-indicator {
    filter: invert(0);
  }

  @media (max-width: 1610px) {
    height: ${({ type }) => (type === 'textarea' ? 108 : 35)}px;

    & input {
      font-size: 10px;
    }
  }
`;

export const HelperContainer = styled.label`
  color: #f44336;
  text-align: left;
  line-height: 1.65;
  letter-spacing: 0.033em;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.7rem;
  margin: 0 !important;
  margin-top: 3px;

  p {
    margin-top: 0 !important;
    margin-bottom: 10px;
  }
`;

export const InputTextArea = styled.textarea`
  resize: none;
  height: 100%;
  padding: 10px;
  font-family: 'Roboto', sans-serif !important;
  font-size: 12px;
  margin-bottom: unset !important;
  color: #6e6e6e;
  background-color: transparent;
  width: 100%;
  border: none;
`;
export const ExtraButtons = styled.div`
  display: flex;
  gap: 8px;
  position: absolute;
  right: 10px;
  align-self: center;

  & button {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background: #fff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      color: #7764e4;
      width: 10px;
      height: 10px;
    }
  }
`;
export const FloatContaner = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 70px;
  width: 100%;
  z-index: 10;
`;
export const Point = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7px 10px 7px;
  border-color: transparent transparent #ef4343 transparent;
  margin-left: 15px;
  filter: drop-shadow(0px -2px 2px rgba(239, 67, 67, 0.15));
`;

export const FloatInfo = styled.div`
  display: flex;
  padding: 8px;
  border-radius: 6px;
  gap: 8px;
  background-color: #ef4343;
  box-shadow: 0px 0px 3px rgba(239, 67, 67, 0.3);

  & svg {
    width: 30px;
    height: 30px;
    color: white;
  }

  & label {
    width: calc(100% - 30px);
    font-family: Roboto;
    font-weight: bold;
    font-size: 10px;
    color: white;
  }
`;
