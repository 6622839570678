import styled from 'styled-components';

export const Form = styled.form`
  margin: 0;
  padding: 0;
`;

export const RadioSearchContainer = styled.div`
  color: #6e6e6e;
  align-items: center;

  & input {
    margin-right: 2px;
  }
`;
