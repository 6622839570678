import { useState } from 'react';
import useDebounce from './useDebounce';

type ValueOrCallback<Type> = Type | (() => Type) | undefined;

function useStateWithDebounce<Type = any>(valueOrCallback?: ValueOrCallback<Type>, delay?: number) {
  const [state, setState] = useState<ValueOrCallback<Type>>(valueOrCallback);
  const setStateWithDebounce = useDebounce<React.Dispatch<React.SetStateAction<ValueOrCallback<Type>>>>(
    setState,
    delay,
  );

  return [state, setStateWithDebounce] as const;
}

export default useStateWithDebounce;
