import style from 'styled-components';

export interface IContainer {
  size?: string;
  margin?: string | number;
}

export const Container = style.div<IContainer>`
  color: #ffffff;
  // width: ${(props) => props.size || 'calc(50% - 7px)'};
  flex: 40%;
  min-width: 200px;
  margin: ${(props) => props.margin || '0px'};
`;

export const Label = style.label`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: #6e6e6e;
  font-weight: normal;
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const ContainerInput = style.div`
  margin-bottom: unset !important;
  background-color: #f5f5f5;
  color: #a8a8a8;
  border-radius: 10px;
  margin-top: unset !important;
  display: flex !important;
  height: 40px !important;
  font-size: 12px !important;

  & input {
    padding-left: 10px;
    padding-right: 10px;
    font-family: 'Roboto', sans-serif !important;
    font-size: 12px !important;
    margin-bottom: unset !important;
    color: #6e6e6e;
    background-color: transparent;
    width: 100%;
    border: none;
    border: none;
  }

  & input:disabled {
    cursor: not-allowed !important;
  }
  & input::placeholder {
    color: #a8a8a8;
  }
  & input:focus {
    outline: none;
    border: none;
  }
  & input[type='text'] {
    border-bottom: unset !important;
    box-shadow: unset !important;
  }
  & input[type='text']:focus {
    border-bottom: unset !important;
    box-shadow: unset !important;
  }
  & input::-webkit-calendar-picker-indicator {
    filter: invert(0);
  }
}
`;
