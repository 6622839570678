import React from 'react';
import * as SC from './styles';
import SimpleBar from 'simplebar-react';

interface ITableBodyProps {
  height: number;
  children?: React.ReactNode;
  onScroll?: React.UIEventHandler<HTMLElement>;
}
interface ISetScroll {
  top?: number;
  left?: number;
}

export interface ITableBodyRef extends HTMLDivElement {
  setScroll?(scroll: ISetScroll): void;
}

export const TableBody = React.memo(
  React.forwardRef<ITableBodyRef, ITableBodyProps>((props, ref) => {
    const { children, onScroll, height } = { ...props };

    const localRef = React.useRef<SimpleBar>();
    const [isMounted, setIsMounted] = React.useState(false);

    React.useImperativeHandle(
      ref,
      () => {
        const el: any = localRef.current?.el;

        if (!el) return;

        el.setScroll = ({ top, left }: ISetScroll) => {
          const divScroll = el.querySelector('.simplebar-content-wrapper');

          if (!divScroll) return;

          divScroll.scrollTo({ top, left });
        };

        return el;
      },
      [isMounted],
    );

    React.useEffect(() => setIsMounted(true), []);

    return (
      <SC.ChildrenContainer ref={localRef} onScrollCapture={onScroll}>
        <div style={{ minHeight: `${height}px` }}>{children}</div>
      </SC.ChildrenContainer>
    );
  }),
);

TableBody.displayName = 'TableBody';
