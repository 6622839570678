import styled from 'styled-components';

export const RadioSearchContainer = styled.div`
  color: #6e6e6e;
  align-items: center;
  padding-bottom: 14px;
  margin-top: -14px;
  grid-column: span 2;

  @media only screen and (max-width: 500px) {
    grid-column: unset;
  }

  & input {
    margin-right: 2px;
  }
`;
