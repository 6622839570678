/* eslint-disable no-underscore-dangle */
import React, { createRef, useEffect } from 'react';

import { Marker as MarkerLeaflet } from 'react-leaflet';
import { Icon } from 'leaflet';
import { defaultMarkerIcon } from './style';

interface IMarker {
  coords: [number, number];
  icon?: Icon;
  selectedIcon?: Icon;
  isSelected?: boolean;
  onClick?: (event: any) => void;
  data?: any;
  children?: React.ReactNode;
}

export const Marker = ({ children, coords, icon, selectedIcon, onClick, isSelected, data }: IMarker) => {
  const markerRef = createRef<any>();

  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.leafletElement.data = data;
      markerRef.current.leafletElement.latlng = coords;
      markerRef.current.leafletElement.normalIcon = icon;
      markerRef.current.leafletElement.selectedIcon = selectedIcon;
      markerRef.current.leafletElement.idLayer = markerRef.current.leafletElement._leaflet_id;
    }

    if (isSelected && selectedIcon) {
      markerRef.current.leafletElement.setIcon(selectedIcon);
    } else if (icon) {
      markerRef.current.leafletElement.setIcon(icon);
    }
  }, [markerRef, icon, selectedIcon, isSelected, data, coords]);

  return (
    <MarkerLeaflet onClick={onClick} ref={markerRef} position={coords} icon={icon || defaultMarkerIcon}>
      {children}
    </MarkerLeaflet>
  );
};

export default Marker;
