import React from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter, Switch } from 'react-router-dom';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import { checkUserLogged } from './guards/checkUserLogged';
import { NotFound, PageError } from '../pages';
import { routes } from './routes';

export const prefix = '';

export const Router = () => {
  return (
    <BrowserRouter>
      <GuardProvider guards={[checkUserLogged]} error={PageError}>
        <Switch>
          {routes.map((route) => (
            <GuardedRoute
              key={route.name}
              path={`${prefix}${route.path}`}
              exact={route.exact}
              component={route.component}
              meta={route.meta}
            />
          ))}

          <GuardedRoute path="*" component={NotFound} />
        </Switch>
      </GuardProvider>
    </BrowserRouter>
  );
};

export default Router;

export const history = createBrowserHistory();
