import L from 'leaflet';
import { createLIconPulse } from './pulse/L.Icon.Pulse';
import blueMarkerIcon from './images/marker-icon.png';
import alertMarkerIcon from './images/alertIcon.svg';
import alertMarkerIconPink from './images/alertIconPink.svg';
import alertMarkerIconYellow from './images/alertIconYellow.svg';
import alertMarkerIconGreen from './images/alertIconGreen.svg';
import alertMarkerCircleIconBlack from './images/alertCircleBlack.svg';
import alertMarkerCircleIconRed from './images/alertCircleRed.svg';
import alertMarkerCircleIconGreen from './images/alertCircleGreen.svg';
import alertMarkerCircleIconYellow from './images/alertCircleYellow.svg';

export const defaultMarkerIcon = new L.Icon({
  iconUrl: blueMarkerIcon,
  iconRetinaUrl: blueMarkerIcon,
  iconSize: [20, 30],
});

export const alertIconCircleBlack = new L.Icon({
  iconUrl: alertMarkerCircleIconBlack,
  iconRetinaUrl: alertMarkerCircleIconBlack,
  iconSize: [20, 20],
});

export const alertIconCircleRed = new L.Icon({
  iconUrl: alertMarkerCircleIconRed,
  iconRetinaUrl: alertMarkerCircleIconRed,
  iconSize: [20, 20],
});

export const alertIconCircleGreen = new L.Icon({
  iconUrl: alertMarkerCircleIconGreen,
  iconRetinaUrl: alertMarkerCircleIconGreen,
  iconSize: [20, 20],
});

export const alertIconCircleYellow = new L.Icon({
  iconUrl: alertMarkerCircleIconYellow,
  iconRetinaUrl: alertMarkerCircleIconYellow,
  iconSize: [20, 20],
});

export const alertIconBlack = new L.Icon({
  iconUrl: alertMarkerIcon,
  iconRetinaUrl: alertMarkerIcon,
  iconSize: [20, 30],
});

export const alertIconPink = new L.Icon({
  iconUrl: alertMarkerIconPink,
  iconRetinaUrl: alertMarkerIconPink,
  iconSize: [20, 20],
});

export const alertIconYellow = new L.Icon({
  iconUrl: alertMarkerIconYellow,
  iconRetinaUrl: alertMarkerIconYellow,
  iconSize: [20, 20],
});

export const alertIconGreen = new L.Icon({
  iconUrl: alertMarkerIconGreen,
  iconRetinaUrl: alertMarkerIconGreen,
  iconSize: [20, 20],
});

export const pulsingIcon: L.Icon = createLIconPulse({
  iconSize: [20, 20],
  color: 'red',
});
