import styled from 'styled-components';

interface ICheckButton {
  checked?: boolean;
}

export const CheckButton = styled.button<ICheckButton>`
  border: none;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.primary};

  & svg {
    width: 10px;
    height: 10px;
    color: ${({ checked, theme }) => (checked ? theme.colors.greendeep : theme.colors.secondary)};
  }
`;

export const Container = styled.div`
  border-right: 1px solid ${(props) => props.theme.colors.border};
  display: flex;
  align-items: center;
  width: 45px;
`;
