import { IInput, Input } from '../..';

import React from 'react';

export const TimeInput = React.forwardRef((props: IInput, ref) => {
  return <Input {...props} type="time" step="1" ref={ref} />;
});

TimeInput.displayName = 'TimeInput';
export default TimeInput;
