import React from 'react';

import { Container, FrameContainer } from './style';
import { LoadingComponent } from 'components/Loading';

interface IFrameData {
  children?: React.ReactNode;
  loading?: boolean;
}

export const FrameData = ({ children, loading }: IFrameData) => {
  return (
    <Container className="sidemodal">
      {loading && <LoadingComponent absolute borderRadius={26} background="#f5f5f5" size={80} color="#7764e4" />}
      <FrameContainer>{children}</FrameContainer>
    </Container>
  );
};
