import styled from 'styled-components';

export interface IContainer {
  absolute?: boolean;
  relative?: boolean;
  fixed?: boolean;
  background?: string;
  borderRadius?: number;
  opacity?: '0.1' | '0.2' | '0.3' | '0.4' | '0.5' | '0.6' | '0.7' | '0.8' | '0.9' | '1';
}

export const Container = styled.div<IContainer>`
  ${({ absolute, fixed, relative }) => (absolute && !fixed && !relative ? 'position: absolute' : '')};
  ${({ fixed, absolute, relative }) => (fixed && !absolute && !relative ? 'position: fixed' : '')};
  ${({ fixed, absolute, relative }) => (relative && !fixed && !absolute ? 'position: relative' : '')};
  ${({ borderRadius }) => (borderRadius ? `border-radius: ${borderRadius}px;` : '')}
  background-color: ${({ background }) => background || 'transparent'};
  opacity: ${({ opacity }) => opacity || '0.5'};

  display: flex;

  z-index: 13;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export interface ILoader {
  size?: number;
  color?: string;
  usePurpleColor?: boolean;
  button?: boolean;
}

export const Loader = styled.div<ILoader>`
  width: ${({ size }) => (size ? `${size}px` : '22px')} !important;
  height: ${({ size }) => (size ? `${size}px` : '22px')} !important;

  border: 3px solid ${({ color, usePurpleColor, theme }: any) => (usePurpleColor ? '#7764e4' : color || '#f3f3f3')};
  border-radius: 50%;
  border-top: 3px solid transparent;

  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
