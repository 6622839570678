import React from 'react';
import { Router as RouterProvider } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import { ToastProvider } from './hooks/toast';
import { Router, history } from './router';
import { IframeProvider } from './contexts/IframeContext';
import './styles/global.css';

function App() {
  return (
    <IframeProvider>
      <ToastProvider>
        <RouterProvider history={history}>
          <Router />
        </RouterProvider>
      </ToastProvider>
      <ToastContainer autoClose={4000} position="bottom-left" limit={5} transition={Slide} />
    </IframeProvider>
  );
}

export default App;
