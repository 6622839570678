import { Container, ContainerInput, HelperContainer, Label } from '../../style';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import React, { useRef } from 'react';

export interface IInputMask extends NumberFormatProps {
  label?: string;
  helperText?: string;
  value?: string;
  handleFunction?: (event) => void;
  name?: string;
  placeholder?: string;
}

export const InputMask = React.forwardRef((props: IInputMask, ref) => {
  const { label, helperText, type, value, handleFunction, name, placeholder, ...otherProps } = props;

  const defaultRef = useRef();
  const resolvedRef: any = ref || defaultRef;

  return (
    <Container type={type || 'text'}>
      <Label>{label}</Label>

      <ContainerInput type={type || 'text'}>
        <NumberFormat
          getInputRef={resolvedRef}
          name={name}
          value={value}
          onChange={handleFunction}
          placeholder={placeholder}
          type={type || 'text'}
          {...otherProps}
        />
      </ContainerInput>
      <HelperContainer>{helperText && <p>{helperText}</p>}</HelperContainer>
    </Container>
  );
});

InputMask.displayName = 'InputMask';
export default InputMask;
