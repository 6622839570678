import { IFixResponse, api, apiExternal, IApiResponse } from '../index';
export interface ITipoAvaliacao {
  seq_tipo_avaliacao: number;
  nom_tipo_avaliacao: string;
}

export async function criarAvaliacao(dados: any): Promise<IFixResponse> {
  const res: any = await api.post('/reclamante/app_prefeitura/avaliacao/atendimento/avaliar', dados);

  if (res.failed) {
    if (res.message) {
      return { err: res.message, data: null };
    }
    return { err: res.err, data: null };
  }

  const { data } = res;
  return { err: null, resp: data };
}

export async function criarAvaliacaoPublico(dados: any): Promise<IFixResponse> {
  const res: any = await api.post('/external/ocorrencia/avaliacao', dados);

  if (res.failed) {
    if (res.message) {
      return { err: res.message, data: null };
    }
    return { err: res.err, data: null };
  }

  const { data } = res;
  return { err: null, resp: data };
}

export async function getAvaliacaoByAtendimento(codAtendimento: number): Promise<IFixResponse> {
  const res: any = await api.get(`/reclamante/ocorrencia/avaliacao/${codAtendimento}`);

  if (res.failed) {
    if (res.message) {
      return { err: res.message, data: null };
    }
    return { err: res.err, data: null };
  }

  const { data } = res;
  return { err: null, resp: data };
}

export async function getAvaliacaoByAtendimentoPublico(token: string): Promise<IFixResponse> {
  const res: any = await api.get(`/external/ocorrencia/avaliacao/${token}`);

  if (res.failed) {
    if (res.message) {
      return { err: res.message, data: null };
    }
    return { err: res.err, data: null };
  }

  const { data } = res;
  return { err: null, resp: data };
}

export async function getTipoAvaliacao(): Promise<IApiResponse<ITipoAvaliacao[]>> {
  return await apiExternal.get('/external/ocorrencia/tipo_avaliacao');
}
