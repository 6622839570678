import { BiHide, BiShow } from 'react-icons/bi';

import styled from 'styled-components';

export const ShowIcon = styled(BiShow)`
  font-size: 20px;
`;

export const HideIcon = styled(BiHide)`
  font-size: 20px;
`;

export const VisibleContainer = styled.div`
  padding: 10px;
  margin-top: 3px;
  position: relative;
  cursor: pointer;
  user-select: none;
  color: #000;
`;
