import Input, { IInput } from '../..';

import React from 'react';

export const CPFInput = React.forwardRef((props: IInput, ref) => {
  return (
    <Input
      {...props}
      mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/]}
      ref={ref}
    />
  );
});

CPFInput.displayName = 'CPFInput';
export default CPFInput;
