import React, { useState } from 'react';
import { FrameData } from '../../components/FrameData';
import { RouteComponentProps } from 'react-router-dom';
import { prefix } from '../../router';
import { resetPasswordReclamante } from '../../api/services/Reclamante';
import { useToast } from '../../hooks/toast';
import { AutoRow } from 'components/AutoRow';
import { Title } from 'components/AutoRow/components/Title';
import { Paragraph } from 'components/AutoRow/components/Paragraph';
import Input from 'components/AutoRow/components/Input';
import { Button } from 'components/AutoRow/components/Button';

const IframePassword = ({ history }: RouteComponentProps) => {
  const toast = useToast();
  const [email, setEmail] = useState<string>('');

  function handleCancel() {
    history.push(`${prefix}/`);
  }

  async function handleForgotPassword(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!email) {
      toast.addToast({ type: 'warn', description: 'Email invalido!' });
    }
    const response = await resetPasswordReclamante({ email });

    if (response.failed) {
      return toast.addToast({
        type: 'warn',
        description: response.message || 'Ocorreu um erro ao recuperar a senha do usuario!',
      });
    }

    toast.addToast({
      type: 'success',
      description: 'Foi enviado um email de recuperação de senha para sua caixa de entrada!',
    });

    return handleCancel();
  }

  return (
    <FrameData>
      <form onSubmit={handleForgotPassword}>
        <AutoRow>
          <Title label="Recuperar Senha" />
          <Paragraph
            marginBottom={26}
            marginTop={-4}
            label="Informe o email do cadastro, será enviado um link para realizar a recuperação"
          />
          <Input
            handleFunction={(event: any) => setEmail(event.target.value)}
            label="Email"
            type="email"
            required
            stretch={2}
            value={email}
          />
          <Button onClick={handleCancel} backgroundColor="light">
            Cancelar
          </Button>
          <Button type="submit">Enviar</Button>
        </AutoRow>
      </form>
    </FrameData>
  );
};

export default IframePassword;
