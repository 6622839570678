import styled from 'styled-components';

export const LineRow = styled.div`
  width: 100%;
  display: flex;
  gap: 14px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;
