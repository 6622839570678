import styled from 'styled-components';
import { props_place_4, props_place_5 } from '../AutoRow/methods';

interface IContainer {
  margin?: string;
  place?: string;
  stretch?: number;
  hidden?: boolean;
  obra?: boolean;
}

export const Container = styled.div<IContainer>`
  color: '#ffffff';
  ${(props) => (props.margin ? `margin: ${props.margin};` : '')}
  grid-column: ${(props) => (props.place ? props.place : '')} ${(props) =>
    props.stretch ? `span ${props.stretch}` : ''};
  @media (max-width: 1610px) {
    grid-column: ${(props) => (props.place ? props_place_5(props.place) : '')}
      ${(props) => (props.stretch ? `span ${props.stretch}` : '')};
  }
  @media (max-width: 1030px) {
    grid-column: ${(props) => (props.place ? props_place_4(props.place) : '')}
      ${(props) => (props.stretch ? `span ${props.stretch}` : '')};
  }
  @media (max-width: 770px) {
    grid-column: unset;
  }
  @media (max-width: 430px) {
    grid-column: unset;
  }
  align-self: end;

  & .react-select__value-container {
    min-height: ${(props) => (props.obra ? 20 : 40)}px;
    max-height: ${(props) => (props.obra ? 20 : 40)}px;
    overflow-y: auto;

    @media (max-width: 1610px) {
      min-height: ${(props) => (props.obra ? 20 : 35)}px;
      max-height: ${(props) => (props.obra ? 20 : 35)}px;
    }
  }

  visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
`;
export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;
export const Label = styled.label`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: '#6e6e6e';
  font-weight: normal;
  display: inline-block;
  margin-bottom: 4px;

  @media (max-width: 1610px) {
    font-size: 10px;
  }
`;
