// docs react-leaflet https://react-leaflet.js.org/legacy/docs/en/components#zoomcontrol
import React from 'react';
import * as L from 'leaflet';
import { Map as MapContainer, TileLayer, ZoomControl, MapProps } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import { Container, IContainer, GlobalMapStyle } from './style';

export interface IMap extends IContainer {
  coords: [number, number];
  children?: React.ReactNode;
  zoom: number;
  scrollWheelZoom?: boolean;
  layerType?: 1 | 2 | 3 | 4;
  refMapElement?: any;
  doubleClickZoom?: boolean;
  onClick?: any;
  maxZoom?: number;
  minZoom?: number;
}

export type IMapContainer = MapContainer<MapProps, L.Map>;

export const Map = ({
  coords,
  zoom,
  scrollWheelZoom,
  children,
  height,
  width,
  maxHeight,
  minHeight,
  maxWidth,
  minWidth,
  resize,
  margin,
  positionResize,
  refMapElement,
  doubleClickZoom = true,
  layerType = 1,
  onClick,
  maxZoom,
  minZoom,
}: IMap) => {
  const layersMap = [
    'http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
    'http://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png',
    'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  ];

  return (
    <Container
      margin={margin}
      positionResize={positionResize}
      resize={resize}
      minHeight={minHeight}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      minWidth={minWidth}
      height={height}
      width={width}
    >
      <GlobalMapStyle />

      <MapContainer
        ref={refMapElement}
        center={coords}
        zoom={zoom}
        scrollWheelZoom={scrollWheelZoom}
        zoomControl={false}
        doubleClickZoom={doubleClickZoom}
        onClick={onClick}
        maxZoom={maxZoom}
        minZoom={minZoom}
      >
        <TileLayer url={layersMap[layerType - 1]} />
        <ZoomControl position="bottomright" />

        {children}
      </MapContainer>
    </Container>
  );
};

export default Map;
export { Marker } from './Marker';
