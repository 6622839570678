export default function (querys = {}) {
  let query = '';

  for (const key in querys) {
    const value = (querys as any)[key];

    if (value && value !== 'DEFAULT') query = query ? `${query}&${key}=${value}` : `${key}=${value}`;
  }

  return query;
}
