import React from 'react';
import { Container } from './styles';

interface IRowCompatibility {
  specifyColumn?: string[];
  specifyRow?: string[];
  noBorder?: boolean;
  margin?: string;
}

export const RowCompatibility: React.FC<IRowCompatibility> = (props) => {
  const { children, specifyColumn, specifyRow, noBorder, margin } = { ...props };
  return (
    <Container specifyColumn={specifyColumn} specifyRow={specifyRow} noBorder={noBorder} margin={margin}>
      {children}
    </Container>
  );
};
