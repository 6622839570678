import React from 'react';
import { LineRow } from './styles';

interface IRow {
  children?: React.ReactNode;
}

export const Row = ({ children }: IRow) => {
  return <LineRow>{children}</LineRow>;
};
