/* eslint-disable consistent-return */

import { Form } from './styles';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { FrameData } from '../../components/FrameData';
import { IReclamante } from '../../dtos/Reclamante';
import { loginReclamante } from '../../api/services/Reclamante';
import { prefix } from '../../router';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useToast } from '../../hooks/toast';
import { verifyParqueExists } from '../../api/services/ParqueService';
import { AutoRow } from 'components/AutoRow';
import Input from 'components/AutoRow/components/Input';
import { Button } from 'components/AutoRow/components/Button';
import { Title } from 'components/AutoRow/components/Title';
import { Paragraph } from 'components/AutoRow/components/Paragraph';
import { HoverClick } from 'components/AutoRow/components/HoverClick';
import { FrameInput } from 'components/FrameInput';

export function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

const IframeLogin = ({ history }: RouteComponentProps) => {
  const [accessToken, setAccessToken] = useLocalStorage<string | undefined>('accessToken', '');
  const [refreshToken, setRefreshToken] = useLocalStorage<string | undefined>('refreshToken', '');
  const [codParque, setCodParqueServico] = useLocalStorage<number>('cod_parque_servico', -1);
  const [parqueIsValid, setParqueIsValid] = useState(true);
  const [, setDadosReclamante] = useLocalStorage<IReclamante | string>('reclamante', '');
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [dadosLogin, setDadosLogin] = useState({
    email: '',
    password: '',
  });
  const toast = useToast();
  const query = useQuery();

  function handleSetState(event: React.ChangeEvent<HTMLInputElement>) {
    const { value, name } = event.target;
    setDadosLogin((oldValue) => ({ ...oldValue, [name]: value }));
  }

  async function handleLogin(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setLoadingSubmit(true);
    const res = await loginReclamante(dadosLogin);
    setLoadingSubmit(false);

    if (res.failed) {
      return toast.addToast({ type: 'error', description: res.message });
    }

    if (!res.data || !res.data.complainant) {
      return toast.addToast({ type: 'error', description: 'Usuário ou senha inválidos!' });
    }

    const { 'x-access-token': accessToken, 'x-refresh-token': refreshToken } = res.headers;

    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    setDadosReclamante(res.data.complainant);

    toast.addToast({
      type: 'success',
      description: 'Usuário logado com sucesso!',
    });

    history.push(`${prefix}/home`);
  }

  function handleRedirectToCreateUser() {
    history.push(`${prefix}/new-account`);
  }

  function handleRedirectForgotPassword() {
    history.push(`${prefix}/forgot`);
  }

  function handleRedirectGenerateAnonimo() {
    const codParqueServico = query.get('cod_parque_servico');

    setCodParqueServico(Number(codParqueServico));
    history.push(`${prefix}/generate`);
  }

  async function checkParqueIsValid() {
    const codParque = Number(query.get('cod_parque_servico'));

    if (codParque === -1 || !codParque) {
      setParqueIsValid(false);
      return;
    }

    const data = await verifyParqueExists(codParque);

    if (!data || !data.resp || !data.resp.check) {
      setParqueIsValid(false);
    }
  }

  useEffect(() => {
    checkParqueIsValid();
  }, []);

  useEffect(() => {
    const codParqueServico = query.get('cod_parque_servico');

    if (codParqueServico) {
      setCodParqueServico(Number(codParqueServico));
    } else if (codParque) {
      window.location.search = `cod_parque_servico=${codParque}`;
    }

    if (accessToken && refreshToken) {
      history.push(`${prefix}/home`);
    }
  }, [history, accessToken, refreshToken]);

  return codParque && codParque !== -1 && parqueIsValid ? (
    <FrameData>
      <Form id="form-login" onSubmit={handleLogin}>
        <AutoRow>
          <Title line="34px" label="Bem-vindo(a) ao portal do usuário de Iluminação Pública" />

          <Paragraph
            label="Para solicitar manutenções nas lâmpadas de sua cidade, é necessário informar email e senha"
            marginBottom={16}
            marginTop={-4}
          />

          <Input
            handleFunction={handleSetState}
            value={dadosLogin.email}
            name="email"
            label="Email"
            type="email"
            required
          />

          <Input
            handleFunction={handleSetState}
            name="password"
            label="Senha"
            type="password"
            value={dadosLogin.password}
            required
          />

          <HoverClick label="Gerar uma ocorrência anônima" onClick={handleRedirectGenerateAnonimo} switchSides />
          <HoverClick label="Esqueceu a senha?" onClick={handleRedirectForgotPassword} />

          <Button onClick={handleRedirectToCreateUser} backgroundColor="light">
            Criar uma conta
          </Button>

          <Button loading={loadingSubmit} type="submit" form="form-login">
            Entrar
          </Button>
        </AutoRow>
      </Form>
    </FrameData>
  ) : (
    <p>Parque de serviço inválido.</p>
  );
};

export default IframeLogin;
