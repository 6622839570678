import React from 'react';
import { IconType } from 'react-icons';
import {
  BsBootstrapReboot,
  BsCameraVideo,
  BsClockHistory,
  BsEyeglasses,
  BsImages,
  BsPen,
  BsPlusCircle,
  BsStar,
  BsThreeDots,
  BsTrash,
} from 'react-icons/bs';
import { CgAttachment } from 'react-icons/cg';
import { FaGasPump } from 'react-icons/fa';
import { FiMapPin } from 'react-icons/fi';
import { IoTimeOutline } from 'react-icons/io5';
import { MdLogout, MdOutlineQrCodeScanner } from 'react-icons/md';
import { ITableRowProps } from '..';
import { ColumnAction } from '../../../dtos';
import { listenerCloseEventClickOutside } from '../../../utils';
import { ButtonOption, ColorButton, FloatOptions, MoreOptionsContainer } from './styles';
type Action = ITableRowProps['actionsLabels'] & { moreOptions?: string };
interface IActionInfo {
  [action: string]: {
    component: IconType | null;
    label: string;
  };
}
const actionsInfo: IActionInfo = {
  show: { component: BsEyeglasses, label: 'Ver detalhes' },
  edit: { component: BsPen, label: 'Editar' },
  delete: { component: BsTrash, label: 'Excluir' },
  fuel: { component: FaGasPump, label: 'Tanque' },
  map: { component: FiMapPin, label: 'Ver mapa' },
  qrcode: { component: MdOutlineQrCodeScanner, label: 'Ver Qrcode' },
  history: { component: BsClockHistory, label: 'Ver Histórico' },
  photo: { component: BsImages, label: 'Ver foto(s)' },
  attachment: { component: CgAttachment, label: 'Anexo(s)' },
  add: { component: BsPlusCircle, label: 'Adicionar' },
  video: { component: BsCameraVideo, label: 'Ver vídeo(s)' },
  logout: { component: MdLogout, label: 'Sair' },
  time: { component: IoTimeOutline, label: 'Histórico' },
  reopening: { component: BsBootstrapReboot, label: 'Reabrir' },
  moreOptions: { component: BsThreeDots, label: 'Exibir outras ações' },
  count: { component: null, label: 'Qtd.' },
  color: { component: null, label: 'Cor' },
  rate: { component: BsStar, label: 'Avaliar' },
};

interface IActionComponentProps {
  action: keyof Action;
  border?: boolean;
  className?: string;
  count?: number;
  countColor?: string;
  countBackground?: string;
  actionsLabels?: Action;
  active?: boolean;
  onAction?(action: ColumnAction): void;
}

export const ActionComponent = React.memo((props: IActionComponentProps) => {
  const { action, actionsLabels, count, countColor, countBackground, onAction, className, border, active } = props;
  const infoAction = actionsInfo[action];
  const actionLabel = actionsLabels?.[action];

  if (!infoAction) return null;

  const handleClick = React.useCallback(() => {
    onAction?.(action as ColumnAction);
  }, [action, onAction]);

  if (['count', 'color'].includes(action)) {
    const isCount = action === 'count';
    return (
      <ColorButton
        type="button"
        title={actionLabel || infoAction.label}
        className={className}
        background={!isCount ? countBackground || '' : ''}
        textColor={isCount ? countColor || '' : ''}
        onClick={handleClick}
      >
        {isCount ? count : null}
      </ColorButton>
    );
  }

  const Cp = infoAction?.component;

  if (!Cp) return null;

  return border ? (
    <ButtonOption active={active} type="button" title={infoAction.label} className={className} onClick={handleClick}>
      <Cp />
    </ButtonOption>
  ) : (
    <button key={action} onClick={handleClick}>
      <Cp />
      {!!infoAction.label && infoAction.label}
    </button>
  );
});

interface IMoreActions {
  actions: ColumnAction[];
  showMoreActions?: boolean;
  onAction?(action: ColumnAction): void;
  onShowMoreActions?(): void;
  isMoreActionsMenuInverted?: boolean;
}

export const MoreActions = React.memo((props: IMoreActions) => {
  const { actions, onAction, showMoreActions, onShowMoreActions, isMoreActionsMenuInverted } = props;
  const ref: React.RefObject<HTMLDivElement> = React.useRef(null);
  const preventCloseClassName = 'prevent_close_others_actions';

  // logica para fechar caso clique fora
  React.useEffect(() => {
    if (!showMoreActions || !actions?.length) return;

    const removeListener = listenerCloseEventClickOutside(onShowMoreActions as any, preventCloseClassName);
    return removeListener;
  }, [showMoreActions, actions]);

  return (
    <MoreOptionsContainer>
      <ActionComponent
        active={showMoreActions}
        className={preventCloseClassName}
        border
        action={'moreOptions' as any}
        onAction={onShowMoreActions}
      />

      <FloatOptions
        showMoreActions={!!showMoreActions}
        ref={ref}
        isMoreActionsMenuInverted={isMoreActionsMenuInverted}
        className={preventCloseClassName}
      >
        {actions?.map?.((action) => (
          <ActionComponent key={action} action={action} onAction={onAction} />
        ))}
      </FloatOptions>
    </MoreOptionsContainer>
  );
});

MoreActions.displayName = 'MoreActions';
ActionComponent.displayName = 'ActionComponent';
