import React from 'react';
import { ColumnType } from '../../dtos';
import { formatType } from '../../utils';
import {
  Container,
  LargeLabel,
  SmallLabel,
  ColorPreview,
  LabelAndColorPreviewContainer,
  IContainer,
  InputCell,
  InputCheckboxContainer,
} from './styles';
import { BsCheckCircle, BsCircle } from 'react-icons/bs';

interface ITableColumn extends IContainer {
  label: string;
  style?: React.CSSProperties;
  onClick?(value?: any): void;
  valueCaseNull?: string;
  type?: ColumnType;
  inputType?: string;
  valueCaseFalse?: string;
  valueCaseTrue?: string;
  inputFunction?(event: React.ChangeEvent<HTMLInputElement>): void;
}

const parserColumnValueByType = (
  value: string | boolean | null | undefined,
  props: React.PropsWithChildren<ITableColumn & { children?: React.ReactNode }>,
) => {
  const fnSerializeColumnByType = formatType[props.type as ColumnType];

  if (props.type === 'color' && !value) {
    return '#000000';
  }

  if (value === null || value === undefined || value === 'null') {
    return props.valueCaseNull || props.valueCaseNull;
  }

  if (value === false && props.valueCaseFalse) {
    return props.valueCaseFalse;
  }

  if (value === true && props.valueCaseTrue) {
    return props.valueCaseTrue;
  }

  return fnSerializeColumnByType ? fnSerializeColumnByType(value) : value;
};

export const TableColumn: React.FC<ITableColumn> = React.memo((props) => {
  const { stretch, label, sublabel, onClick, style, type, inputType, inputFunction } = { ...props };
  const isColor = type === 'color';
  const isInput = type === 'input';
  const isInputCheckbox = isInput && inputType === 'checkbox';

  const value = parserColumnValueByType(label, props);

  const handleClick = React.useCallback(() => {
    onClick?.(value);
  }, [onClick, value]);

  return (
    <Container
      isInput={isInput}
      stretch={stretch}
      sublabel={!!sublabel}
      style={style}
      onClick={onClick ? handleClick : undefined}
    >
      <LabelAndColorPreviewContainer>
        {!!isColor && <ColorPreview color={value} />}

        {isInput ? (
          <>
            {isInputCheckbox ? (
              <InputCheckboxContainer checked={!!value}>
                <InputCell type={inputType} style={style} checked={!!value} onChange={inputFunction} />

                {value ? <BsCheckCircle /> : <BsCircle />}
              </InputCheckboxContainer>
            ) : (
              <InputCell type={inputType} style={style} value={value} onChange={inputFunction} />
            )}
          </>
        ) : (
          ![null, undefined, ''].includes(value) && <LargeLabel>{value}</LargeLabel>
        )}
      </LabelAndColorPreviewContainer>

      {!!sublabel && <SmallLabel>{sublabel}</SmallLabel>}
    </Container>
  );
});

TableColumn.displayName = 'TableColumn';
