import styled from 'styled-components';

interface IWrapper {
  switchSides?: boolean;
  large?: boolean;
}
export const Wrapper = styled.div<IWrapper>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: ${(props) => (props.switchSides ? 'flex-start' : 'flex-end')};

  grid-column: ${(props) => (props.large ? 'span 2' : 'unset')};

  @media only screen and (max-width: 500px) {
    grid-column: unset;
  }
`;
export const Container = styled.p`
  color: #6e6e6e;
  opacity: 0.5;
  font-size: 12px;
  margin-top: -4px;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    opacity: 1;
    color: #7764e4;
  }
`;
