import { AutoTable } from 'components/AutoRow/components/AutoTable';
import React from 'react';

interface ITableHome {
  ocorrencias: any[];
  onClickRate?(itemRow: any): void;
  onClickDelete?(itemRow: any): void;
}

export const TableHome: React.FC<ITableHome> = (props) => {
  const { ocorrencias, onClickRate, onClickDelete } = { ...props };
  const tableColumns = [
    {
      key: 'num_protocolo',
      name: 'Protocolo',
    },
    {
      key: 'dth_inclusao_ocorrencia_ponto_servico',
      name: 'Data Solicitação',
      type: 'datetime',
    },
    {
      key: 'nom_tipo_ocorrencia',
      name: 'Tipo Ocorrência',
    },
    {
      key: 'nom_endereco_livre',
      name: 'Endereço',
    },
    {
      key: 'dsc_observacao',
      name: 'Observação',
    },
    {
      key: 'nom_status_ocorrencia',
      name: 'Status',
    },
  ];

  const getRemoveActions = (row) => {
    const removeActions: string[] = [];

    if (row.cod_status_ocorrencia != 4) {
      removeActions.push('delete');
    }
    if (!row.seq_atendimento_ponto_servico || row.seq_avaliacao_atendimento) {
      removeActions.push('rate');
    }

    return removeActions;
  };
  return (
    <AutoTable
      columns={tableColumns as any}
      actions={['delete', 'rate']}
      valueCaseNull="NULO"
      rows={ocorrencias.map((item) => ({
        ...item,
        removeActions: getRemoveActions(item),
      }))}
      onClickRate={onClickRate}
      onClickDelete={onClickDelete}
      keyAttribute={'seq_reclamante'}
    />
  );
};
