import styled from 'styled-components';

export const TopOptionsTab = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 12px;
  background: #f5f5f5;
  padding: 7px 10px;
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & input {
    width: 120px;
    height: 28px;
    border-radius: 14px;
    background: #fff;
    color: #6e6e6e;
    font-family: Roboto;
    font-weight: normal;
    font-size: 10px;
    border: none;
    padding: 4px 30px 4px 10px;
  }
`;

export const OptionButton = styled.button`
  border: none;
  min-width: 26px;
  min-height: 26px;
  border-radius: 14px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 8px;

  font-size: 10px;
  font-weight: normal;
  color: #6e6e6e;

  &[disabled] {
    cursor: not-allowed;
  }

  & svg {
    width: 12px;
    height: 12px;
    color: #6e6e6e;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  & p {
    font-family: Roboto;
    font-weight: bold;
    font-size: 12px;
    color: #6e6e6e;
    display: flex;
    gap: 4px;
  }
  & p span {
    font-weight: normal;
  }
`;

export const LabelExtraButtonHeader = styled.p`
  font-size: 10px;
  font-weight: normal;
  color: #6e6e6e;
  margin-left: 6px;
`;

interface IFloatOrderItemContainer {
  disabled?: boolean;
}

export const FloatOrderItemContainer = styled.a<IFloatOrderItemContainer>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: #6e6e6e;
  border-radius: 4px;
  padding: 2px;

  &:hover {
    filter: ${({ disabled }) => (disabled ? 'unset' : 'brightness(.1)')};
  }

  & p {
    font-size: 10px;
    font-weight: normal;
  }
`;

interface IFloatActions {
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;

  /***
   * @default 210
   */
  size?: number;
}

export const FloatActions = styled.div<IFloatActions>`
  width: ${({ size = 210 }) => size}px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #eae7e7;
  background: #fff;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  position: absolute;
  ${(props) => (props.left !== undefined ? `left: ${props.left}px` : '')};
  ${(props) => (props.right !== undefined ? `right: ${props.right}px` : '')};
  ${(props) => (props.top !== undefined ? `top: ${props.top}px` : '')};
  ${(props) => (props.bottom !== undefined ? `bottom: ${props.bottom}px` : '')};
  display: flex;
  flex-direction: column;
  gap: 8px;
  user-select: none;
  z-index: 2;
  cursor: default;

  & p {
    font-family: Roboto;
    font-weight: normal;
    font-size: 12px;
    color: #6e6e6e;
  }
  & a:hover p {
    font-weight: bold;
  }
  & > select {
    width: 100%;
    height: 30px;
    padding: 0 10px;
    font-family: Roboto;
    font-weight: normal;
    font-size: 10px;
    color: #6e6e6e;
    background: #f5f5f5;
    border-radius: 6px;
    border: none;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%);
    background-position: 91%, 93.3%;
    background-size: 5px 5px, 5px 5px;
    background-repeat: no-repeat;
  }
  & button {
    width: 100%;
    border: none;
    height: 28px;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f4f2ff;
    color: #7764e4;

    font-family: Roboto;
    font-weight: bold;
    font-size: 10px;
  }
`;
