import React from 'react';
import { Container } from './styles';

interface ITitle {
  label: string;
  weight?: string;
  margin?: string;
  line?: string;
}

export const Title: React.FC<ITitle> = (props) => {
  const { label, weight, margin, line } = { ...props };
  return (
    <Container weight={weight} margin={margin} line={line}>
      {label}
    </Container>
  );
};
