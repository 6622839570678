import styled from 'styled-components';

interface IContainer {
  weight?: string;
  marginBottom?: number;
  marginTop?: number;
}

export const Container = styled.p<IContainer>`
  color: #6e6e6e;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)}px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)}px;
  grid-column: span 2;

  @media only screen and (max-width: 500px) {
    grid-column: unset;
  }
`;
