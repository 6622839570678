import React from 'react';
import { Toggle, ContainerMoreToggle } from '../Toggle';
import { TableColumn } from '../TableColumn';
import { Container, ContentContainer, IChildrenContainer, IContainerProps } from './styles';
import type { IColumn } from '../../dtos';

interface ITableHeaderProps extends IChildrenContainer, IContainerProps {
  hasCheckbox?: boolean;
  isSelectedAll?: boolean;
  onSelectAll?(checked: boolean): void;
  columns: IColumn[];
  top?: number;
  depth?: number;
}

export const TableHeader = React.memo((props: ITableHeaderProps) => {
  const { gridTemplate, width, hasCheckbox, top, hasAcordeon, isSelectedAll, onSelectAll, depth, columns = [] } = props;

  return (
    <Container
      hasAcordeon={hasAcordeon}
      style={{ top, position: top ? 'absolute' : 'unset', marginLeft: depth ? depth * 50 : 'unset' }}
    >
      {!!hasCheckbox && (
        <ContainerMoreToggle>
          <Toggle type="checkbox" checked={!!isSelectedAll} onChecked={(checked) => onSelectAll?.(checked)} />
        </ContainerMoreToggle>
      )}

      <ContentContainer gridTemplate={gridTemplate} width={width}>
        {columns.map((column) => (
          <TableColumn key={column.key} label={column.name} />
        ))}
      </ContentContainer>
    </Container>
  );
});

TableHeader.displayName = 'TableHeader';
