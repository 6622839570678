import { IInput } from '../..';
import InputMask from '../InputMask';
import React from 'react';

export const FloatNumberInput = React.forwardRef((props: IInput, ref) => {
  // return <Input {...props} type="number" step="0.1" min="0" ref={ref} />;
  return <InputMask {...props} ref={ref} />;
});

FloatNumberInput.displayName = 'FloatNumberInput';
export default FloatNumberInput;
