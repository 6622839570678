import styled from 'styled-components';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

export const ChildrenContainer = styled(SimpleBar as any)`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;

  & .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    background-color: #babac0;
  }
`;
