import React from 'react';
import * as SC from './styles';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { addClassNameInChildrenRecursive, listenerCloseEventClickOutside } from '../../utils';

interface IBottomBarProps {
  totalPages: number;

  page: number;
  onChangePage(page: number): void;

  rowsPerPage: number;
  onChangeRowsPerPage?(rowsPerPage: number): void;

  firstRowsSelected: boolean;
  onChangeFirstRowsSelected(status: boolean): void;

  selectJustPageRows: boolean;
  onChangeSelectJustPageRows(status: boolean): void;
}

export const BottomBar = React.memo((props: IBottomBarProps) => {
  const {
    totalPages,
    page,
    onChangePage,
    rowsPerPage,
    onChangeRowsPerPage,
    firstRowsSelected,
    onChangeFirstRowsSelected,
    selectJustPageRows,
    onChangeSelectJustPageRows,
  } = props;

  const [showSettings, setShowSettings] = React.useState<boolean>(false);
  const optionsRowsPerPage = React.useRef([8, 10, 15, 20, 50, 100, 200, 500, 800, 1000, 2000, 5000]);
  const refContainer = React.useRef<HTMLDivElement>();

  const pagesOptionsSelect = (() => {
    const pages: number[] = [];

    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }

    return pages;
  })();

  const handleChangePage = React.useCallback(
    (newPage: number) => {
      if (newPage > totalPages || newPage < 1) return;
      onChangePage?.(newPage);
    },
    [totalPages],
  );

  const preventCloseClassName = 'prevent_close_config';

  // logica para fechar caso clique fora
  React.useEffect(() => {
    if (!showSettings) return;

    addClassNameInChildrenRecursive(preventCloseClassName, refContainer.current);
    const removeListener = listenerCloseEventClickOutside(() => setShowSettings(!showSettings), preventCloseClassName);

    return removeListener;
  }, [showSettings]);

  return (
    <SC.BottomOptionsTab ref={refContainer as any}>
      <SC.SelectOptions
        disabled={!totalPages}
        value={page}
        onChange={(e) => handleChangePage?.(Number(e.target.value))}
      >
        {!totalPages && <option value={0}>0</option>}

        {pagesOptionsSelect.map((numPage) => (
          <option value={numPage} key={numPage}>
            {numPage}
          </option>
        ))}
      </SC.SelectOptions>

      {!(totalPages === 1) && (
        <SC.PageCounterContainer>
          <SC.OptionButton disabled={page == 1} onClick={() => handleChangePage?.(Number(page) - 1)}>
            <BsChevronLeft />
          </SC.OptionButton>

          <p>
            <span>
              {page}/{totalPages}
            </span>
          </p>

          <SC.OptionButton disabled={page == totalPages} onClick={() => handleChangePage?.(Number(page) + 1)}>
            <BsChevronRight />
          </SC.OptionButton>
        </SC.PageCounterContainer>
      )}
    </SC.BottomOptionsTab>
  );
});

BottomBar.displayName = 'BottomBar';
