import styled from 'styled-components';

interface IContainer {
  weight?: string;
  margin?: string;
  line?: string;
}

export const Container = styled.p<IContainer>`
  font-size: 24px;
  color: #42434a;
  font-weight: ${(props) => (props.weight ? props.weight : 'bold')};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  line-height: ${(props) => (props.line ? props.line : 'normal')};
  grid-column: span 2;

  @media only screen and (max-width: 500px) {
    grid-column: unset;
  }
`;
