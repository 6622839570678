import styled from 'styled-components';

interface IContainer {
  small?: boolean;
  specifyColumn?: string[];
  specifyRow?: string[];
}

export const Container = styled.div<IContainer>`
  grid-column: span 2;

  @media only screen and (max-width: 500px) {
    grid-column: unset;
  }

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  overflow: auto;

  /* &:first-child {
    margin-top: 0;
  } */
`;
