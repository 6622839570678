import { FrameData } from '../../../../components/FrameData';
import React from 'react';
import { Row } from '../../../../components/Row';
import { Title } from './styles';

const ChangedPassword: React.FC = () => {
  return (
    <>
      <FrameData>
        <Row>
          <Title>Senha Alterada</Title>
        </Row>
        <Row>Sua senha foi alterada com sucesso</Row>
      </FrameData>
    </>
  );
};

export default ChangedPassword;
