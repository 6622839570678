import styled, { css } from 'styled-components';

export interface IContainer {
  stretch?: number;
  sublabel?: boolean;
  isInput?: boolean;
}

const hoverContainerCss = css`
  &:hover {
    white-space: unset;
    overflow: visible;
    text-overflow: unset;
    width: max-content;
  }
`;

export const Container = styled.div<IContainer>`
  width: 100%;
  height: 25px;
  display: ${(props) => (props.sublabel ? 'flex' : 'inline-block')};
  padding: 2px 10px;
  justify-content: center;
  flex-direction: column;
  border-left: 1px solid #eae7e7;
  ${(props) => props.stretch && `grid-column: span ${props.stretch}`};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;

  ${(props) => (!props.isInput ? hoverContainerCss : '')}
`;

export const LargeLabel = styled.p`
  font-family: Roboto;
  font-weight: normal;
  font-size: 10px;
  color: #6e6e6e;
`;

export const SmallLabel = styled.p`
  font-family: Roboto;
  font-weight: normal;
  font-size: 7px;
  color: #6e6e6e;
`;

interface IColorPreviewProps {
  color: string;
}

export const ColorPreview = styled.div<IColorPreviewProps>`
  height: 10px;
  width: 10px;
  background-color: ${({ color }) => color};
  border: 1px solid #a8a8a8;
  margin-right: 4px;
`;

export const LabelAndColorPreviewContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

interface InputCheckboxContainerProps {
  checked?: boolean;
}

export const InputCheckboxContainer = styled.label<InputCheckboxContainerProps>`
  border: none;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  cursor: pointer;

  & svg {
    width: 10px;
    height: 10px;
    color: ${({ checked, theme }) => (checked ? '#0db25f' : '#6e6e6e')};
  }
`;

export const InputCell = styled.input`
  width: 100%;
  height: 100%;
  border: 0;
  padding: 4px;
  background-color: transparent;
  color: #6e6e6e;
  font-size: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;

  &[type='checkbox'] {
    display: none;
    /* width: 16px;
    height: 16px;
    margin: 0 auto;
    border: 1px solid ${({ theme }) => theme.colors.border};
    accent-color: ${({ theme }) => theme.colors.secondary} */
  }
`;
