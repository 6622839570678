export const checkStringContains = (item: any, inputText: any) => {
  const itens = String(inputText).split(' ');
  let paridade = 0;

  for (let i = 0; i < itens.length; i++) {
    const busca = itens[i];

    const verifyString = String(item)
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .includes(
        busca
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase(),
      );

    if (verifyString) paridade++;
  }

  return paridade == itens.length;
};
