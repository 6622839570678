import {
  Container,
  ContainerInput,
  ExtraButtons,
  FloatContaner,
  FloatInfo,
  HelperContainer,
  InputTextArea,
  Label,
  Point,
} from './style';
import React, { InputHTMLAttributes, useCallback, useRef, useState } from 'react';

import MaskedInput from 'react-text-mask';
import { getTextTime, parseISOToDateStr, replace_freetime } from '../../../../hooks/helpers/methods';
import { BsDash, BsExclamationDiamond, BsPlug, BsSearch } from 'react-icons/bs';
import dayjs from 'dayjs';

function convertTimeToText(time: `${number}:${number}:${number}`) {
  const [hours, minutes, seconds] = time.split(':');

  return getTextTime(Number(hours) || 0, Number(minutes) || 0, Number(seconds) || 0);
}

export interface IInput extends InputHTMLAttributes<HTMLInputElement | any> {
  label?: string | number;
  placeholder?: string;
  value?: any;
  handleFunction?: any;
  type?: string;
  margin?: string;
  name?: string;
  mask?: string[] | any;
  readOnly?: boolean;
  ref?: any;
  helperText?: string;
  state?: any;
  setState?: any;
  handleFunctionDateISO?: any;
  place?: string;
  stretch?: number;
  maxLength?: number;
  invert?: boolean;
  numberPad?: boolean;
  error?: boolean;
  onSearchClick?: React.MouseEventHandler<HTMLButtonElement>;
}

interface IActive {
  active: boolean;
  focusactive: boolean;
}

export const Input: React.FC<IInput> = React.forwardRef((props, ref) => {
  const {
    label,
    helperText,
    type = 'text',
    value,
    handleFunction,
    name,
    mask,
    readOnly,
    margin,
    placeholder,
    state,
    setState,
    handleFunctionDateISO,
    place,
    stretch,
    maxLength,
    invert,
    numberPad,
    onSearchClick,
    error,
    ...otherProps
  } = { ...props };

  const [textTime, setTextTime] = React.useState<string>('');

  const serializedValue = React.useMemo(() => {
    if (type === 'freetime' && value) {
      setTextTime(convertTimeToText(value));
    } //
    else if (value?.split?.('T')?.length > 1) {
      if (type === 'date') return parseISOToDateStr(value);
      if (type === 'datetime-local') return dayjs(value).format('YYYY-MM-DD HH:mm');
    }

    if (value === null || value === undefined) return '';

    return value;
  }, [value, type]);

  const defaultRef = useRef();
  const resolvedRef: any = ref || defaultRef;

  const [values, setValues] = useState<IActive>({
    active: false,
    focusactive: false,
  });

  const onMouseBlurHandler = useCallback(
    (event: any) => {
      setValues({
        ...values,
        active: event.target.value.length !== 0,
        focusactive: false,
      });
    },
    [values],
  );

  const onMouseFocusHandler = useCallback(() => {
    setValues({
      ...values,
      active: true,
      focusactive: true,
    });
  }, [values]);

  const handleButtonNumberValue = (increment: number) => {
    handleChange({
      target: {
        name,
        value: (value || 0) + increment,
      },
    });
  };

  const handleChange = React.useCallback(
    (event) => {
      if (type === 'freetime') {
        event.target.value = replace_freetime(`${event.target.value}`);
      }

      handleFunction?.(event);

      if (state) setState?.({ ...state, [event.target.name]: event.target.value });

      if (type === 'date') {
        const [, timeZone] = value?.split('T') || [];

        handleFunctionDateISO?.({
          ...event,
          target: {
            ...event.target,
            name: event.target.name,
            value: event.target.value && timeZone ? `${event.target.value}T${timeZone}` : event.target.value,
          },
        });
      }
    },
    [handleFunction, handleFunctionDateISO, state, setState],
  );

  return (
    <Container type={type} place={place} stretch={stretch} margin={margin}>
      {label && (
        <Label>
          {label} {helperText && <HelperContainer>{helperText}</HelperContainer>}
        </Label>
      )}

      <ContainerInput disabled={otherProps.disabled} error={error} type={type} invert={invert}>
        {mask ? (
          <MaskedInput mask={mask} name={name} value={value} onChange={handleChange} />
        ) : type === 'textarea' ? (
          <InputTextArea maxLength={maxLength || 100} name={name} value={serializedValue} onChange={handleChange} />
        ) : (
          <input
            placeholder={placeholder}
            name={name}
            value={serializedValue}
            onChange={handleChange}
            type={type || 'text'}
            readOnly={readOnly}
            onBlur={onMouseBlurHandler}
            onFocus={onMouseFocusHandler}
            ref={resolvedRef}
            {...otherProps}
          />
        )}
        {type === 'number' && numberPad && (
          <ExtraButtons>
            <button onClick={() => handleButtonNumberValue(-1)}>
              <BsDash />
            </button>
            <button onClick={() => handleButtonNumberValue(1)}>
              <BsPlug />
            </button>
          </ExtraButtons>
        )}
        {textTime && (
          <p
            style={{
              position: 'absolute',
              top: '40px',
              left: '5px',
              color: '#15f786',
              fontSize: '10px',
            }}
          >
            {textTime}
          </p>
        )}
        {onSearchClick && (
          <ExtraButtons>
            <button onClick={onSearchClick}>
              <BsSearch />
            </button>
          </ExtraButtons>
        )}
      </ContainerInput>

      {error && (
        <FloatContaner>
          <Point />
          <FloatInfo>
            <BsExclamationDiamond />
            <label>Este campo é obrigatório! Preencha antes de prosseguir.</label>
          </FloatInfo>
        </FloatContaner>
      )}
    </Container>
  );
});

export * from './components/InputMask';

export * from './components/CurrencyInput';

export * from './components/TimeInput';

export * from './components/FloatNumberInput';

export * from './components/PasswordInput';

export * from './components/CPFInput';

export * from './components/CNPJInput';

export * from './components/CellphoneInput';

export * from './components/PhoneInput';

export default Input;
