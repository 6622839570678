import React from 'react';
import { useTheme } from 'styled-components';
import { BsArrowDown, BsArrowUp, BsFilter, BsSearch, BsSortDown, BsXLg } from 'react-icons/bs';
import { MdDone } from 'react-icons/md';
import { IOrderBy, IColumn, IExtraHeaderButton, IInfo } from '../../dtos';
import { IRemoveListener, addClassNameInChildrenRecursive, listenerCloseEventClickOutside } from '../../utils';
import * as SC from './styles';
import { LoadingComponent } from 'components/Loading';
import { useToggle } from 'hooks/useToggle';

interface ITopBarProps {
  onFilterText?(text: string): void;
  onHideColumns?(columnsKey: React.Key[]): void;
  hideColumns?: React.Key[];

  loading?: boolean;
  infos?: IInfo[];
  extraHeaderButtons?: IExtraHeaderButton[];
  keyForceDropDownItems?: string;
  columns: IColumn[];
  totalRows?: number;

  onOrderBy?(orderBy: IOrderBy[]): void;
  orderBy: IOrderBy[];
}

export const TopBar = React.memo((props: ITopBarProps) => {
  const {
    loading,
    hideColumns = [],
    orderBy = [],
    columns = [],
    extraHeaderButtons,
    infos,
    onFilterText,
    onHideColumns,
    onOrderBy,
    totalRows,
    keyForceDropDownItems,
  } = props;

  const theme = useTheme();
  const [showSearch, toggleShowSearch] = useToggle(false);
  const [showOrder, toggleShowOrder] = useToggle(false);
  const [showFilter, toggleShowFilter] = useToggle(false);
  const [activeDropdownItemExtra, setActiveDropdownItemExtra] = React.useState<React.Key[]>([]);
  const refContainer: React.RefObject<HTMLDivElement> = React.useRef(null);

  const onCloseFilterText = React.useCallback(() => {
    toggleShowSearch();
    onFilterText?.('');
  }, []);

  const onOpenDropdownItem = React.useCallback((key: string | number) => {
    setActiveDropdownItemExtra((prevState) => {
      const newState = [...prevState];

      const activeItemIndex = newState.findIndex((keyActiveItem) => keyActiveItem === key);

      if (activeItemIndex !== -1) {
        newState.splice(activeItemIndex, 1);
      } else {
        newState.push(key);
      }

      return newState;
    });
  }, []);

  const handlehideColumn = React.useCallback(
    (column: IColumn) => {
      const newHideColumns = [...hideColumns];
      const hideColumnIndex = newHideColumns.findIndex((hideColumn) => hideColumn === column.key);

      if (hideColumnIndex !== -1) {
        newHideColumns.splice(hideColumnIndex, 1);
      } //
      else {
        newHideColumns.push(column.key);
      }

      onHideColumns?.(newHideColumns);
    },
    [hideColumns],
  );

  const handleSortColumn = React.useCallback(
    (column: IColumn) => {
      const newOrderBy = [...orderBy];

      const sortIndex = newOrderBy.findIndex((sort) => sort.column == column.key);
      const sort = newOrderBy[sortIndex];

      if (!sort) {
        newOrderBy.push({ column: `${column.key}`, order: 'ASC' });
      } //
      else if (sort.order === 'ASC') {
        sort.order = 'DESC';
      } //
      else {
        newOrderBy.splice(sortIndex, 1);
      }

      onOrderBy?.(newOrderBy);
    },
    [orderBy],
  );

  const renderOrderColumnIcon = React.useCallback(
    (column: IColumn) => {
      const sort = orderBy.find((sort) => sort.column == column.key);

      if (!sort) return null;

      return sort.order === 'ASC' ? (
        <BsArrowUp pointerEvents="none" key={column.key} />
      ) : (
        <BsArrowDown pointerEvents="none" key={column.key} />
      );
    },
    [orderBy],
  );

  const columnsWithHide = columns.map((column) => ({
    ...column,
    hide: hideColumns.some((hideColumn) => column.key == hideColumn),
  }));

  const makeClassNameExtraHeaderButton = (key: React.Key) => {
    return `prevent_close_extra_header_buttons_${key}`;
  };

  const preventCloseOrderClassName = 'prevent_close_order';
  const preventCloseFilterClassName = 'prevent_close_show_filter';

  // logica para fechar caso clique fora
  React.useEffect(() => {
    if (!showFilter && !showOrder && !activeDropdownItemExtra.length) return;

    const removeListeners: IRemoveListener[] = [];

    if (showOrder) {
      addClassNameInChildrenRecursive(preventCloseOrderClassName, refContainer.current);
      const removeListener = listenerCloseEventClickOutside(toggleShowOrder, preventCloseOrderClassName);
      removeListeners.push(removeListener);
    }

    if (showFilter) {
      addClassNameInChildrenRecursive(preventCloseFilterClassName, refContainer.current);
      const removeListener = listenerCloseEventClickOutside(toggleShowFilter, preventCloseFilterClassName);
      removeListeners.push(removeListener);
    }

    if (activeDropdownItemExtra.length) {
      activeDropdownItemExtra.forEach((key) => {
        const className = makeClassNameExtraHeaderButton(key);

        addClassNameInChildrenRecursive(className, refContainer.current);
        const removeListener = listenerCloseEventClickOutside(() => onOpenDropdownItem(key), className);
        removeListeners.push(removeListener);
      });
    }

    return () => {
      removeListeners.forEach((removeListener) => removeListener?.());
    };
  }, [showOrder, showFilter, activeDropdownItemExtra]);

  return (
    <SC.TopOptionsTab ref={refContainer}>
      <SC.ButtonContainer>
        <SC.OptionButton onClick={toggleShowOrder} className={preventCloseOrderClassName}>
          <BsSortDown />
        </SC.OptionButton>

        {showSearch && <input placeholder="Buscar" autoFocus onChange={(e) => onFilterText?.(e.target.value)} />}

        <SC.OptionButton onClick={onCloseFilterText}>{showSearch ? <BsXLg /> : <BsSearch />}</SC.OptionButton>

        {!!loading && (
          <SC.OptionButton type="button">
            <LoadingComponent opacity="1" background="transparent" size={12} color={'#6e6e6e'} />
          </SC.OptionButton>
        )}
      </SC.ButtonContainer>

      <SC.TextContainer>
        {typeof totalRows === 'number' && (
          <p>
            Total: <span>{totalRows}</span>
          </p>
        )}

        {infos?.map?.((info) => (
          <p key={info.label}>
            {info.label}: <span>{info.value}</span>
          </p>
        ))}
      </SC.TextContainer>

      <SC.ButtonContainer>
        {extraHeaderButtons?.map?.(
          ({ key, onClick, component: Cp, label: labelExtraButton, loading, dropdownItems }) => (
            <SC.OptionButton
              key={key}
              type="button"
              className={makeClassNameExtraHeaderButton(key)}
              disabled={loading}
              onClick={() => {
                dropdownItems && onOpenDropdownItem(key);
                onClick?.();
              }}
            >
              {loading ? (
                <LoadingComponent
                  opacity="1"
                  background="transparent"
                  size={12}
                  color={'#6e6e6e'}
                  style={{ width: 'auto' }}
                />
              ) : (
                <Cp />
              )}

              {!!labelExtraButton && <SC.LabelExtraButtonHeader>{labelExtraButton}</SC.LabelExtraButtonHeader>}

              {(activeDropdownItemExtra.includes(key) || keyForceDropDownItems === key) && (
                <SC.FloatActions key={key} right={0} top={42} onClick={(e) => e.stopPropagation()}>
                  {dropdownItems?.map?.(({ icon: IconDropdownItem, ...dropdownItem }) => (
                    <SC.FloatOrderItemContainer
                      key={dropdownItem.key}
                      id={dropdownItem.id}
                      disabled={dropdownItem.loading}
                      onClick={dropdownItem.loading ? undefined : dropdownItem.onClick}
                    >
                      <p>{dropdownItem.label}</p>

                      {!!(IconDropdownItem && !dropdownItem.loading) && <IconDropdownItem />}

                      {!!dropdownItem.loading && (
                        <LoadingComponent
                          style={{ width: '18px' }}
                          opacity="0.6"
                          background="transparent"
                          size={14}
                          color="#7764e4"
                        />
                      )}
                    </SC.FloatOrderItemContainer>
                  ))}
                </SC.FloatActions>
              )}
            </SC.OptionButton>
          ),
        )}

        <SC.OptionButton className={preventCloseFilterClassName} onClick={toggleShowFilter}>
          <BsFilter />
        </SC.OptionButton>
      </SC.ButtonContainer>

      {showOrder && (
        <SC.FloatActions className={preventCloseOrderClassName} left={10} top={50}>
          {columns.map((column) => (
            <SC.FloatOrderItemContainer key={column.key} onClick={() => handleSortColumn(column)}>
              <p>{column.name}</p>
              {renderOrderColumnIcon(column)}
            </SC.FloatOrderItemContainer>
          ))}
        </SC.FloatActions>
      )}

      {showFilter && (
        <SC.FloatActions className={preventCloseFilterClassName} right={10} top={50}>
          {columnsWithHide.map((column) => (
            <SC.FloatOrderItemContainer key={column.key} onClick={() => handlehideColumn(column)}>
              <p>{column.name}</p>
              {!column.hide && <MdDone pointerEvents="none" />}
            </SC.FloatOrderItemContainer>
          ))}
        </SC.FloatActions>
      )}
    </SC.TopOptionsTab>
  );
});

TopBar.displayName = 'TopBar';
