import axios, { AxiosError, AxiosInstance } from 'axios';
import { getItemInStorage, removeItemInStorage, setItemInStorage } from '../../hooks/useLocalStorage';

let isAlreadyFetchingAccessToken = false;
let subscribers: any = [];

function subscribeTokenRefresh(cb: any) {
  subscribers.push(cb);
}

function onRefreshed(token: string) {
  subscribers.map((cb: any) => cb(token));
}

function cleanSubscribe() {
  subscribers = [];
}

const UserDestroyStorage = () => {
  removeItemInStorage('accessToken');
  removeItemInStorage('refreshToken');
  removeItemInStorage('reclamante');
};

export const addErrorInterceptor = (axiosInstance: AxiosInstance) => {
  const UNAUTHORIZED = 401;

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError | any) => {
      if (error.message === 'Network Error') {
        return {
          failed: true,
          message: 'Erro de ao conectar ao servidor, verifique a sua conexão com a internet',
        };
      }

      const { status } = error.response;
      const originalRequest: any = error.config;

      const accessToken = getItemInStorage('accessToken');

      if (status !== UNAUTHORIZED || !accessToken) {
        cleanSubscribe();
        return {
          ...error.response.data,
          failed: true,
        };
      }

      if (!isAlreadyFetchingAccessToken && accessToken) {
        isAlreadyFetchingAccessToken = true;
        const refreshToken = getItemInStorage('refreshToken');

        axios
          .get(`${process.env.REACT_APP_API_URL}/auth/complainer/refresh`, {
            headers: {
              'x-refresh-token': refreshToken,
            },
          })
          .then((response) => {
            const { 'x-access-token': newAccessToken, 'x-refresh-token': newRefreshToken } = response.headers;
            setItemInStorage('accessToken', newAccessToken);
            setItemInStorage('refreshToken', newRefreshToken);

            isAlreadyFetchingAccessToken = false;
            onRefreshed(newAccessToken);
            cleanSubscribe();
          })
          .catch(() => {
            UserDestroyStorage();
            window.location.reload();
            cleanSubscribe();
            isAlreadyFetchingAccessToken = false;
          });
      }

      return new Promise((resolve) => {
        return subscribeTokenRefresh((token: string) => {
          originalRequest.headers['x-access-token'] = `${token}`;
          axios.defaults.headers.common['x-access-token'] = `${token}`;
          resolve(axios(originalRequest));
        });
      });
    },
  );
};
