import React from 'react';

import { Loader, Container, IContainer, ILoader } from './styles';

export const LoadingPage = () => {
  //
};

interface ILoadingComponent extends IContainer, ILoader {
  borderRadius?: number;
  style?: React.CSSProperties;
}

export const LoadingComponent: React.FC<ILoadingComponent> = ({ ...props }) => {
  return (
    <Container
      style={props.style}
      background={props.background}
      absolute={props.absolute}
      relative={props.relative}
      fixed={props.fixed}
      opacity={props.opacity}
      borderRadius={props.borderRadius}
    >
      <Loader usePurpleColor={props.usePurpleColor} button={props.button} color={props.color} size={props.size} />
    </Container>
  );
};
