import styled from 'styled-components';

interface IContainer {
  specifyColumn?: string[];
  specifyRow?: string[];
  noBorder?: boolean;
  margin?: string;
}

export const Container = styled.div<IContainer>`
  grid-column: span 2;

  @media only screen and (max-width: 500px) {
    grid-column: unset;
  }

  border-radius: 10px;
  overflow: hidden;
  border: 1px solid ${(props) => (props.noBorder ? 'transparent' : props.theme.colors.border)};
  position: relative;

  ${(props) => props.margin && `margin: ${props.margin}`};
`;
