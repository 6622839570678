import React from 'react';
import { Container } from './styles';

interface IParagraph {
  label: string;
  marginBottom?: number;
  marginTop?: number;
}

export const Paragraph: React.FC<IParagraph> = (props) => {
  const { label, marginBottom, marginTop } = { ...props };
  return (
    <Container marginBottom={marginBottom} marginTop={marginTop}>
      {label}
    </Container>
  );
};
