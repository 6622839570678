import React, { createContext, useContext, useCallback } from 'react';
import Toast from '../components/Toast';

export interface ToastMessage {
  type?: 'success' | 'error' | 'info' | 'warn' | 'dark';
  description?: string;
}

interface ToastContextData {
  addToast(message: ToastMessage): void;
}

interface IToastProvider {
  children?: React.ReactNode;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

const ToastProvider = ({ children }: IToastProvider) => {
  const addToast = useCallback(({ type, description }: ToastMessage) => {
    Toast(type, description);
  }, []);

  return <ToastContext.Provider value={{ addToast }}>{children}</ToastContext.Provider>;
};

function useToast(): ToastContextData {
  return useContext(ToastContext);
}

export { useToast, ToastProvider };
