import React from 'react';
import { RoundedButton, Container, IContainer, IRoundedButton } from './style';
import { LoadingComponent } from 'components/Loading';

export interface IButton extends IContainer, IRoundedButton {
  theme?: any;
  onClick?: any;
  disabled?: boolean;
  loading?: boolean;
  title?: string;
  alternarCor?: [boolean, string];
  type?: 'submit' | 'button' | 'reset';
  loadingText?: string;
  form?: string;
  className?: string;
  refButton?: React.MutableRefObject<HTMLButtonElement>;
  hidden?: boolean;
  place?: string;
  stretch?: number;
  placeImportant?: string;
  smallFont?: boolean;
  invert?: boolean;
  small?: boolean;
  topic?: boolean;
  medium?: boolean;
}

export const Button: React.FC<IButton> = (props) => {
  const {
    children,
    backgroundColor = '',
    onClick,
    alternarCor,
    disabled,
    loading,
    type,
    form,
    className,
    margin,
    refButton,
    hidden,
    place,
    stretch,
    placeImportant,
    smallFont,
    invert,
    title,
    loadingText,
    small,
    topic,
    medium,
  } = { ...props };

  function handleClick(event) {
    if (onClick && !loading) {
      onClick(event);
    }
  }

  return (
    <Container
      hiddenButton={hidden}
      margin={margin}
      className={className}
      place={place}
      stretch={stretch}
      placeImportant={placeImportant}
    >
      <RoundedButton
        topic={topic}
        invert={invert}
        smallFont={smallFont}
        alternarCor={alternarCor}
        onClick={handleClick}
        backgroundColor={backgroundColor}
        disabled={disabled}
        loadingButton={loading}
        type={type}
        form={form}
        ref={refButton}
        title={title}
        small={small}
        medium={medium}
      >
        {!loading && children}

        {loading && (
          <>
            <LoadingComponent
              size={16}
              style={{ width: '16px' }}
              button
              color={backgroundColor === 'light' ? '#7764e4' : backgroundColor === 'dark' ? '#fff' : '#fff'}
            />

            {loadingText && <p>{loadingText}</p>}
          </>
        )}
      </RoundedButton>
    </Container>
  );
};
