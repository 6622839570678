import styled from 'styled-components';

interface IRowGrid {
  margin?: string;
}

export const RowGrid = styled.div<IRowGrid>`
  ${({ margin }) => (margin ? `margin: ${margin};` : '')}
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 16px;
  column-gap: 16px;
  align-items: stretch;
  grid-auto-columns: 1fr;

  @media only screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;
