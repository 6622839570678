import React, { useCallback, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { criarAvaliacao, criarAvaliacaoPublico } from '../../api/services/Avaliacao';
import { FrameData } from '../../components/FrameData';
import { useToast } from '../../hooks/toast';
import { AutoRow } from 'components/AutoRow';
import { Title } from 'components/AutoRow/components/Title';
import { Paragraph } from 'components/AutoRow/components/Paragraph';
import Input from 'components/AutoRow/components/Input';
import { Button } from 'components/AutoRow/components/Button';
import SelectTipoAvaliacao from 'helpers/SelectTipoAvaliacao';
import { SelectAutocomplete } from 'components/SelectAutocomplete';

interface IFormData {
  num_avaliacao_sistema: number | string;
  num_avaliacao_colaborador: number | any;
  fl_prazo: boolean | string | any;
  dsc_melhoria: string | any;
}

interface IFormType {
  cod_atendimento?: number;
  activeToken?: string;
  cod_ocorrencia_ponto_servico?: number;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const IframeRating = ({ history }: RouteComponentProps) => {
  const query = useQuery();
  const toast = useToast();
  const [formData, setFormData] = useState<IFormData>({} as IFormData);
  const [loadingPage, setLoadingPage] = useState<boolean>(false);
  const [formType] = useState<IFormType>(() => {
    const dadosIniciais: IFormType = {
      cod_atendimento: undefined,
      activeToken: undefined,
      cod_ocorrencia_ponto_servico: undefined,
    };

    if (query.get('token')) {
      dadosIniciais.activeToken = query.get('token') || undefined;
    }

    if (query.get('code')) {
      dadosIniciais.cod_atendimento = Number(query.get('code'));
    }

    if (query.get('cod_ops')) {
      dadosIniciais.cod_ocorrencia_ponto_servico = Number(query.get('cod_ops'));
    }

    return dadosIniciais;
  });

  const handleRedirectToHome = useCallback(() => {
    history.push('/home');
  }, [history]);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      setLoadingPage(true);
      event.preventDefault();

      let resp: any = null;

      const { activeToken, cod_atendimento, cod_ocorrencia_ponto_servico } = formType;

      if (activeToken) {
        resp = await criarAvaliacaoPublico({
          ...formData,
          token: activeToken,
        });
      } else if (cod_atendimento) {
        resp = await criarAvaliacao({
          ...formData,
          cod_ocorrencia_ponto_servico,
          cod_atendimento,
        });
      }

      setLoadingPage(false);
      if (resp) {
        if (resp.err) {
          toast.addToast({ type: 'warn', description: resp.err || 'Houve algum erro na avaliação' });
        } else {
          toast.addToast({
            type: 'success',
            description: 'Avaliação cadastrada com sucesso!',
          });
        }
      }
      handleRedirectToHome();
    },
    [formData],
  );
  const handleOnChangeFilters = (event) => {
    const name = event?.name || event?.target?.name;
    const value = event?.value || event?.target?.value;
    setFormData((old) => ({ ...old, [name]: value }));
  };
  function handleSetDataInIframeState(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setFormData((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
  }

  return (
    <FrameData loading={loadingPage}>
      <form onSubmit={handleSubmit}>
        <AutoRow>
          <Title label="Formulário de satisfação" />
          <Paragraph
            marginBottom={26}
            marginTop={-4}
            label="Agradecemos pelo seu tempo, por favor responda as perguntas abaixo:"
          />
          <SelectTipoAvaliacao
            handleFunction={handleOnChangeFilters}
            label="Como o Sr.(a) avalia o sistema que usou até a abertura da ocorrência?"
            isObrigatorio
            stretch={2}
            name="num_avaliacao_sistema"
            value={formData.num_avaliacao_sistema}
          />
          <SelectTipoAvaliacao
            handleFunction={handleOnChangeFilters}
            label="Como o Sr.(a) avalia o atendimento prestado pelo nosso colaborador?"
            isObrigatorio
            stretch={2}
            name="num_avaliacao_colaborador"
            value={formData.num_avaliacao_colaborador}
          />
          <SelectAutocomplete
            label="Suas solicitações foram atendidas dentro do prazo?*"
            descricao="nom_fl_prazo"
            stretch={2}
            removeDefaultOption
            placeHolder="Selecione"
            keyAttribute="fl_prazo"
            array={[
              { fl_prazo: 'true', nom_fl_prazo: 'Sim' },
              { fl_prazo: 'false', nom_fl_prazo: 'Não' },
            ]}
            value={formData.fl_prazo}
            handleFunction={handleOnChangeFilters}
            name={'fl_prazo'}
          />
          <Input
            handleFunction={handleSetDataInIframeState}
            value={formData.dsc_melhoria || ''}
            label="Na sua opinião como poderiamos melhoras nosso serviço?"
            name="dsc_melhoria"
            stretch={2}
          />
          <Button onClick={() => handleRedirectToHome()} backgroundColor="light">
            Cancelar
          </Button>
          <Button type="submit">Enviar</Button>
        </AutoRow>
      </form>
    </FrameData>
  );
};

export default IframeRating;
