import {
  formatDate,
  formatarData,
  formatarDataHora,
  formatarDataOnlyString,
  parseBoolean,
  parseCnpj,
  parseCpf,
  parseMoney,
  parsePercentage,
  parsePhone,
} from '../../../../hooks/helpers/methods';
import type { ColumnAction } from './dtos';

type IActionsFunctionName = {
  [key in ColumnAction]: string;
};

export const actionsFunctionName: IActionsFunctionName = {
  show: 'onClickShow',
  edit: 'onClickEdit',
  delete: 'onClickDelete',
  time: 'onClickTime',
  count: 'onClickCount',
  color: 'onClickColor',
  fuel: 'onClickFuel',
  attachment: 'onClickAttachment',
  map: 'onClickMap',
  video: 'onClickVideo',
  add: 'onClickAdd',
  qrcode: 'onClickQrCode',
  logout: 'onClickLogout',
  reopening: 'onClickReopening',
  photo: 'onClickPhoto',
  rate: 'onClickRate',
};

export const formatType: any = {
  phone: parsePhone,
  cpf: parseCpf,
  boolean: parseBoolean,
  cnpj: parseCnpj,
  money: parseMoney,
  percentage: parsePercentage,
  date: formatarData,
  dateOnly: formatarDataOnlyString,
  datetime: formatDate,
  datetime3: formatarDataHora,
};

export const addClassNameInChildrenRecursive = (className: string, containerElement: any = window.document as any) => {
  const elementsRoot = [...containerElement.querySelectorAll(`.${className}`)];

  const getChildrenRecursive = (element: Element) => {
    element.classList.add(className);

    for (let i = 0; i < element.children.length; i++) {
      getChildrenRecursive(element.children[i]);
    }
  };

  elementsRoot.forEach(getChildrenRecursive);
};

export type ICallbackClose = () => void;
export type IRemoveListener = () => void;

export const listenerCloseEventClickOutside = (
  callbackClose: ICallbackClose,
  preventCloseClassName?: string,
): IRemoveListener => {
  const callbackClick = (event: MouseEvent) => {
    const element = event.target as Element;

    if (preventCloseClassName && element.classList.contains(preventCloseClassName)) return;

    callbackClose?.();
  };

  window.addEventListener('click', callbackClick);

  return () => {
    window.removeEventListener('click', callbackClick);
  };
};
