import React from 'react';
import { RoundedButton, Container, IContainer, Loader, IRoundedButton } from './style';

interface IFrameButton extends IContainer, IRoundedButton {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | undefined;
  form?: string;
  type?: 'button' | 'submit' | 'reset';
  children: React.ReactNode;
  loading?: boolean;
  background?: string;
  color?: string;
}

export const FrameButton = ({
  children,
  size,
  onClick,
  form,
  loading,
  type = 'button',
  background,
  color,
}: IFrameButton) => {
  function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (!loading && onClick) {
      onClick(event);
    }
  }

  return (
    <Container size={size}>
      <RoundedButton
        loadingBtn={loading}
        type={type}
        form={form}
        onClick={handleClick}
        background={background}
        color={color}
      >
        {loading ? <Loader /> : children}
      </RoundedButton>
    </Container>
  );
};
