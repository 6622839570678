import React from 'react';
import * as Actions from './Actions';
import type { ColumnAction } from '../../dtos';
import { Toggle, ContainerMoreToggle } from '../Toggle';
import { ButtonContainer, ChildrenContainer, Container, IChildrenContainer, SwitchContainer } from './styles';

export interface ITableRowProps extends IChildrenContainer {
  actions?: ColumnAction[];
  hasSwitch?: boolean;
  hasCheckbox?: boolean;
  hasAcordeon?: boolean;
  isSelected?: boolean;
  isActive?: boolean;
  disabledSelect?: boolean;
  disabledStatus?: boolean;
  width: string;
  count?: number;
  countColor?: string;
  countBackground?: string;
  color?: string;
  backgroundColor?: string;
  showMoreActions?: boolean;
  acordeonOpened?: boolean;
  acordeonItems?: React.ReactNode | React.ReactNode[];
  keyRow?: React.Key;
  top: number;
  actionsLabels?: { [action in ColumnAction]?: string };
  depth?: number;
  isOdd?: boolean;
  onShowMoreActions?(keyRow: React.Key, show: boolean): void;
  onAction?(action: ColumnAction): void;
  onStatus?(checked: boolean): void;
  onSelect?(checked: boolean): void;
  onOpenAcordeon?(keyRow: React.Key, checked: boolean): void;
  isMoreActionsMenuInverted?: boolean;
}

export const TableRow: React.FC<ITableRowProps> = React.memo((props) => {
  const {
    keyRow,
    children,
    gridTemplate,
    onAction,
    onStatus,
    onSelect,
    hasCheckbox,
    hasSwitch,
    width,
    isActive,
    isSelected,
    disabledSelect,
    disabledStatus,
    actions,
    count,
    countColor,
    countBackground,
    backgroundColor,
    color,
    showMoreActions,
    onShowMoreActions,
    hasAcordeon,
    acordeonItems = [],
    onOpenAcordeon,
    acordeonOpened,
    top,
    isOdd,
    depth,
    actionsLabels,
    isMoreActionsMenuInverted,
  } = { ...props };

  const refContainer: React.RefObject<HTMLDivElement> = React.useRef(null);

  const handleCheckedAcordeon = React.useCallback((checked: boolean) => !!keyRow && onOpenAcordeon?.(keyRow, checked), [
    onOpenAcordeon,
    keyRow,
  ]);

  const { firstActions, othersActions } = React.useMemo(() => {
    const serializedActions = actions || [];
    const first = serializedActions.length > 3 ? serializedActions.slice(0, 2) : serializedActions;
    const other = serializedActions.length > 3 ? serializedActions.slice(2, serializedActions.length) : [];

    return { firstActions: first, othersActions: other };
  }, [actions]);

  const handleShowMoreAction = React.useCallback(() => {
    if (!!keyRow) {
      onShowMoreActions?.(keyRow, !showMoreActions);
    }
  }, [onAction, keyRow, showMoreActions, onShowMoreActions]);

  return (
    <>
      <Container
        ref={refContainer}
        isOdd={isOdd}
        style={{ top, backgroundColor, marginLeft: depth ? depth * 50 : 'unset' }}
      >
        {!!(hasCheckbox || hasAcordeon) && (
          <ContainerMoreToggle>
            {!!hasCheckbox && (
              <Toggle type="checkbox" disabled={disabledSelect} checked={!!isSelected} onChecked={onSelect} />
            )}

            {!!hasAcordeon && <Toggle type="folder" checked={acordeonOpened} onChecked={handleCheckedAcordeon} />}
          </ContainerMoreToggle>
        )}

        <ChildrenContainer color={color} gridTemplate={gridTemplate} style={{ width }}>
          {children}
        </ChildrenContainer>

        <ButtonContainer show={!!actions?.length}>
          {firstActions.map((action) => (
            <Actions.ActionComponent
              border
              key={action}
              count={count}
              countBackground={countBackground}
              countColor={countColor}
              onAction={onAction}
              action={action}
              actionsLabels={actionsLabels}
            />
          ))}

          {!!othersActions.length && (
            <Actions.MoreActions
              showMoreActions={showMoreActions}
              onShowMoreActions={handleShowMoreAction}
              actions={othersActions}
              onAction={onAction}
              isMoreActionsMenuInverted={isMoreActionsMenuInverted}
            />
          )}
        </ButtonContainer>
      </Container>

      {acordeonItems}
    </>
  );
});

TableRow.displayName = 'TableRow';
