import React from 'react';
import { IconType } from 'react-icons';
import { BsCheckCircle, BsCircle, BsFolderMinus, BsFolderPlus } from 'react-icons/bs';
import { CheckButton } from './style';

interface IAutoTableToggleProps {
  checked?: boolean;
  disabled?: boolean;
  onChecked?(checkd: boolean): void;

  /**
   * @default 'checkbox''
   */
  type?: 'checkbox' | 'folder';
}

interface IToggleIcons {
  active: IconType;
  notActive: IconType;
}

const checkboxIcons: IToggleIcons = {
  active: BsCheckCircle,
  notActive: BsCircle,
};

const folderIcons: IToggleIcons = {
  active: BsFolderMinus,
  notActive: BsFolderPlus,
};

export const Toggle = React.memo((props: IAutoTableToggleProps) => {
  const { checked, disabled, onChecked, type = 'checkbox' } = props;

  const icon = (() => {
    const { active: IconChecked, notActive: IconNotChecked } = type === 'checkbox' ? checkboxIcons : folderIcons;
    return checked ? <IconChecked /> : <IconNotChecked />;
  })();

  const handleCheck = React.useCallback(() => {
    onChecked?.(!checked);
  }, [onChecked, checked]);

  return (
    <CheckButton checked={checked} onClick={!disabled ? handleCheck : undefined}>
      {icon}
    </CheckButton>
  );
});

Toggle.displayName = 'Toggle';

export { Container as ContainerMoreToggle } from './style';
