import { useEffect, useState } from 'react';

export const prefix = '@nexus_cidadao';

export function getItemInStorage(key: string) {
  const tokenJSON = window.localStorage[`${prefix}:${key}`];

  return tokenJSON ? JSON.parse(tokenJSON) : '';
}

export function removeItemInStorage(key: string) {
  window.localStorage[`${prefix}:${key}`] = '';
}

export function setItemInStorage(keyName: string, keyValue: string): void {
  localStorage.setItem(`${prefix}:${keyName}`, JSON.stringify(keyValue) || '');
}

export function useLocalStorage<T>(key: string, initialValue: T): [T, (value: T | ((val: T) => T)) => void] {
  const localKey = `${prefix}:${key}`;

  const [storedValue, setStoredValue] = useState<T>(() => {
    const item = window.localStorage[localKey];

    return item ? JSON.parse(item) : initialValue;
  });

  const setValue = (value: T | ((val: T) => T)) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;

    setStoredValue(valueToStore);

    window.localStorage.setItem(localKey, JSON.stringify(valueToStore) || '');
  };

  function onChange(event: StorageEvent) {
    if (event.key === localKey && event.oldValue !== event.newValue) {
      if (event.newValue) {
        setValue(JSON.parse(event.newValue));
      }
    }
  }

  useEffect(() => {
    window.addEventListener('storage', onChange);
    return () => {
      window.removeEventListener('storage', onChange);
    };
  }, [window.localStorage]);

  return [storedValue, setValue];
}

export default useLocalStorage;
