import {
  IframeLogin,
  IframeAccount,
  IframeAddress,
  IframeGenerate,
  IframeHome,
  IframePassword,
  IframeRating,
  IframeResetPassword,
  IframeSend,
  IframeTag,
} from '../pages';

export const prefix = '';

export const routes = [
  {
    path: '/',
    name: 'Login',
    component: IframeLogin,
    exact: true,
    meta: {
      auth: false,
    },
  },
  {
    path: '/forgot',
    name: 'Recuperar senha',
    component: IframePassword,
    meta: {
      auth: false,
    },
  },
  {
    path: '/new-account',
    name: 'Criar uma nova conta',
    component: IframeAccount,
    meta: {
      auth: false,
    },
  },
  {
    path: '/home',
    name: 'Area do usuario',
    component: IframeHome,
    exact: true,
    meta: {
      auth: true,
    },
  },
  {
    path: '/home/rating',
    name: 'Fomulário de satisfação',
    component: IframeRating,
    meta: {
      auth: false,
    },
  },
  {
    path: '/generate',
    name: 'Selecionar opção para nova ocorrencia',
    component: IframeGenerate,
    exact: true,
    meta: {
      auth: false,
    },
  },
  {
    path: '/generate/tag',
    name: 'Gerar ocorrencia por plaqueta',
    component: IframeTag,
    meta: {
      auth: false,
    },
  },
  {
    path: '/generate/address',
    name: 'Gerar ocorrencia pelo endereço',
    component: IframeAddress,
    exact: true,
    meta: {
      auth: false,
    },
  },
  {
    path: '/generate/address/send',
    name: 'Enviar ocorrencia gerada pelo endereço',
    component: IframeSend,
    exact: true,
    meta: {
      auth: false,
    },
  },
  {
    path: '/new_password',
    name: 'Nova senha',
    component: IframeResetPassword,
    meta: {
      auth: false,
    },
  },
];
