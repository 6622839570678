/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { toast } from 'react-toastify';
import { MdWarning, MdCancel, MdError, MdCheckCircle } from 'react-icons/md';
import { ContainerToast } from './styles';
import 'react-toastify/dist/ReactToastify.css';

type IType = 'success' | 'error' | 'info' | 'warn' | 'dark';
type description = string;

const Toast: any = (type: IType, message: description) => {
  switch (type) {
    case 'warn':
      return toast.warning(
        <ContainerToast>
          <MdWarning size={24} />
          <span>{message}</span>
        </ContainerToast>,
      );
    case 'error':
      return toast.error(
        <ContainerToast>
          <MdCancel size={24} /> <span>{message}</span>
        </ContainerToast>,
      );
    case 'success':
      return toast.success(
        <ContainerToast>
          <MdCheckCircle size={24} /> <span>{message}</span>
        </ContainerToast>,
      );
    case 'info':
      return toast.info(
        <ContainerToast>
          <MdError size={24} /> <span>{message}</span>
        </ContainerToast>,
      );
    case 'dark':
      return toast.dark(
        <ContainerToast>
          <MdError size={24} /> <span>{message}</span>
        </ContainerToast>,
      );
    default:
      return toast(message);
  }
};
export default Toast;
