import styled from 'styled-components';

interface IContentContainer {
  noPadding?: boolean;
}
export const ContentContainer = styled.div<IContentContainer>`
  padding: ${(props) => (props.noPadding ? '0' : '10px 20px')};
  width: 100%;
`;
interface IContainer {
  small?: boolean;
}
export const Container = styled.div`
  height: 90px;
  width: 100%;
  border-radius: 16px;
  background: #f4f2ff;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border: 0.8px solid #e2ddfd;

  & svg {
    width: 30px;
    height: 30px;
    color: #7764e4;
  }
`;
export const InfoText = styled.label<IContainer>`
  max-width: calc(100% - 80px);
  font-family: Roboto;
  font-weight: normal;
  font-size: ${(props) => (props.small ? '12px' : '14px')};
  color: #7764e4;
`;
