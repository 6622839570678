import React from 'react';
import { useToast } from '../hooks/toast';
import { getTipoAvaliacao, ITipoAvaliacao } from '../api/services/Avaliacao';
import {
  IHandleFunctionSelectAutocomplete,
  IValueSelectAutocomplete,
  SelectAutocomplete,
} from '../components/SelectAutocomplete';

interface ISelectTipoAvaliacao {
  value: IValueSelectAutocomplete;
  handleFunction: IHandleFunctionSelectAutocomplete;
  setTipoAvaliacao?: React.Dispatch<React.SetStateAction<ITipoAvaliacao>>;
  filter?(codTipoAvaliacao: number): boolean;
  isObrigatorio?: boolean;
  name?: string;
  disabled?: boolean;
  auto?: boolean;
  label?: string;
  stretch?: number;
}

const SelectTipoAvaliacao: React.FC<ISelectTipoAvaliacao> = (props) => {
  const toast = useToast();
  const [loading, setLoading] = React.useState<boolean>(false),
    [tipoAvaliacao, setTipoAvaliacao] = React.useState<ITipoAvaliacao[]>([]);

  const loadTipoAvaliacao = async () => {
    const TipoAvaliacaoResponse = await getTipoAvaliacao();

    if (TipoAvaliacaoResponse.failed) {
      toast.addToast({ type: 'error', description: TipoAvaliacaoResponse.message });
      setTipoAvaliacao([]);
      return;
    }

    setTipoAvaliacao(TipoAvaliacaoResponse.data);
  };

  React.useEffect(() => {
    const promises = [loadTipoAvaliacao()];

    setLoading(true);

    Promise.all(promises).finally(() => setLoading(false));
  }, []);

  return (
    <SelectAutocomplete
      loading={loading}
      stretch={props.stretch}
      label={
        props?.label
          ? `${props.label}${props.isObrigatorio ? ' *' : ''}`
          : `Tipo Avaliação${props.isObrigatorio ? ' *' : ''}`
      }
      array={props.filter ? tipoAvaliacao.filter((to) => props?.filter?.(to.seq_tipo_avaliacao)) : tipoAvaliacao}
      noOptions={'Nenhum tipo de avaliação disponível'}
      disabled={props.disabled || !tipoAvaliacao?.length}
      name={props.name || 'cod_tipo_avaliacao'}
      value={props.value}
      placeHolder="Selecione uma avaliação"
      descricao={'nom_tipo_avaliacao'}
      handleFunction={(e) => {
        props.handleFunction(e);

        if (props.setTipoAvaliacao) {
          const TipoAvaliacaoelected = tipoAvaliacao.find((p) => Number(p.seq_tipo_avaliacao) == Number(e.value));

          props.setTipoAvaliacao(TipoAvaliacaoelected as any);
        }
      }}
      keyAttribute="seq_tipo_avaliacao"
    ></SelectAutocomplete>
  );
};

export default SelectTipoAvaliacao;
