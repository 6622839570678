import React from 'react';
import InputMask from 'react-input-mask';

import { Container, Label, ContainerInput, IContainer } from './style';

interface IFrameInput extends IContainer {
  label: string | number;
  value?: string | number;
  handleFunction?: any;
  type?: string;
  placeholder?: string;
  name?: string;
  required?: boolean;
  pattern?: string;
  mask?: string;
}

export const FrameInput = ({
  label,
  type,
  value,
  size,
  handleFunction,
  margin,
  placeholder,
  name,
  required,
  pattern,
  mask,
}: IFrameInput) => {
  return (
    <Container size={size} margin={margin}>
      <Label>{label}</Label>

      <ContainerInput>
        {mask ? (
          <InputMask
            mask={mask}
            required={required}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={handleFunction}
            pattern={pattern}
          />
        ) : (
          <input
            required={required}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={handleFunction}
            pattern={pattern}
            type={type || 'text'}
          />
        )}
      </ContainerInput>
    </Container>
  );
};
