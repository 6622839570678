import { GuardFunction } from 'react-router-guards';
import { getItemInStorage } from '../../hooks/useLocalStorage';

export const checkUserLogged: GuardFunction = (to, from, next) => {
  const accessToken = getItemInStorage('accessToken');
  const refreshToken = getItemInStorage('refreshToken');

  if (to.meta.auth) {
    if (!accessToken && !refreshToken) {
      next.redirect('/');
    } else {
      next();
    }
  } else {
    next();
  }
};
