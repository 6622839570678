import React from 'react';
import { useToast } from '../hooks/toast';
import { getTipoOcorrencia, getTipoOcorrenciaAnonimo, ITipoOcorrencia } from '../../src/api/services/Reclamante';
import {
  IHandleFunctionSelectAutocomplete,
  IValueSelectAutocomplete,
  SelectAutocomplete,
} from '../components/SelectAutocomplete';

interface ISelectTipoOcorrencia {
  value: IValueSelectAutocomplete;
  handleFunction: IHandleFunctionSelectAutocomplete;
  setTipoOcorrencia?: React.Dispatch<React.SetStateAction<ITipoOcorrencia>>;
  filter?(codTipoOcorrencia: number): boolean;
  isObrigatorio?: boolean;
  name?: string;
  disabled?: boolean;
  anonimo?: boolean;
  is_iframe?: boolean;
  auto?: boolean;
  cod_parque_servico?: number;
}

const SelectTipoOcorrencia: React.FC<ISelectTipoOcorrencia> = (props) => {
  const toast = useToast();
  const [loading, setLoading] = React.useState<boolean>(false),
    [tipoOcorrencia, setTipoOcorrencia] = React.useState<ITipoOcorrencia[]>([]);

  const loadTipoOcorrencia = async () => {
    if (!props.cod_parque_servico) {
      setTipoOcorrencia([]);
      return;
    }

    let TipoOcorrenciaResponse;

    if (!props?.anonimo) {
      TipoOcorrenciaResponse = await getTipoOcorrencia(props?.cod_parque_servico, true);
    } else {
      TipoOcorrenciaResponse = await getTipoOcorrenciaAnonimo(props?.cod_parque_servico, true);
    }

    if (TipoOcorrenciaResponse.failed) {
      toast.addToast({ type: 'error', description: TipoOcorrenciaResponse.message });
      setTipoOcorrencia([]);
      return;
    }

    setTipoOcorrencia(TipoOcorrenciaResponse.data);
  };

  React.useEffect(() => {
    const promises = [loadTipoOcorrencia()];

    setLoading(true);

    Promise.all(promises).finally(() => setLoading(false));
  }, [props.cod_parque_servico]);

  return (
    <SelectAutocomplete
      loading={loading}
      label={`Tipo Ocorrência${props.isObrigatorio ? ' *' : ''}`}
      array={props.filter ? tipoOcorrencia.filter((to) => props?.filter?.(to.seq_tipo_ocorrencia)) : tipoOcorrencia}
      noOptions={'Nenhum tipo de ocorrência disponível'}
      disabled={props.disabled || !tipoOcorrencia?.length}
      name={props.name || 'cod_tipo_ocorrencia'}
      value={props.value}
      placeHolder="Selecione um tipo ocorrência"
      descricao={'nom_descricao'}
      handleFunction={(e) => {
        props.handleFunction(e);

        if (props.setTipoOcorrencia) {
          const TipoOcorrenciaelected = tipoOcorrencia.find((p) => Number(p.seq_tipo_ocorrencia) == Number(e.value));

          props.setTipoOcorrencia(TipoOcorrenciaelected as any);
        }
      }}
      keyAttribute="seq_tipo_ocorrencia"
    ></SelectAutocomplete>
  );
};

export default SelectTipoOcorrencia;
