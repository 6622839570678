/* eslint-disable @typescript-eslint/ban-types */
import { useRef, useCallback } from 'react';

function useDebounce<Type extends Function>(fn: Type, delay?: number) {
  const timeoutRef = useRef<NodeJS.Timeout>();

  const debounceFn = useCallback(
    (...args) => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
      timeoutRef.current = typeof fn === 'function' ? setTimeout(() => fn(...args), delay) : undefined;
    },
    [fn, delay],
  );

  return (debounceFn as any) as Type;
}

export default useDebounce;
