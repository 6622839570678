import React from 'react';

import { Container } from './styles';

export const NotFound = () => {
  return (
    <Container>
      <p>Ocorreu um erro!</p>
    </Container>
  );
};

export default NotFound;
