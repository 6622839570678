import styled from 'styled-components';

export interface IChildrenContainer {
  gridTemplate?: string;
  width?: string;
}

export interface IContainerProps {
  hasAcordeon?: boolean;
}

export const Container = styled.section<IContainerProps>`
  width: 100%;
  height: 25px;
  display: flex;
  border-radius: 6px;
  background: #f5f5f5;
  padding-left: 5px;
`;

export const ContentContainer = styled.div<IChildrenContainer>`
  width: ${({ width }) => width};
  height: 25px;
  display: grid;
  grid-template-columns: ${(props) => props.gridTemplate};
  align-items: stretch;

  & > div:first-child {
    border: none !important;
  }
  & > div p {
    font-weight: bold;
  }
`;
