import React from 'react';

import { filterValues } from '../../../../hooks/helpers/methods';
import useStateWithDebounce from '../../../../hooks/useStateWithDebounce';
import useResize from '../../../../hooks/useResize';
import useStorage from '../../../../hooks/useStorage';
import useDebounce from '../../../../hooks/useDebounce';

import { RowCompatibility } from './../RowCompatibility';
import { EmptyData } from '../../../EmptyData';
import { TopBar } from './components/TopBar';
import { BottomBar } from './components/BottomBar';
import { TableHeader } from './components/TableHeader';
import { TableBody, ITableBodyRef } from './components/TableBody';
import { TableColumn } from './components/TableColumn';
import { TableRow } from './components/TableRow';

import { ITableProps, IOrderBy, ColumnAction, ITableCommomProps, IColumn, IInfo } from './dtos';
import { actionsFunctionName } from './utils';
import * as SC from './styles';
import { filter_text } from '../../../../hooks/helpers/methods';

export const AutoTable = (props: ITableProps) => {
  const {
    loading,
    totalRowsAPI,
    infos,
    extraHeaderButtons,
    checkbox: hasCheckboxRoot,
    selectedItems = [],
    rows = [],
    columns = [],
    page: externalPage,
    specifyColumn = [7, 5, 4, 3, 2],
    specifyRow,
    showTotalRows,
    showTotalRowsSelected,
    emptyText = 'Não há resultados para exibir os itens!',
    keyForceDropDownItems,
    hidden,
    maxHeight,
    minHeight,
  } = props;

  const refTableBody: React.RefObject<HTMLDivElement> = React.useRef(null);
  const [localPage, setLocalPage] = React.useState(1);
  const [filterText, setFilterText] = React.useState('');
  const [orderBy, setOrderBy] = React.useState<IOrderBy[]>([]);
  const [keyRowActiveShowMoreActions, setKeyRowActiveShowMoreActions] = React.useState<React.Key>();
  const [scroll, setScroll] = useStateWithDebounce({ left: 0, top: 10 }, 10);
  const { height: heightTableBody } = useResize({
    HTMLElement: refTableBody?.current || undefined,
    ignoreZeroValue: true,
  });
  const [hideColumns, setHideColumns] = React.useState(columns.filter((c) => c.hide).map((c) => c.key));
  const [openedRows, setOpenedRows] = React.useState(new Map<React.Key, boolean>());

  // user config table
  const [rowsPerPage, setRowsPerPage] = useStorage('@table:rows_per_page', 200);
  const [firstRowsSelected, setFirstRowsSelected] = useStorage('@table:first_rows_selected', false);
  const [selectJustPageRows, setSelectJustPageRows] = useStorage('@table:select_just_page_rows', false);

  const rowHeight = 25;
  const page = externalPage || localPage;
  const isPaginateAPI = typeof totalRowsAPI === 'number';

  const extractRowKey = (row: any, key: string) => {
    return key
      .split(':')
      .map((attribute) => row[attribute])
      .join(':');
  };

  const getGridTemplateString = (columns?: IColumn[]) => {
    const stretchColumns = columns?.reduce?.((acm, column) => acm + (column?.stretch || 1), 0) || 0;
    const gridTemplateLocal = `repeat(${stretchColumns}, 1fr)`;

    return gridTemplateLocal;
  };

  const getWidthHeaderAndRows = (actions: ColumnAction[], hasCheckbox: boolean, hasSwitch: boolean, depth = 0) => {
    const switchContainerSize = 43;
    const checkboxSize = 45;
    const depthSize = depth * 50;

    const widthActionsContainer = (() => {
      const size = actions.length > 3 ? 3 : actions.length;
      const calculatedSize = size * 32.5;

      return calculatedSize > 38 ? calculatedSize : 38;
    })();

    const or = (arg1, arg2) => arg1 || arg2;
    const sizes = [
      depthSize,
      or(hasCheckbox && checkboxSize, 0),
      or(hasSwitch && switchContainerSize, 0),
      widthActionsContainer,
    ];

    const totalSize = sizes.reduce((acm, size) => (size ? acm + size : acm), 0);

    return `calc(100% - ${totalSize}px)`;
  };

  const getSerializedActions = (currentProps: ITableCommomProps) => {
    const localActions = currentProps?.actions || [];

    const serializedActions = [
      ...[...new Set(localActions)].filter((action) => {
        return true;
      }),
    ].sort((a, b) => {
      if (a === 'delete' || b === 'color' || b === 'count') return 1;
      if (a === 'color' || a === 'count' || b === 'delete') return -1;

      return 0;
    });

    return serializedActions;
  };

  const checkHasSwitch = (currentProps?: ITableCommomProps) => {
    if (!currentProps?.rulesSlugs?.status || !currentProps?.status) return currentProps?.status;
    return true;
  };

  const rowsSorted = React.useMemo(() => {
    const rowsSortedLocal = [...rows];

    rowsSortedLocal.forEach((row, index) => {
      row.__real_index = index;
    });

    if (!orderBy.length && !firstRowsSelected) return rowsSortedLocal;

    if (firstRowsSelected) {
      rowsSortedLocal.sort((itemPrev, itemPos): any => {
        let itemPrevIsSelected = false;
        let itemPosIsSelected = false;

        const itemPrevKey = extractRowKey(itemPrev, props.keyAttribute);
        const itemPosKey = extractRowKey(itemPos, props.keyAttribute);

        for (const item of selectedItems) {
          const itemKey = extractRowKey(item, props.keyAttribute);

          itemPrevIsSelected = itemPrevIsSelected || itemKey === itemPrevKey;
          itemPosIsSelected = itemPosIsSelected || itemKey === itemPosKey;

          if (itemPrevIsSelected && itemPosIsSelected) break;
        }

        if (itemPrevIsSelected && !itemPosIsSelected) return -1;
        if (itemPrevIsSelected && itemPosIsSelected) return 0;
      });
    }

    if (orderBy.length) {
      const normalize = (val) => {
        if (typeof val !== 'string') return val;
        return val.normalize('NFD').toLowerCase();
      };

      orderBy.forEach((sortInfo) => {
        rowsSortedLocal.sort((itemPrev, itemPos) => {
          const { column, order } = sortInfo;

          const valueItemPrev = normalize(itemPrev[column]);
          const valueItemPos = normalize(itemPos[column]);

          const emptyValues = [undefined, null, ''];

          const small = order === 'ASC' ? -1 : 1;
          const big = order === 'ASC' ? 1 : -1;

          if (emptyValues.includes(valueItemPrev)) return small;
          if (emptyValues.includes(valueItemPos)) return big;

          if (valueItemPrev < valueItemPos) return big;
          if (valueItemPrev > valueItemPos) return small;

          return 0;
        });
      });
    }

    return rowsSortedLocal;
  }, [firstRowsSelected, selectedItems, rows, orderBy]);

  const rowsFiltered = React.useMemo(() => {
    if (!filterText?.trim?.()) return rowsSorted;

    return rowsSorted.filter((row) => {
      return columns.some(({ key }) => {
        const value = `${row[key]}`;
        return filter_text(value, filterText);
      });
    });
  }, [filterText, rowsSorted]);

  const rowsCurrentPage = isPaginateAPI
    ? rowsFiltered
    : rowsFiltered.slice((page - 1) * rowsPerPage, rowsPerPage * page);

  const totalRows = !!isPaginateAPI ? totalRowsAPI : rowsFiltered.length;
  const totalPages = Math.ceil(totalRows || 0 / rowsPerPage);
  const containerRowsHeight = rowsCurrentPage.length * rowHeight;
  const columnsToRender = columns.filter((column) => !hideColumns.includes(column.key));
  const gridTemplate = getGridTemplateString(columnsToRender);
  const hasSwitchRoot = checkHasSwitch(props);
  const actionsRoot = getSerializedActions(props);
  const widthHeaderAndRows = getWidthHeaderAndRows(actionsRoot, !!hasCheckboxRoot, !!hasSwitchRoot);
  const hasAcordeonRoot = typeof props.acordeonExtract === 'function';

  const specifyRowSerialized = specifyRow?.map?.((value) => `span ${value}`);

  const specifyColumnSerialized = (() => {
    let specifyLocal = specifyColumn;

    if (props.size3) {
      specifyLocal = [3, 3, 2, 2, 1];
    }
    if (props.size4) {
      specifyLocal = [4, 4, 3, 2, 1];
    }

    return specifyLocal.map((value) => `span ${value}`);
  })();

  const isSelectedAllRoot = React.useMemo(() => {
    if (!selectJustPageRows) return rows.length === selectedItems?.length;

    const itemsSelectedCurrentPage = rowsCurrentPage.filter((row) => {
      const keyRow = extractRowKey(row, props.keyAttribute);

      return !!selectedItems.some((item) => {
        return extractRowKey(item, props.keyAttribute) === keyRow;
      });
    });

    return itemsSelectedCurrentPage.length === rowsCurrentPage.length;
  }, [selectedItems, rows, page]);

  const infosSerialized = React.useMemo<IInfo[]>(() => {
    if (!showTotalRowsSelected || !selectedItems?.length) return infos || [];
    return [{ label: 'Selecionados', value: selectedItems?.length }, ...(infos || [])];
  }, [infos, selectedItems]);

  const onFilterText = props.onFilterText ? useDebounce(props.onFilterText, 2000) : undefined;

  const handleFilterText = React.useCallback(
    (text: string) => {
      setFilterText(text);
      onFilterText?.(text);
    },
    [onFilterText],
  );

  const handleChangePage = React.useCallback(
    (numPage: number) => {
      if (loading) return;
      props.onChangePage?.(numPage);
      setLocalPage(numPage);
    },
    [props.onChangePage, isPaginateAPI, loading],
  );

  const onScroll = React.useCallback((event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const element = event.target as HTMLDivElement;
    setScroll({ left: element.scrollLeft, top: element.scrollTop });
  }, []);

  const onOpenAcordeon = React.useCallback((keyRow: React.Key, checked: boolean) => {
    setOpenedRows((prevState) => {
      const newState = new Map(prevState);
      checked ? newState.set(keyRow, true) : newState.delete(keyRow);
      return newState;
    });
  }, []);

  const onSelectAll = React.useCallback(
    (checked: boolean) => {
      const items = selectJustPageRows ? rowsCurrentPage : rows;
      checked ? props?.onSelect?.(items) : props?.onSelect?.([]);
    },
    [selectJustPageRows, rowsCurrentPage, rows, props.onSelect],
  );

  const onShowMoreActions = React.useCallback((keyRow: React.Key, checked: boolean) => {
    setKeyRowActiveShowMoreActions(checked ? keyRow : undefined);
  }, []);

  const rowsDetails = (() => {
    const numberOfRowsToShowOnScreen = Number(heightTableBody) / rowHeight;
    const scrollResult = scroll instanceof Function ? scroll() : scroll;
    let first = Math.ceil(Number(scrollResult?.top || 0) / rowHeight) - 1;
    const last = first + numberOfRowsToShowOnScreen + 11;

    first = first >= numberOfRowsToShowOnScreen ? first - numberOfRowsToShowOnScreen : 0;

    return { first, last, numberOfRowsToShowOnScreen };
  })();

  const rowsRender = (() => {
    let rowsComponent: any[] = [];

    interface IMakeRowParams {
      row: any;
      currentProps: ITableCommomProps;
      rowIndex: number;
      gridTemplateRow: string;
      width: string;
      hasSwitch?: boolean;
      hasAcordeon?: boolean;
      parentKeyRow?: React.Key;
      depth?: number;
      isMoreActionsMenuInverted?: boolean;
    }

    const makeRow = ({
      row,
      currentProps,
      rowIndex,
      gridTemplateRow,
      width,
      hasSwitch,
      parentKeyRow,
      hasAcordeon,
      depth = 0,
      isMoreActionsMenuInverted,
    }: IMakeRowParams) => {
      const index = rowsComponent.length + rowsDetails.first;
      const top = index * rowHeight;
      const isOdd = index % 2 === 0;
      const childrenProps = currentProps?.acordeonExtract?.(row);
      const originalKeyRow = extractRowKey(row, currentProps.keyAttribute);
      const keyRow = parentKeyRow ? `${parentKeyRow}:${originalKeyRow}` : originalKeyRow;
      const columnsRow = depth === 0 ? columnsToRender : currentProps.columns;
      const actionsRow = getSerializedActions(currentProps);
      const acordeonIsOpenend = openedRows.get(keyRow);
      const showMoreActionsIsActive = keyRow === keyRowActiveShowMoreActions;
      const isActive = !!row.status;
      const count = currentProps?.countAttribute ? row[currentProps?.countAttribute] : undefined;
      const selectDisabled = !!row.checkbox_disabled;
      const statusDisabled = !!row.status_disabled;
      const countBackgroundColor = row.colorIcon;
      const actionsLabels = row.actionsLabels;
      const rowBackgroundColor = row.backgroundColor;
      const rowColor = row.color;
      const valueCaseNull = currentProps.valueCaseNull;

      const rowActions = !Array.isArray(row.removeActions)
        ? actionsRow
        : actionsRow.filter((action) => !row.removeActions.includes(action));

      const isSelected = currentProps?.selectedItems?.some?.((item) => {
        return extractRowKey(item, currentProps.keyAttribute) === originalKeyRow;
      });

      const onClickStatus = (checked: boolean) => currentProps?.onClickStatus?.({ ...row }, checked, rowIndex);

      const onClickAction = (action: ColumnAction) => {
        const actionFunctionName = actionsFunctionName[action];
        const fn = currentProps?.[actionFunctionName];

        fn?.({ ...row }, rowIndex);
      };

      const onSelect = (checked: boolean) => {
        if (checked) {
          currentProps?.onSelect?.([...(currentProps.selectedItems || []), row]);
        } else {
          currentProps?.onSelect?.(
            currentProps?.selectedItems?.filter?.((item) => {
              return extractRowKey(item, currentProps.keyAttribute) !== originalKeyRow;
            }) || [],
          );
        }
      };

      const rowComponent = (
        <TableRow
          key={keyRow}
          keyRow={keyRow}
          top={top}
          isOdd={isOdd}
          depth={depth}
          gridTemplate={gridTemplateRow}
          width={width}
          isMoreActionsMenuInverted={isMoreActionsMenuInverted}
          actions={rowActions}
          hasCheckbox={currentProps.checkbox}
          hasSwitch={hasSwitch}
          isActive={isActive}
          isSelected={isSelected}
          count={count}
          onStatus={onClickStatus}
          onAction={onClickAction}
          onSelect={onSelect}
          disabledSelect={selectDisabled}
          disabledStatus={statusDisabled}
          backgroundColor={rowBackgroundColor}
          color={rowColor}
          countBackground={countBackgroundColor}
          actionsLabels={actionsLabels}
          showMoreActions={showMoreActionsIsActive}
          onShowMoreActions={onShowMoreActions}
          hasAcordeon={hasAcordeon}
          onOpenAcordeon={onOpenAcordeon}
          acordeonOpened={acordeonIsOpenend}
        >
          {columnsRow.map((column) => {
            const cellValue = row[column.key];
            const {
              type,
              inputFunction,
              inputType,
              valueCaseFalse,
              valueCaseTrue,
              valueCaseNull: valueCaseNullCell = valueCaseNull,
            } = column;

            return (
              <TableColumn
                key={`${keyRow}:${column.key}`}
                type={type}
                label={cellValue}
                valueCaseNull={valueCaseNullCell}
                valueCaseFalse={valueCaseFalse}
                valueCaseTrue={valueCaseTrue}
                inputType={inputType}
                inputFunction={(e) => inputFunction?.(row, e, rowIndex, column.key)}
              />
            );
          })}
        </TableRow>
      );

      rowsComponent = [...(rowsComponent || []), rowComponent].filter(Boolean);

      if (acordeonIsOpenend) {
        const childrenDepth = depth + 1;
        const childrenSelectedItems = childrenProps?.selectedItems;
        const childrenRows = childrenProps?.rows;
        const childrenColumns = childrenProps?.columns;
        const topHeaderAcordeon = (index + 1) * rowHeight;
        const hasAcordeonInChildren = !!childrenProps?.acordeonExtract;
        const isSelectedAllChildren = childrenRows?.length && childrenRows?.length === childrenSelectedItems?.length;
        const hasSwitchChildren = checkHasSwitch(childrenProps);
        const gridTemplateHeaderChildren = getGridTemplateString(childrenColumns);
        const widthHeaderAndRowsChildren = getWidthHeaderAndRows(
          childrenProps?.actions || [],
          !!childrenProps?.checkbox,
          !!childrenProps?.status,
          childrenDepth,
        );

        const headerAcordeon = (
          <TableHeader
            depth={childrenDepth}
            key={`${keyRow}:children-header`}
            columns={childrenColumns || []}
            gridTemplate={gridTemplateHeaderChildren}
            width={widthHeaderAndRowsChildren}
            hasCheckbox={!!childrenProps?.checkbox}
            hasAcordeon={hasAcordeonInChildren}
            isSelectedAll={!!isSelectedAllChildren}
            onSelectAll={onSelectAll}
            top={topHeaderAcordeon}
          />
        );

        rowsComponent.push(headerAcordeon);

        childrenProps?.rows?.forEach((r, i) =>
          makeRow({
            row: r,
            currentProps: childrenProps,
            rowIndex: i,
            gridTemplateRow: gridTemplateHeaderChildren,
            width: widthHeaderAndRowsChildren,
            depth: childrenDepth,
            hasSwitch: hasSwitchChildren,
            parentKeyRow: keyRow,
            hasAcordeon: hasAcordeonInChildren,
          }),
        );
      }
    };

    const rowsInView = rowsCurrentPage.slice(rowsDetails.first, rowsDetails.last);

    rowsInView.forEach((row, index) => {
      const i = index >= 11 ? index - 11 : index;
      const isMoreActionsMenuInverted = i + 4 >= rowsDetails.numberOfRowsToShowOnScreen;

      return makeRow({
        row,
        currentProps: props,
        gridTemplateRow: gridTemplate,
        rowIndex: row.__real_index,
        width: widthHeaderAndRows,
        hasAcordeon: !!props.acordeonExtract,
        hasSwitch: hasSwitchRoot,
        isMoreActionsMenuInverted,
      });
    });

    return rowsComponent;
  })();

  React.useEffect(() => {
    props.onChangeLimit?.(rowsPerPage);
  }, [rowsPerPage]);

  React.useEffect(() => {
    if (openedRows.size) {
      setOpenedRows(new Map());
    }

    (refTableBody?.current as any)?.setScroll?.({ top: 0 });
  }, [page, filterText]);

  React.useEffect(() => {
    if (page > totalPages) handleChangePage(1);
    if (!rows.length) setScroll({ left: 0, top: 0 });
  }, [rows]);

  if (hidden) return null;

  if (!rows.length) {
    return (
      <RowCompatibility noBorder specifyColumn={specifyColumnSerialized} specifyRow={specifyRowSerialized}>
        <EmptyData icon={props.iconEmpty} noPadding>
          {emptyText}
        </EmptyData>
      </RowCompatibility>
    );
  }

  return (
    <SC.Container
      style={{ maxHeight, minHeight }}
      specifyColumn={specifyColumnSerialized}
      specifyRow={specifyRowSerialized}
    >
      <TopBar
        loading={loading}
        infos={infosSerialized}
        totalRows={showTotalRows ? (isPaginateAPI ? totalRowsAPI : rowsFiltered.length) : undefined}
        extraHeaderButtons={extraHeaderButtons}
        columns={columns}
        onFilterText={handleFilterText}
        hideColumns={hideColumns}
        onHideColumns={setHideColumns}
        orderBy={orderBy}
        onOrderBy={setOrderBy}
        keyForceDropDownItems={keyForceDropDownItems}
      />

      <SC.ContentWrapper>
        <TableHeader
          columns={columnsToRender}
          gridTemplate={gridTemplate}
          width={widthHeaderAndRows}
          hasCheckbox={hasCheckboxRoot}
          hasAcordeon={hasAcordeonRoot}
          isSelectedAll={isSelectedAllRoot}
          onSelectAll={onSelectAll}
        />

        <TableBody ref={refTableBody} height={containerRowsHeight} onScroll={onScroll}>
          {rowsRender}
        </TableBody>
      </SC.ContentWrapper>

      <BottomBar
        totalPages={totalPages}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={setRowsPerPage}
        firstRowsSelected={firstRowsSelected}
        onChangeFirstRowsSelected={setFirstRowsSelected}
        selectJustPageRows={selectJustPageRows}
        onChangeSelectJustPageRows={setSelectJustPageRows}
      />
    </SC.Container>
  );
};
