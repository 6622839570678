import React, { useCallback, useEffect, useState } from 'react';
import { FrameData } from '../../components/FrameData';
import ReCAPTCHA from 'react-google-recaptcha';
import { RouteComponentProps } from 'react-router-dom';
import { createReclamante } from '../../api/services/Reclamante';
import { getMunicipioByParqueServico } from '../../api/services/ParqueService';
import { prefix } from '../../router';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useToast } from '../../hooks/toast';
import { AutoRow } from 'components/AutoRow';
import { Title } from 'components/AutoRow/components/Title';
import { Paragraph } from 'components/AutoRow/components/Paragraph';
import { CaptchContainer } from './styles';
import Input from 'components/AutoRow/components/Input';
import { Button } from 'components/AutoRow/components/Button';

interface IFormData {
  cod_municipio?: string;
  nom_cpf?: string;
  nom_email?: string;
  nom_fone1?: string;
  nom_nome_completo?: string;
  nom_senha?: string;
  recaptcha?: string;
}

function validatePhoneField(field: string) {
  if (field) {
    return field.replace(/[^0-9]+/g, '');
  }
  return field;
}

const IframeAccount = ({ history }: RouteComponentProps) => {
  let captcha: any;

  const setCaptchaRef = (ref: any) => {
    if (ref) {
      captcha = ref;
    }
  };

  const [formData, setFormData] = useState<IFormData>();
  // const [, setToken] = useLocalStorage<string | undefined>('token', '');
  const [codParqueServico] = useLocalStorage<number>('cod_parque_servico', -1);
  // const [, setDadosReclamante] = useLocalStorage<IReclamante | string>('reclamante', {});
  const toast = useToast();
  let inProgress = false;

  function handleCancel() {
    history.push(`${prefix}/`);
  }

  function handleRedirectToIframeHome() {
    // const { token, complainant: dadosReclamante } = dados;

    // setToken(token);
    // setDadosReclamante(dadosReclamante);

    handleCancel();
  }

  function handleSetFormData(event: React.ChangeEvent<HTMLInputElement>) {
    const { value, name } = event.target;

    setFormData((oldValue) => ({ ...oldValue, [name]: value }));
  }

  async function handleCreateUser(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!formData?.nom_fone1) {
      toast.addToast({ description: 'O telefone é obrigatório!', type: 'warn' });
      return;
    }

    if (inProgress) return;

    const objectUser = {
      ...formData,
      nom_fone1: validatePhoneField(formData.nom_fone1),
    };

    captcha.reset();

    inProgress = true;
    const response: any = await createReclamante(objectUser);
    inProgress = true;

    if (response.failed) {
      if (response.causes) {
        response?.causes?.forEach((cause: string) => {
          toast.addToast({ type: 'warn', description: cause });
        });
      } else {
        toast.addToast({ type: 'warn', description: response.message });
      }
      inProgress = false;
      return;
    }

    toast.addToast({
      type: 'success',
      description: 'Usuario cadastrado com sucesso!',
    });

    handleRedirectToIframeHome();
  }

  const loadParqueServicoData = useCallback(
    async (idParqueServico: string | number | any) => {
      if (!idParqueServico || idParqueServico === 'null') {
        toast.addToast({
          type: 'warn',
          description: 'Ocorreu um erro ao buscar o parque de serviço, você será redirecionado para a página inicial!',
        });

        handleCancel();
        return;
      }
      const response = await getMunicipioByParqueServico(idParqueServico);

      if (response.err) {
        toast.addToast({
          type: 'warn',
          description: response.err,
        });
      }

      setFormData((oldValue) => ({
        ...oldValue,
        cod_municipio: response.resp.cod_municipio,
      }));
    },
    [toast],
  );

  const onChangeRecaptcha = (value: any) => {
    setFormData((oldValue) => ({
      ...oldValue,
      recaptcha: value,
    }));
  };

  useEffect(() => {
    if (!codParqueServico) return;

    loadParqueServicoData(codParqueServico);

    return () => setFormData({});
  }, [codParqueServico, loadParqueServicoData]);

  return (
    <FrameData>
      <form onSubmit={handleCreateUser}>
        <AutoRow>
          <Title label="Cadastrar usuário" line="34px" />
          <Paragraph marginBottom={16} marginTop={-4} label="Informe os dados abaixo para a criação do seu usuário:" />

          <Input
            handleFunction={handleSetFormData}
            required
            label="Nome*"
            name="nom_nome_completo"
            value={formData?.nom_nome_completo}
          />

          <Input
            handleFunction={handleSetFormData}
            label="CPF*"
            name="nom_cpf"
            required
            type="tel"
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/]}
            pattern="[0-9]{3}.[0-9]{3}.[0-9]{3}.[0-9]{2}"
            value={formData?.nom_cpf}
          />

          <Input
            handleFunction={handleSetFormData}
            mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            label="Telefone*"
            name="nom_fone1"
            type="tel"
            value={formData?.nom_fone1}
          />

          <Input
            required
            handleFunction={handleSetFormData}
            label="Email*"
            type="email"
            name="nom_email"
            value={formData?.nom_email}
          />

          <Input
            required
            handleFunction={handleSetFormData}
            label="Senha**"
            type="password"
            name="nom_senha"
            value={formData?.nom_senha}
          />

          <Paragraph label="*Item obrigatório" />

          <Paragraph
            marginBottom={26}
            label="**Sua senha deve conter no mínimo um caractere especial, uma letra maíuscula, um número e no mínimo 8 caracteres."
          />

          <CaptchContainer>
            <ReCAPTCHA
              ref={(r) => setCaptchaRef(r)}
              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA || ''}
              onChange={onChangeRecaptcha}
            />
          </CaptchContainer>

          <Button onClick={handleCancel} backgroundColor="light">
            Cancelar
          </Button>

          <Button type="submit">Cadastrar</Button>
        </AutoRow>
      </form>
    </FrameData>
  );
};

export default IframeAccount;
