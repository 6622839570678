import { IApiResponse, IFixResponse, api } from '../index';

import generateQuery from '../../utils/generateQuery';

type IDadosLogin = {
  email: string;
  password: string;
};

interface IFormData {
  cod_municipio?: string;
  nom_cpf?: string;
  nom_email?: string;
  nom_fone1?: string;
  nom_nome_completo?: string;
  nom_senha?: string;
  recaptchaResponse?: string;
}

export async function loginReclamante(dadosLogin: IDadosLogin): Promise<IApiResponse> {
  const res: IApiResponse = await api.post(`/auth/complainer/login_cidadao`, dadosLogin);

  return res;
}

interface IResetPasswordData {
  email: string;
}

interface INewPasswordData {
  token: string;
  new_password: string;
  verify_password: string;
}

export async function resetPasswordReclamante(dadosReset: IResetPasswordData): Promise<IApiResponse> {
  const res: IApiResponse = await api.post(`/auth/v1/complainer/forgot_password`, dadosReset);

  return res;
}

export async function newPasswordReclamante(dadosReset: INewPasswordData): Promise<IApiResponse> {
  const res: IApiResponse = await api.post(`/auth/v1/complainer/reset_password`, dadosReset);

  return res;
}

export async function createReclamante(dadosLogin: IFormData | undefined): Promise<IApiResponse> {
  const res: IApiResponse = await api.post(`/auth/v1/complainer/create`, dadosLogin);

  return res;
}

interface IetOcorrenciasByIdReclamanteOptions {
  cod_parque_servico?: number;
}

export async function getOcorrenciasByIdReclamante(
  cod_reclamante: number,
  options: IetOcorrenciasByIdReclamanteOptions = {},
): Promise<IFixResponse> {
  const query = generateQuery(options);

  const res: IApiResponse = await api.get(`/reclamante/ocorrencia/listagem/${cod_reclamante}?${query}`);

  if (res.failed) {
    if (res.message) {
      return { err: res.message, data: null };
    }
    return { err: res.err, data: null };
  }

  const { data } = res;
  return { err: null, resp: data };
}

export async function cancelarOcorrencia(seq_ocorrencia_ponto_servico: number) {
  return (await api.put(`/management/ocorrencia/cancelar-ops/${seq_ocorrencia_ponto_servico}`)) as IApiResponse;
}

export interface ITipoOcorrencia {
  cod_prioridade_ocorrencia: number;
  nom_descricao: string;
  seq_tipo_ocorrencia: number;
}

export async function getTipoOcorrencia(
  cod_parque_servico?: number,
  is_iframe?: boolean,
): Promise<IApiResponse<ITipoOcorrencia[]>> {
  return await api.get(`/management/ocorrencia/tipo/ocorrencia/listar/tipos`, {
    params: { cod_parque_servico, is_iframe },
  });
}

export async function getTipoOcorrenciaAnonimo(
  cod_parque_servico?: number,
  is_iframe?: boolean,
): Promise<IApiResponse<ITipoOcorrencia[]>> {
  return await api.get(`/external/ocorrencia/tipo/ocorrencia/listar/tipos`, {
    params: { cod_parque_servico, is_iframe },
  });
}

export async function reclamanteLogout(currentToken: string | any): Promise<any> {
  const res: IApiResponse = await api.get('/auth/complainer/logout', {
    headers: {
      'x-access-token': currentToken,
    },
  });

  return res;
}

export async function verifyTokenPasswordReclamante(token: string): Promise<IApiResponse> {
  const res: IApiResponse = await api.get(`/auth/complainer/verify_token_password?token=${token}`);

  return res;
}
