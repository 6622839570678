import { IApiResponse, IFixResponse, api } from '../index';

export async function getUfById(codEstado: number): Promise<IFixResponse> {
  const res: IApiResponse = await api.get(`/reclamante/localidade/estado/${codEstado}`);

  if (res.failed) {
    if (res.message) {
      return { err: res.message, data: null };
    }
    return { err: res.err, data: null };
  }

  const { data } = res;
  return { err: null, resp: data };
}

export async function getUfByIdAnonimo(codEstado: number): Promise<IFixResponse> {
  const res: IApiResponse = await api.get(`/external/estado/${codEstado}`);

  if (res.failed) {
    if (res.message) {
      return { err: res.message, data: null };
    }
    return { err: res.err, data: null };
  }

  const { data } = res;
  return { err: null, resp: data };
}

export async function getMunicipiosById(codMunicipio: number): Promise<IFixResponse> {
  const res: IApiResponse = await api.get(`/reclamante/localidade/municipio/${codMunicipio}`);

  if (res.failed) {
    if (res.message) {
      return { err: res.message, data: null };
    }
    return { err: res.err, data: null };
  }

  const { data } = res;
  return { err: null, resp: data };
}

export async function getMunicipiosByIdAnonimo(codMunicipio: number): Promise<IFixResponse> {
  const res: IApiResponse = await api.get(`/external/municipio/${codMunicipio}`);

  if (res.failed) {
    if (res.message) {
      return { err: res.message, data: null };
    }
    return { err: res.err, data: null };
  }

  const { data } = res;
  return { err: null, resp: data };
}
