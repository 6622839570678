import axios, { AxiosResponse } from 'axios';

import { addErrorInterceptor } from './interceptors/errorInterceptor';
import { addIFrameInfoOfAllRequests, addTokenOfAllRequests } from './interceptors/authInterceptor';

export interface IFixResponse {
  err?: string | null;
  data?: any;
  resp?: any;
}

export interface IApiResponse<T = any> extends AxiosResponse {
  failed?: boolean;
  data: T;
  message?: string;
  err?: any;
}

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const apiExternal = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const apiNominatim = axios.create({
  baseURL: process.env.REACT_APP_API_NOMINATIM,
});

addTokenOfAllRequests(api);
addIFrameInfoOfAllRequests(api);
addIFrameInfoOfAllRequests(apiExternal);

addErrorInterceptor(api);
addErrorInterceptor(apiExternal);
addErrorInterceptor(apiNominatim);

export default api;
