import React from 'react';
import { Container, Wrapper } from './styles';

interface IHoverClick {
  label: string;
  onClick?: React.MouseEventHandler<HTMLParagraphElement> | undefined;
  switchSides?: boolean;
  large?: boolean;
}

export const HoverClick: React.FC<IHoverClick> = (props) => {
  const { label, onClick, switchSides, large } = { ...props };
  return (
    <Wrapper switchSides={switchSides} large={large}>
      <Container onClick={onClick}>{label}</Container>
    </Wrapper>
  );
};
