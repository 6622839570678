import React, { useContext, useEffect, useState } from 'react';
import iframeContext, { IIframeContext } from '../../contexts/IframeContext';
import { FrameData } from '../../components/FrameData';
import { RouteComponentProps } from 'react-router-dom';
import { createOcorrencia, createOcorrenciaAnonima } from '../../api/services/OcorrenciaPublica';
import { prefix } from '../../router';
import { useToast } from '../../hooks/toast';
import useLocalStorage, { getItemInStorage } from '../../hooks/useLocalStorage';
import { IReclamante } from '../../dtos/Reclamante';
import { AutoRow } from 'components/AutoRow';
import { Title } from 'components/AutoRow/components/Title';
import { Paragraph } from 'components/AutoRow/components/Paragraph';
import Input from 'components/AutoRow/components/Input';
import { Button } from 'components/AutoRow/components/Button';
import SelectTipoOcorrencia from 'helpers/SelectTipoOcorrencia';
import { openStdin } from 'process';

interface IFormData {
  dsc_observacao?: string;
  seq_tipo_ocorrencia?: number;
}

const IframeSend = ({ history }: RouteComponentProps) => {
  const [dadosReclamante] = useLocalStorage<IReclamante>('reclamante', {});
  const { setIframeState, iframeState } = useContext<IIframeContext>(iframeContext);
  const [formData, setFormData] = useState<IFormData>({});
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const toast = useToast();
  const [codParqueServico] = useLocalStorage<number>('cod_parque_servico', -1);
  let inProgress = false;

  function handleRedirectToAddress() {
    history.push(`${prefix}/generate/address`);
  }
  const handleOnChangeFilters = (event) => {
    const name = event?.name || event?.target?.name;
    const value = event?.value || event?.target?.value;
    setFormData((old) => ({ ...old, [name]: value }));
  };
  async function handleCreateOcorrencia(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!formData.seq_tipo_ocorrencia) {
      toast.addToast({
        type: 'warn',
        description: 'Nenhum tipo de ocorrencia foi selecionado!',
      });
      return;
    }

    if (inProgress) return;
    console.log(iframeState);

    inProgress = true;

    setLoadingSubmit(true);
    let response;

    if (!getItemInStorage('accessToken')) {
      response = await createOcorrenciaAnonima({
        ocorrencia: iframeState?.dadosCadastroOcorrencia?.ocorrencia,
        ocorrenciasPontoServico:
          iframeState?.dadosCadastroOcorrencia?.ocorrenciasPontoServico?.map((ops) => ({
            ...ops,
            cod_tipo_ocorrencia: formData?.seq_tipo_ocorrencia,
            dsc_observacao: formData?.dsc_observacao,
          })) || [],
      });
    } else {
      response = await createOcorrencia({
        ocorrencia: {
          ...iframeState?.dadosCadastroOcorrencia?.ocorrencia,
          cod_reclamante: dadosReclamante.seq_reclamante,
        },
        ocorrenciaPontoServico: {
          ...iframeState?.dadosCadastroOcorrencia?.ocorrenciasPontoServico?.[0],
          cod_tipo_ocorrencia: formData.seq_tipo_ocorrencia,
          dsc_observacao: formData.dsc_observacao,
        },
      });
    }
    inProgress = false;
    setLoadingSubmit(false);

    if (response?.resp?.err) {
      toast.addToast({ type: 'warn', description: response?.resp?.err });
    }

    toast.addToast({
      type: 'success',
      description: 'Ocorrência cadastrada com sucesso!',
    });

    if (setIframeState) {
      setIframeState((oldValue) => ({
        ...oldValue,
        dadosCadastroOcorrencia: {},
      }));
    }

    history.push(`${prefix}/home`);
  }
  useEffect(() => {
    if (!iframeState || (iframeState && !iframeState?.dadosCadastroOcorrencia)) {
      history.push(`${prefix}/home`);
    }
  }, [iframeState]);

  return (
    <FrameData loading={loadingPage}>
      <form onSubmit={handleCreateOcorrencia}>
        <AutoRow>
          <Title label="Geração de Ocorrência" />
          <Paragraph marginBottom={26} marginTop={-4} label="Para finalizar, informe os dados finais necessários" />

          <SelectTipoOcorrencia
            isObrigatorio
            cod_parque_servico={codParqueServico}
            handleFunction={handleOnChangeFilters}
            value={formData.seq_tipo_ocorrencia as any}
            anonimo={!getItemInStorage('accessToken')}
            is_iframe={true}
            name="seq_tipo_ocorrencia"
          />
          <Input
            handleFunction={handleOnChangeFilters}
            label="Observação"
            name="dsc_observacao"
            value={formData.dsc_observacao}
          />
          <Button onClick={handleRedirectToAddress} backgroundColor="light">
            Cancelar
          </Button>
          <Button loading={loadingSubmit} type="submit">
            Enviar
          </Button>
        </AutoRow>
      </form>
    </FrameData>
  );
};

export default IframeSend;
