import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  createOcorrencia,
  createOcorrenciaAnonima,
  findOnePontoServicoByEtiqueta,
  findOnePontoServicoByEtiquetaAnonimo,
} from '../../api/services/OcorrenciaPublica';
import iframeContext, { IIframeContext } from '../../contexts/IframeContext';
import { FrameData } from '../../components/FrameData';
import { ITiposOcorrencia } from '../../dtos/TipoOcorrencia';
import { RouteComponentProps } from 'react-router-dom';
import { prefix } from '../../router';
import { getItemInStorage, useLocalStorage } from '../../hooks/useLocalStorage';
import { useToast } from '../../hooks/toast';
import { IReclamante } from '../../dtos/Reclamante';
import { LoadingComponent } from 'components/Loading';
import { AutoRow } from 'components/AutoRow';
import { Title } from 'components/AutoRow/components/Title';
import { Paragraph } from 'components/AutoRow/components/Paragraph';
import { Button } from 'components/AutoRow/components/Button';
import Input from 'components/AutoRow/components/Input';
import { Select } from 'components/AutoRow/components/Select';
import SelectTipoOcorrencia from 'helpers/SelectTipoOcorrencia';

interface IFormData {
  seq_tipo_ocorrencia?: number;
  dsc_observacao?: string;
  etiqueta: string;
}

const IframeTag = ({ history }: RouteComponentProps) => {
  const [formData, setFormData] = useState<IFormData>({
    dsc_observacao: '',
    etiqueta: '',
  });
  const { iframeState, setIframeState } = useContext<IIframeContext>(iframeContext);
  const [codParqueServico] = useLocalStorage<number>('cod_parque_servico', -1);
  const [dadosReclamante] = useLocalStorage<IReclamante>('reclamante', {});
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const toast = useToast();

  function handleRedirectGenerateOcorrencia() {
    if (setIframeState) {
      setIframeState((oldValue) => ({ ...oldValue, tipoBusca: '' }));
    }
    history.push(`${prefix}/home`);
  }

  const handleOnChangeFilters = (event) => {
    const name = event?.name || event?.target?.name;
    const value = event?.value || event?.target?.value;
    setFormData((old) => ({ ...old, [name]: value }));
  };

  async function handleSearchPontoServicoByEtiqueta() {
    let response;
    if (!getItemInStorage('accessToken')) {
      response = await findOnePontoServicoByEtiquetaAnonimo(formData.etiqueta);
    } else {
      response = await findOnePontoServicoByEtiqueta(formData.etiqueta);
    }

    if (response.err) {
      toast.addToast({ type: 'warn', description: response.err });
    }

    return response.resp;
  }

  async function handleCreateOcorrencia(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!formData.seq_tipo_ocorrencia) {
      toast.addToast({
        type: 'warn',
        description: 'Nenhum tipo de ocorrencia foi selecionado!',
      });
      return;
    }

    setLoadingSubmit(true);

    const pontoservico = await handleSearchPontoServicoByEtiqueta();

    setLoadingSubmit(false);

    if (!pontoservico) return;

    const { seq_tipo_ocorrencia: cod_tipo_ocorrencia, dsc_observacao } = formData;

    const {
      nom_endereco: nom_endereco_livre,
      seq_ponto_servico: cod_ponto_servico,
      cod_bairro,
      cod_municipio,
      cod_parque_servico,
      latitude,
      longitude,
    } = pontoservico;

    if (cod_parque_servico !== codParqueServico) {
      toast.addToast({ description: 'A etiqueta digitada não pertence ao parque de serviço atual!', type: 'warn' });
      return;
    }

    const dados = {
      ocorrencia: {
        cod_parque_servico,
        cod_municipio,
        cod_tipo_origem_ocorrencia: 5,
      },
      ocorrenciasPontoServico: [
        {
          cod_tipo_ocorrencia,
          nom_endereco_livre,
          cod_ponto_servico,
          cod_bairro,
          cod_municipio,
          dsc_observacao,
          json_ocorrencia_ponto_servico: {
            coords: {
              latitude,
              longitude,
            },

            endereco: {},
            imagens: [{ nom_caminho_imagem: null }],
          },
        },
      ],
    };

    setLoadingSubmit(true);
    let response;
    if (!getItemInStorage('accessToken')) {
      response = await createOcorrenciaAnonima({ ...dados });
    } else {
      response = await createOcorrencia({ ...dados, cod_reclamante: dadosReclamante.seq_reclamante });
    }
    setLoadingSubmit(false);

    if (response.err) {
      toast.addToast({ type: 'warn', description: response.err });
    }

    toast.addToast({
      type: 'success',
      description: 'Ocorrencia cadastrada com sucesso!',
    });

    history.push(`${prefix}/home`);
  }

  useEffect(() => {
    if (!iframeState || !iframeState.tipoBusca) {
      history.push(`${prefix}/generate`);
    }
  }, [iframeState]);

  return (
    <FrameData loading={loadingPage}>
      <form onSubmit={handleCreateOcorrencia}>
        <AutoRow>
          <Title label="Geração de Ocorrência" />
          <Paragraph marginBottom={26} marginTop={-4} label="Informe a etiqueta e os outros dados necessários" />

          <Input
            handleFunction={handleOnChangeFilters}
            name="etiqueta"
            label="Etiqueta*"
            required
            value={formData.etiqueta}
          />
          <SelectTipoOcorrencia
            isObrigatorio
            cod_parque_servico={codParqueServico}
            handleFunction={handleOnChangeFilters}
            value={formData.seq_tipo_ocorrencia as any}
            anonimo={!getItemInStorage('accessToken')}
            is_iframe
            name="seq_tipo_ocorrencia"
          />
          <Input
            value={formData.dsc_observacao}
            name="dsc_observacao"
            handleFunction={handleOnChangeFilters}
            label="Observação"
            required
            stretch={2}
          />
          <Button onClick={handleRedirectGenerateOcorrencia} backgroundColor="light">
            Cancelar
          </Button>
          <Button loading={loadingSubmit} type="submit">
            Enviar
          </Button>
        </AutoRow>
      </form>
    </FrameData>
  );
};

export default IframeTag;
