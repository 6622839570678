import { useCallback, useState } from 'react';

export const useToggle = (initialValue?: boolean) => {
  const [toggle, setToggle] = useState(!!initialValue);

  const changeToggle = useCallback((state?) => {
    setToggle((prevState) => (typeof state === 'boolean' ? state : !prevState));
  }, []);

  return [toggle, changeToggle] as const;
};
