import styled from 'styled-components';
import { props_place_4, props_place_5 } from '../../methods';
import { hexToRgb } from '../../../../hooks/helpers/methods';

export interface IContainer {
  margin?: string;
  hiddenButton?: boolean;
  place?: string;
  stretch?: number;
  placeImportant?: string;
}

export const Container = styled.div<IContainer>`
  ${({ placeImportant }) => (placeImportant ? `grid-column: ${placeImportant} !important;` : '')}
  ${(props) =>
    props.place || props.stretch
      ? 'grid-column: ' + (props.place ? props.place : '') + ' ' + (props.stretch ? `span ${props.stretch}` : '')
      : ''}
  @media (max-width: 1610px) {
    ${(props) =>
      props.place || props.stretch
        ? 'grid-column: ' +
          (props.place ? props_place_5(props.place) : '') +
          ' ' +
          (props.stretch ? `span ${props.stretch}` : '')
        : ''}
  }
  @media (max-width: 1030px) {
    ${(props) =>
      props.place || props.stretch
        ? 'grid-column: ' +
          (props.place ? props_place_4(props.place) : '') +
          ' ' +
          (props.stretch ? `span ${props.stretch}` : '')
        : ''}
  }
  @media (max-width: 770px) {
    grid-column: unset;
  }
  @media (max-width: 430px) {
    grid-column: unset;
  }
  ${({ margin }) => (margin ? `margin: ${margin};` : '')}
  ${({ hiddenButton }) => (hiddenButton ? `display: none;` : '')}
  align-self: end;
`;

export interface IRoundedButton {
  backgroundColor?: 'light' | 'dark' | '';
  loadingButton?: boolean;
  alternarCor?: [boolean, string];
  smallFont?: boolean;
  invert?: boolean;
  small?: boolean;
  topic?: boolean;
  medium?: boolean;
}

export const RoundedButton = styled.button<IRoundedButton>`
  height: ${(props) => (props.medium ? '35px' : props.topic ? '30px' : props.small ? '26px' : '40px')};
  width: 100%;
  border-radius: 10px;
  /* text-transform: capitalize; */
  font-family: 'Roboto', sans-serif;
  font-size: ${(props) => (props.smallFont || props.small || props.topic || props.medium ? '12px' : '14px')};
  color: ${(props) =>
    props.backgroundColor === 'light' ? '#7764e4' : props.backgroundColor === 'dark' ? '#fff' : '#fff'};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => (props.topic ? '8px 14px' : '4px 16px')};
  background-color: ${(props) =>
    props.backgroundColor === 'light' ? '#ebe7ff' : props.backgroundColor === 'dark' ? '#7764e4' : '#7764e4'};
  ${(props) => props.invert && `background-color: ${props.theme.colors.primary} !important`};
  gap: 14px;
  ${({ loadingButton }) => (loadingButton ? 'cursor: not-allowed' : '')};
  border: ${(props) => (props.topic ? '0.5px' : '0.8px')} solid
    rgba(
      ${(props) =>
        hexToRgb(
          props.backgroundColor === 'light' ? '#ebe7ff' : props.backgroundColor === 'dark' ? '#7764e4' : '#7764e4',
          true,
        )},
      20%
    );

  &:hover {
    border: ${(props) => (props.topic ? '0.5px' : '0.8px')} solid
      rgba(
        ${(props) =>
          hexToRgb(
            props.backgroundColor === 'light' ? '#ebe7ff' : props.backgroundColor === 'dark' ? '#7764e4' : '#7764e4',
            true,
          )},
        80%
      );
  }

  &:focus {
    outline: unset !important;
  }

  &:disabled {
    background-color: #fff;
    opacity: 0.6;
    cursor: not-allowed;
    border: 2px solid #f5f5f5;
    color: #6e6e6e;
  }

  @media (max-width: 1610px) {
    height: ${(props) => (props.topic ? '30px' : props.small ? '26px' : '35px')};
    font-size: 12px;
  }

  ${({ alternarCor }) => (alternarCor?.length && alternarCor[0] ? `background-color: ${alternarCor[1]}` : '')}

  & svg {
    width: ${(props) => (props.small ? '12px' : props.topic ? '14px' : '16px')};
    height: ${(props) => (props.small ? '12px' : props.topic ? '14px' : '16px')};
    color: ${(props) =>
      props.backgroundColor === 'light' ? '#7764e4' : props.backgroundColor === 'dark' ? '#fff' : '#fff'} !important;
  }
`;
