import styled from 'styled-components';

export interface IChildrenContainer {
  gridTemplate?: string;
  color?: string;
}

interface IContainer {
  isOdd?: boolean;
}

export const Container = styled.section<IContainer>`
  width: 100%;
  min-height: 25px;
  display: flex;
  border-radius: 6px;
  position: absolute;
  left: 0;
  padding-left: 5px;

  background-color: ${(props) => (!props.isOdd ? '#fafafa' : 'transparent')};
`;

export const SwitchContainer = styled.div`
  border-left: 1px solid #eae7e7;
  padding: 0 10px;
  display: flex;
  align-items: center;
  width: 43px;
`;

export const ChildrenContainer = styled.div<IChildrenContainer>`
  height: 100%;
  display: grid;
  grid-template-columns: ${(props) => props.gridTemplate};
  align-items: stretch;

  & > div:first-child {
    border: none !important;
  }

  & > div label,
  & > div input {
    color: ${({ color }) => color};
  }
`;

interface IButtonContainer {
  show?: boolean;
}

export const ButtonContainer = styled.div<IButtonContainer>`
  ${(props) => (!props.show ? `display: none` : `display: flex;`)};
  height: 25px;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding: 0 8px;
  border-left: 1px solid #eae7e7;
`;
