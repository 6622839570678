import { Container, ContainerInput, HelperContainer, Label } from '../../style';
import { HideIcon, ShowIcon, VisibleContainer } from './styles';
import React, { InputHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';

export interface IPasswordInput extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value?: string;
  label: string;
  handleFunction?: (event) => void;
  helperText?: string;
  size?: number;
  margin?: string;
  state?: any;
  setState?: any;
  /**
   * @param off usado para não sugerir ao digitar, porém não impede o autofill
   * @param new-password usado para não utilizar o autofill do navegador
   * @param on usa autofill e sugestão de digitação
   */
  autoComplete?: 'on' | 'off' | 'new-password';
  rememberPassword?: boolean;
}

export const PasswordInput: React.FC<IPasswordInput> = React.forwardRef((props: IPasswordInput, ref) => {
  const {
    name,
    value,
    label,
    handleFunction,
    helperText,
    margin,
    state,
    setState,
    autoComplete,
    rememberPassword = true,
  } = props;

  const defaultRef = useRef();
  const resolvedRef: any = ref || defaultRef;

  const [visible, setVisible] = useState<boolean>(false);

  const onChangeHandler = useCallback(
    (event) => {
      if (handleFunction) {
        handleFunction(event);
      }

      if (setState && state) {
        setState({ ...state, [event.target.name]: event.target.value });
      }
    },
    [handleFunction, setState, state],
  );

  const [enabledReadOnly, setEnabledReadOnly] = useState(false);

  const onFocusHandler = useCallback(() => {
    if (!rememberPassword) {
      setEnabledReadOnly(false);
    }
  }, [rememberPassword, setEnabledReadOnly]);

  useEffect(() => {
    setEnabledReadOnly(!rememberPassword);
  }, [rememberPassword]);

  return (
    <>
      <Container type={visible ? 'text' : 'password'} margin={margin}>
        <Label>{label}</Label>

        <ContainerInput type={visible ? 'text' : 'password'}>
          <input
            name={name}
            type={visible ? 'text' : 'password'}
            value={value}
            onChange={onChangeHandler}
            autoComplete={autoComplete}
            ref={resolvedRef}
            readOnly={enabledReadOnly}
            onFocus={() => onFocusHandler()}
          />
          <VisibleContainer
            // onClick={() => setVisible((prevState) => !prevState)}
            onMouseDown={() => setVisible(true)}
            onMouseUp={() => setVisible(false)}
            onMouseLeave={() => setVisible(false)}
          >
            {visible ? <HideIcon /> : <ShowIcon />}
          </VisibleContainer>
        </ContainerInput>
        <HelperContainer>{helperText && <p>{helperText}</p>}</HelperContainer>
      </Container>
    </>
  );
});

PasswordInput.displayName = 'PasswordInput';
export default PasswordInput;
