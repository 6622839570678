import Input, { IInput } from '../..';

import React from 'react';

export const PhoneInput = React.forwardRef((props: IInput, ref) => {
  return (
    <Input
      {...props}
      mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      ref={ref}
    />
  );
});

PhoneInput.displayName = 'PhoneInput';
export default PhoneInput;
