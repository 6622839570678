// import dayjs from 'dayjs';

import Toast from '../../components/Toast';
import { generateHash } from './generateHash';

// export function parseDate(date) {
//   if (typeof date === 'string') {
//     const checkDateIsParsed = date.split('/');
//     if (checkDateIsParsed.length === 3) return date;
//   }

//   return dayjs(date).format('DD/MM/YYYY');
// }

interface IDataFeatureGeojson {
  type: 'Point' | 'LineString' | 'Polygon';
  locs: [number, number] | [number, number][];
  properties?: any;
}

interface IFeatureGeojson {
  type: 'Feature';
  properties?: any;
  geometry: {
    type: 'Point' | 'LineString' | 'Polygon';
    coordinates: [number, number] | [number, number][];
  };
}

export interface IValidateRequestErros {
  response: any;
  functionSuccess?: React.Dispatch<React.SetStateAction<any>>;
}

export const getAtraso = (dth_limite) => {
  enum tipo_status_limite {
    sem_definicao = 0,
    em_atraso,
    menos_24_horas,
    entre_24_48_horas,
    mais_48_horas,
  }

  const definicoes = {
    [tipo_status_limite.em_atraso]: 'Em atraso',
    [tipo_status_limite.menos_24_horas]: 'Menos de 24 horas',
    [tipo_status_limite.entre_24_48_horas]: 'Entre 24 e 48 horas',
    [tipo_status_limite.mais_48_horas]: 'Mais de 48 horas',
    [tipo_status_limite.sem_definicao]: 'Sem definição',
  };

  const prazo = Date.parse(dth_limite);
  const agora = Date.now();
  const diferenca = prazo - agora;
  const multiplicador = 60 * 60 * 1000;
  let cor_atraso = '#fa029b';
  let tipo_status = tipo_status_limite.sem_definicao;
  let nom_definicao = definicoes[tipo_status_limite.sem_definicao];

  if (diferenca < 0) {
    cor_atraso = '#F00';
    tipo_status = tipo_status_limite.em_atraso;
    nom_definicao = definicoes[tipo_status_limite.em_atraso];
  } else if (diferenca < 24 * multiplicador) {
    cor_atraso = '#F80';
    tipo_status = tipo_status_limite.menos_24_horas;
    nom_definicao = definicoes[tipo_status_limite.menos_24_horas];
  } else if (diferenca < 48 * multiplicador) {
    cor_atraso = '#a4a82a';
    tipo_status = tipo_status_limite.entre_24_48_horas;
    nom_definicao = definicoes[tipo_status_limite.entre_24_48_horas];
  } else if (diferenca >= 48 * multiplicador) {
    cor_atraso = '#4eee1f';
    tipo_status = tipo_status_limite.mais_48_horas;
    nom_definicao = definicoes[tipo_status_limite.mais_48_horas];
  }

  return {
    cor_atraso,
    tipo_status,
    nom_definicao,
  };
};

export const createFeatureGeojson = (dataFeature: IDataFeatureGeojson) => {
  const { type, locs, properties = undefined } = dataFeature;

  return {
    type: 'Feature' as const,
    properties: properties,
    geometry: {
      type: type,
      coordinates: locs,
    },
  };
};

export const createFeatureCollectionGeojson = (features: IFeatureGeojson[]) => {
  return {
    type: 'FeatureCollection',
    features: features,
  };
};

export function downloadFile(data, filename = 'arquivo_download', extension = 'txt') {
  const link = window.document.createElement('a');

  link.href = window.URL.createObjectURL(new Blob([data]));

  link.setAttribute('download', extension ? `${filename}.${extension}` : filename);

  window.document.body.appendChild(link);
  link.click();

  window.document.body.removeChild(link);
  link.remove();
}

export const removeMask = (str) => {
  return `${str}`.replace(/[^0-9]/g, '');
};

export function converTimesTampToLocalBRDate(date: Date) {
  return date.toLocaleDateString('pt-br', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });
}

/**
 * @deprecated Em alguns navegadores, está colocando uma vírgula após o ano "YYYY,-mm-dd hh:mm:ss"
 * use timestampWithoutTZ(date) no lugar
 */
export function converTimesTampToLocalBRHour(date) {
  const formatedDate = date
    .toLocaleDateString('pt-br', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    })
    .split(' ');
  let data = formatedDate[0];
  const hora = formatedDate[1];

  data = data.split('/').reverse().join('-');
  return data + 'T' + hora;
}

export function convertTimeStampLocalBRToDataBase(date: string, returnType: 'string' | 'Date') {
  const string = date.split(' ');
  const data = string[0].split('/').reverse().join('-');
  const hora = string[1];
  const timestamp = data + 'T' + hora;

  if (returnType == 'string') {
    return timestamp;
  } else {
    return new Date(timestamp);
  }
}

export function parseISOToDateStr(dateISO: string) {
  if (!dateISO) return dateISO;
  const date = new Date(dateISO);

  const [data] = date.toLocaleString().split(' ');
  const [dia, mes, ano] = data.split('/');

  return `${ano}-${mes}-${dia}`;
}

export function formatarData(dateISO: string | Date) {
  if (!dateISO) return dateISO as string;
  const date = new Date(dateISO);

  const [data] = date.toLocaleString('pt-br').split(' ');

  return `${data}`;
}

export function get_clean_date(value: Date | string) {
  if (!value) return;

  const tmpDate = new Date(value);
  const ptBr = tmpDate.toLocaleString('pt-BR');

  const [dateTmp, hour] = ptBr.split(' ');
  const date = dateTmp
    .split('-')
    .reverse()
    .map((v, i) => (i == 1 ? `0${v}`.slice(-2) : v))
    .join('/');

  if (ptBr.length > 10) return `${date} ${hour}`;

  return date;
}

// Evita problemas com fuso horário
export function formatarDataHoraString(dateISO: string) {
  if (!dateISO) return '';

  const data = dateISO.slice(0, 10).split('-').reverse().join('/');
  const hora = dateISO.split('T')[1].slice(0, 8);

  return `${data} ${hora}`;
}

export function formatarDataOnlyString(dateISO: string) {
  if (!dateISO) return '';

  const data = dateISO.slice(0, 10).split('-').reverse().join('/');

  return `${data}`;
}

export function formatarDataHora(dateISO: string) {
  if (!dateISO) return dateISO;
  const date = formatarDataHoraString(dateISO);

  const [data, hora] = date.toLocaleString().split(' ');

  return `${data} ${hora}`;
}

export function getColorAleatory() {
  const letters = '0123456789ABCDEF';
  let color = '#';

  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
}

export function parseMoney2(money) {
  let formatedMoney = `${money}`.replace('.', ',');

  if (formatedMoney.split(',').length === 1) {
    if (!money) {
      formatedMoney = '00';
    }
    formatedMoney = formatedMoney + ',00';
  }

  return formatedMoney;
}

export function parseMoney(money) {
  return Number(money).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
}

export function parsePercentage(percentage) {
  const value = Number(percentage / 100);

  return value.toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 3,
  });
}

export function parseCnpj(cnpj) {
  const cnpjStr = `${cnpj}`.replace('-', '').replace('/', '').split('.').join('');

  const formatCnpj = `${cnpjStr.slice(0, 2)}.${cnpjStr.slice(2, 5)}.${cnpjStr.slice(5, 8)}/${cnpjStr.slice(
    8,
    12,
  )}-${cnpjStr.slice(12, 14)}`;

  return formatCnpj;
}

export function parseCpf(cpf) {
  const cpfStr = `${cpf}`.replace('-', '').split('.').join('');

  const formatCpf = `${cpfStr.slice(0, 3)}.${cpfStr.slice(3, 6)}.${cpfStr.slice(6, 9)}-${cpfStr.slice(9, 12)}`;

  return formatCpf;
}

export function parseBoolean(boolean) {
  const boolStr = `${boolean}`;
  let formatBoolean;
  if (boolStr == 'A' || boolStr == 'true') {
    formatBoolean = 'Ativo';
  } else {
    if (boolStr == 'D' || boolStr == 'false') {
      formatBoolean = 'Desativado';
    }
  }

  return formatBoolean;
}

export function parseCep(cep) {
  const cepStr = `${cep}`.replace('-', '');

  const formatCep = `${cepStr.slice(0, 5)}-${cepStr.slice(5, 8)}`;

  return formatCep;
}

export function parseCpfToNumber(cpf) {
  return `${cpf}`.replace('-', '').split('.').join('');
}

export function parsePhone(phone) {
  const phoneStr = `${phone}`.replace('(', '').replace(')', '').replace('-', '').replace(/ /, '');

  const formatPhone = `(${phoneStr.slice(0, 2)}) ${phoneStr.slice(2, 7)}-${phoneStr.slice(7, 11)}`;

  return formatPhone;
}

export function parsePhoneToNumber(phone) {
  return Number(`${phone}`.replace('(', '').replace(')', '').replace('-', '').replace(/ /, ''));
}

export function parseMoneyToFloat(moneyStr) {
  if (!moneyStr) {
    return undefined;
  }

  let value = `${moneyStr}`.replace('R$ ', '').replace('%', '');

  while (value.includes('.')) {
    value = value.replace('.', '');
  }

  value = value.replace(/,/g, '.');

  return value;
}

// apenas a primeira letra maiuscula
export function capitalize(string = '') {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

// a primeira letra de todas as palavras
export function capitalizeFull(string) {
  const arr = string.split(' ');

  return arr.reduce((acm, word) => {
    return `${acm} ${capitalize(word)}`;
  }, '');
}

export function shuffleArray(array: any[] = []) {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export function formatar_horas_segundos(time) {
  const [h, min, s] = time.split(':');

  return Number(h) * 3600 + Number(min) * 60 + Number(s);
}

export function formatar_segundos_horas(segundos) {
  const horas = Math.floor(segundos / 3600);
  const minutos = Math.floor((segundos - horas * 3600) / 60);
  segundos = segundos - Math.floor(segundos / 60) * 60;
  return `${('0' + horas).slice(-2)}:${('0' + minutos).slice(-2)}:${('0' + segundos).slice(-2)}`;
}

export function serializeString(str: string) {
  if (!str) return '';

  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

export function getDirectionName(angle) {
  const directions = ['Norte', 'Noroeste', 'Oeste', 'Sudoeste', 'Sul', 'Sudeste', 'Leste', 'Nordeste'];
  return directions[Math.round(((angle %= 360) < 0 ? angle + 360 : angle) / 45) % 8];
}

export function normalizeFormatDate(time: string) {
  if (!time) {
    return null;
  }

  const [date, hour] = time.split(' ');

  const [year, month, day] = date.split(/-/);

  return `${day}/${month}/${year} ${hour}`;
}

export const getDatesRange = (startDate, endDate) => {
  const dayInterval = 1000 * 60 * 60 * 24;

  const duration = endDate - startDate;
  const steps = duration / dayInterval;
  return Array.from({ length: steps + 1 }, (v, i) =>
    new Date(startDate.valueOf() + dayInterval * i).toLocaleDateString(),
  );
};

export const convertDurationtoSeconds = (duration: string) => {
  const [hours, minutes, seconds] = duration.split(':');
  return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
};

export const secondsToTime = (e) => {
  const h = Math.floor(e / 3600)
      .toString()
      .padStart(2, '0'),
    m = Math.floor((e % 3600) / 60)
      .toString()
      .padStart(2, '0'),
    s = Math.floor(e % 60)
      .toString()
      .padStart(2, '0');

  return h + ':' + m + ':' + s;
};

interface IHostDetail {
  ip: string;
  port: string;
}

export function getHostDetailByQueryString(query): IHostDetail | null {
  if (!query) {
    return null;
  }

  const host = query.split(/\//)[2];
  const [ip, port] = host.split(/:/);

  return {
    ip,
    port,
  };
}

export const removeMaskInput = (value: string) => {
  if (!value?.length) {
    return null;
  }
  return parseFloat(`${value}`.replace(/[^0-9|.]/g, ''));
};

export function normalizeDateOnly(dateonly: string) {
  if (!dateonly) return dateonly;

  const [data] = dateonly.split(/T/);

  const [year, month, day] = data.split(/-/);

  return `${day}/${month}/${year}`;
}

export function CurrencyFormat(value) {
  return new Number(value).toLocaleString('ptb', {
    style: 'currency',
    currency: 'BRL',
  });
}

export const normalizeFormSearchParamsRequest = (form) => {
  return Object.keys(form).reduce((object, key) => {
    if (form[key] !== 'DEFAULT' && form[key] !== '') {
      object[key] = form[key];
    }
    return object;
  }, {});
};

export function getUniqueListBy(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

export const yupValidationErrors = async (objects, schema) => {
  return await schema
    .validate(objects, { abortEarly: false })
    .then(() => null)
    .catch((err: any) => err.inner);
};

export const yupFormattedErrors = (validationErrors) => {
  if (!validationErrors) {
    return null;
  }
  return validationErrors.reduce(
    (acc, item) => ({
      ...acc,
      [item.path]: {
        message: item.message,
      },
    }),
    {},
  );
};

export const diferencaDiasPeriodo = (dth_inicial, dth_final) => {
  return Number(
    Math.round((new Date(dth_final).getTime() - new Date(dth_inicial).getTime()) / (1000 * 3600 * 24)).toFixed(0),
  );
};

export const adicaoDiasData = (dth, nDias) => {
  const date = new Date(new Date(dth).getTime() + nDias * 1000 * 3600 * 24);
  const [dia, mes, ano] = [`0${date.getDate()}`.slice(-2), `0${date.getMonth() + 1}`.slice(-2), date.getFullYear()];
  return `${ano}-${mes}-${dia}`;
};

export const validateRequestErrors = (request: IValidateRequestErros[]) => {
  return new Promise<any>((resolve) => {
    for (let i = 0; i < request.length; i++) {
      const { response, functionSuccess } = request[i];

      if (response.failed) {
        Toast('error', response.message);
        resolve(undefined);
        return;
      } else if (functionSuccess) {
        resolve(functionSuccess(response.data));
      } else {
        return resolve(response.data);
      }
    }
  });
};

export const replaceEmptyTextToNull = (object: Object) => {
  return JSON.parse(JSON.stringify(object).replace(/:""/g, ':null'));
};

export const filterDistinctByKey = (key: string, array: any[]) => {
  const mapped: typeof array = [];
  return array.filter((item) => {
    if (item[key] && !mapped.includes(item[key])) {
      mapped.push(item[key]);
      return true;
    }
  });
};

export const removerAcentos = (string: string) => {
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const removeRepetidos = (array: any[], chave = '') => {
  if (!!array.length) {
    const dadosLidos: typeof array = [];

    if (chave) {
      return array.filter((item) => {
        if (!dadosLidos.includes(item[chave])) {
          dadosLidos.push(item[chave]);
          return true;
        }
      });
    } else {
      return array.filter((item) => {
        if (!dadosLidos.includes(item)) {
          dadosLidos.push(item);
          return true;
        }
      });
    }
  }

  return [];
};

export function isNumber(value) {
  if (!value) {
    return false;
  }
  return /^\d*$/.test(value);
}

export const generateLastDays = (days) => {
  // eslint-disable-next-line prefer-spread
  return Array.apply(null, new Array(days))
    .map(function () {
      return new Date();
    })
    .map(function (v, i) {
      v.setDate(v.getDate() - i);
      return v;
    })
    .map(function (v) {
      return parseISOToDateStr(v.toISOString());
    })
    .reverse();
};

export function getRandom(min: number, max: number, decimalPlaces = 10) {
  return ((Math.random() * ((max - min) as any) + min) as any).toFixed(decimalPlaces) * 1;
}

export const objectFlip = (obj) => {
  const ret = {};
  Object.keys(obj).forEach((key) => {
    ret[obj[key]] = key;
  });
  return ret;
};

export function addMinutesOnDateObject(date = new Date(), minutes = 0) {
  return new Date(date.getTime() + minutes * 60000);
}

export function addHoursOnDateObject(date = new Date(), hours = 0) {
  return new Date(date.getTime() + hours * 3600000);
}

export function addMillisecondsOnDateObject(date = new Date(), milisseconds = 0) {
  return new Date(date.getTime() + milisseconds);
}

export function removeMillisecondsOnDateObject(date = new Date(), milisseconds = 0) {
  return new Date(date.getTime() - milisseconds);
}

export default {
  // parseDate,
  getColorAleatory,
  parseMoney,
  parseCnpj,
  parseCpf,
  parsePhone,
  parsePhoneToNumber,
  parseMoneyToFloat,
  capitalize,
  capitalizeFull,
};

export const formatDate = (date: string | Date) => {
  if (!date) return null;

  const formatedDate = new Date(date).toLocaleDateString([], { hour: '2-digit', minute: '2-digit' });

  return formatedDate;
};

export const formatDateIgnoringTimezone = (date: string | Date) => {
  if (!date) return null;

  const d = typeof date === 'string' ? new Date(date) : date;

  const iso = d.toISOString();

  const [dth, time] = iso.split('T');

  const [year, month, day] = dth.split('-');
  const [hour, minute] = time.split(':');

  return `${day}/${month}/${year} ${hour}:${minute}`;
};

export const removeAccentsText = (text: string): string => {
  if (!text) {
    return '';
  }
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function removeDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
}

export function removeDuplicateItemsFromArray<T = any[]>(array: T, keyAttribute: string): T {
  if (!keyAttribute) return array;

  const filteredArray = (array as any).filter((value, index, selfArray) => {
    return selfArray.findIndex((attribute) => attribute[keyAttribute] === value[keyAttribute]) === index;
  });

  return filteredArray;
}

export function filter_text(target: string, filter) {
  const words_item = target
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\w]/g, ' ')
    .trim()
    .toLowerCase()
    .split(' ');

  const words_filter = (filter || '')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\w]/g, ' ')
    .trim()
    .toLowerCase()
    .split(' ');

  const checker = (arr, filter) => filter.every((str) => arr.find((str_arr) => str_arr.includes(str)));

  return words_filter == '' || checker(words_item, words_filter);
}

export const getByPriorityValues = (strFiltered, filtro, nom_column?: string) => {
  const filtro_arr = filtro.split(' ');

  const serialize = (str) => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^\w]/g, ' ')
      .trim()
      .toLowerCase();
  };

  const splitDataReducer = (str) =>
    str.split(' ').reduce((acc, act) => {
      return acc + filtro_arr.filter((word) => serialize(word) === serialize(act)).length;
    }, 0);

  return strFiltered.sort((a, b) => {
    const countA = splitDataReducer(nom_column ? a[nom_column] : a);
    const countB = splitDataReducer(nom_column ? b[nom_column] : b);

    return countB - countA;
  });
};

export const filterValues = (values_to_filter: string[] | Object[], filtro_input: string, nom_column?: string) => {
  const filtro_arr = filtro_input?.split(' ') || [];

  const getByPriority = () => {
    const serialize = (str = '') => {
      return str
        ?.normalize?.('NFD')
        ?.replace(/[\u0300-\u036f]/g, '')
        ?.replace(/[^\w]/g, ' ')
        ?.trim()
        ?.toLowerCase();
    };

    function filter_text(target, filter) {
      const words_item = serialize(target).split(' ');

      const words_filter = serialize(filter || '').split(' ');

      const checker = (arr, filter) => filter.every((str) => arr.find((str_arr) => str_arr.includes(str)));

      return filter == '' || checker(words_item, words_filter);
    }

    const splitDataReducer = (str) =>
      str.split(' ').reduce((acc, act) => {
        return acc + filtro_arr.filter((word) => serialize(word) === serialize(act)).length;
      }, 0);

    const filtered = values_to_filter.filter((item) => filter_text(nom_column ? item[nom_column] : item, filtro_input));

    return filtered.sort((a, b) => {
      const countA = splitDataReducer(nom_column ? a[nom_column] : a);
      const countB = splitDataReducer(nom_column ? b[nom_column] : b);

      return countB - countA;
    });
  };

  return getByPriority();
};

export const getDataSemFuso = () => {
  const tzoffset = new Date().getTimezoneOffset() * 60000;
  const localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -1);

  return localISOTime;
};

export function chunkArray(myArray: any[] = [], chunk_size) {
  const results: any[] = [];
  const arr = [...myArray];

  while (arr.length) {
    results.push(arr.splice(0, chunk_size));
  }

  return results;
}
export const execute_by_section = (array: any[], max: number, method: any) => {
  if (!array.length) return;

  let count = 1;

  while (true) {
    const inicial = max * (count - 1);
    const final = max * count;

    const dados = array.slice(inicial, final);

    if (!!dados.length) {
      method(dados);
      count++;
    } else {
      break;
    }
  }
};

export const checkAreaClick = (latlng: [number, number], centerLatlng: [number, number], radiusInkm: number) => {
  const ky = 40000 / 360;
  const kx = Math.cos((Math.PI * centerLatlng[0]) / 180.0) * ky;
  const dx = Math.abs(centerLatlng[1] - latlng[1]) * kx;
  const dy = Math.abs(centerLatlng[0] - latlng[0]) * ky;

  return Math.sqrt(dx * dx + dy * dy) <= radiusInkm;
};

export const segundosParaHora = (seg: number) => {
  const date = new Date();
  date.setSeconds(seg);
  return date.toISOString().substr(11, 8);
};

export const horaMinutoToSeg = (hora: string) => {
  const [h = 0, m = 0, s = 0] = hora.split(':');

  const h_segundos = Number(h) * 60 * 60;

  const m_segundos = Number(m) * 60;

  return h_segundos + m_segundos + Number(s);
};

export const timestampWithoutTZ = (dateISO: string | Date) => {
  if (!dateISO) return null;

  if (typeof dateISO !== 'string') {
    dateISO = new Date(dateISO).toLocaleString('pt-BR').replace(/(\d{2})\/(\d{2})\/(\d{4})(\W)(.*)/, '$3-$2-$1 $5');
  }

  return dateISO
    .replace(/^(\d)(\d)(\d)(\d)-(\d{2})-(\d{2})(\W)(\w{2}):(\w{2}):(\w{2})/, '$1$2$3$4-$5-$6 $8:$9:$10')
    .replace(/(\.\d\w{1,})/, '');
};

export const timestampBR = (dateISO: string | Date, asString = false, curto = true) => {
  if (!dateISO) return null;

  if (new Date(dateISO).getFullYear() != new Date().getFullYear()) curto = false;

  if (typeof dateISO !== 'string') {
    dateISO = new Date(dateISO).toLocaleString('pt-BR').replace(/(\d{2})\/(\d{2})\/(\d{4})(\W)(.*)/, '$3-$2-$1 $5');
  }

  return dateISO
    .replace(
      /^(\d)(\d)(\d)(\d)-(\d{2})-(\d{2})T(\w{2}):(\w{2}):(\w{2})/,
      (curto ? '$6/$5/$3$4 ' : '$6/$5/$1$2$3$4 ') +
        (asString ? ' às ' : curto ? ' - ' : '') +
        `$7:$8${curto ? '' : ':$9'}`,
    )
    .replace(/(\.\d\w{1,})/, '');
};

export const dateBR = (dateISO: string | Date) => {
  if (!dateISO) return null;

  if (typeof dateISO !== 'string') {
    dateISO = new Date(dateISO).toLocaleString('pt-BR').replace(/(\d{2})\/(\d{2})\/(\d{4})(\W)(.*)/, '$3-$2-$1 $5');
  }

  return dateISO.replace(/^(\d{4})-(\d{2})-(\d{2})(.*)/, '$3/$2/$1');
};

export const replace_freetime = (str: string) =>
  str
    .replace(/\D/g, '')
    .replace(/:/g, '')
    .replace(/(.*)(\d{2})$/, '$1:$2')
    .replace(/(.*)(\d{2}):(.*)/, '$1:$2:$3')
    .replace(/^(:)(.*)/, '$2')
    .replace(/^(\d{1}):(.*)/, '0$1:$2')
    .replace(/0{3,}:/g, '00:')
    .replace(/0{1,}(\d{2,})/g, '$1')
    .replace(/^(\d{1,})$/, '$1:00:00') || '00:00:00';

export const getTextTime = (h: number, m: number, s: number) => {
  function getHorasDias(horas: number) {
    const resto = horas % 24;

    const horasUteis = horas - resto;

    const dias = horasUteis / 24;

    return { dias, horas: resto };
  }

  function getMinutosHoras(minutos: number) {
    const resto = minutos % 60;

    const minutosUteis = minutos - resto;

    const horas = minutosUteis / 60;

    return { horas, minutos: resto };
  }

  function getSegundosMinutos(segundos: number) {
    const resto = segundos % 60;

    const minutosUteis = segundos - resto;

    const minutos = minutosUteis / 60;

    return { minutos, segundos: resto };
  }

  const { minutos: minutosDerivados, segundos } = getSegundosMinutos(s);

  const { horas: horasDerivadas, minutos } = getMinutosHoras(m + minutosDerivados);

  const { dias, horas } = getHorasDias(h + horasDerivadas);

  const useS = (value: number) => (value === 1 ? '' : 's');

  const response: string[] = [];

  if (dias) response.push(`${dias} dia${useS(dias)}`);
  if (horas) response.push(`${horas} hora${useS(horas)}`);
  if (minutos) response.push(`${minutos} minuto${useS(minutos)}`);
  if (segundos) response.push(`${segundos} segundo${useS(segundos)}`);

  return response
    .join(', ')
    .replace(/,([^,]+)$/, ' e $1')
    .replace(/\s{2,}/g, ' ');
};

export const copyTextToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (error) {
    return false;
  }
};

export const extract = (date: Date | string, type: 'date' | 'time') => {
  if (date instanceof Date) date = date.toISOString();

  switch (type) {
    case 'date':
      return date.replace(/(\d{4})-(\d{2})-(\d{2})(.*)/, '$3/$2/$1');
    case 'time':
      return date.replace(/(\d{4})-(\d{2})-(\d{2})(T|\s)(\d{2}):(\d{2})(.*)/, '$5:$6');
    default:
      return date;
  }
};

interface ICheckPoint {
  lat: number;
  lng: number;
}

export const getPointDistanceInKm = (checkPoint: ICheckPoint, centerPoint: ICheckPoint) => {
  const ky = 40000 / 360;
  const kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky;
  const dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
  const dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;

  return Math.sqrt(dx * dx + dy * dy);
};

export const destructuring = <T extends { children?: Array<T> }>(structure: T[]) => {
  if (!structure?.length) return [];

  const response: T[] = [];

  for (const item of structure) {
    response.push(item);

    if (!!item.children?.length) {
      response.push(...destructuring(item.children));
    }
  }

  return response;
};

export const getValorObjetoCascata = (keys: string[], object: object, keyTarget: string) => {
  let retorno = undefined;

  for (const [index, key] of keys.entries()) {
    if (index === 0) retorno = object?.[key];
    else retorno = retorno?.[key];

    if (key === keyTarget) return retorno;
  }

  return retorno;
};

export const setValorObjetoCascata = (keys: string[], prevObject: object, valor: unknown) => {
  const objeto = {};
  const keysReverse = JSON.parse(JSON.stringify(keys));
  keysReverse.reverse();

  for (const [index, key] of keysReverse.entries()) {
    const prevValue = getValorObjetoCascata(keys, prevObject, key);
    const newValue = !index ? valor : objeto[keysReverse[index - 1]];

    const isObject =
      (typeof prevValue === 'object' || [null, undefined].includes(prevValue)) &&
      (typeof newValue === 'object' || [null, undefined].includes(newValue)) &&
      (!!newValue || !!prevValue) &&
      !Array.isArray(newValue) &&
      !Array.isArray(prevValue);

    const value =
      isObject && ![null, undefined].includes(keysReverse[index - 1])
        ? { ...(prevValue || {}), [keysReverse[index - 1]]: newValue }
        : !index
        ? newValue
        : { [keysReverse[index - 1]]: newValue };

    objeto[key] = value;
  }

  const baseKey = keys[0];

  return { [baseKey]: objeto[baseKey] };
};

export const hexToRgb = (hex: string, convertToString?: boolean) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const hexSerialized = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexSerialized);
  const r = parseInt(result?.[1] || '', 16);
  const g = parseInt(result?.[2] || '', 16);
  const b = parseInt(result?.[3] || '', 16);
  const resultSerialized = !convertToString ? { r, g, b } : `${r},${g},${b}`;

  return result ? resultSerialized : null;
};

export const isValidImg = async (base64String: string) => {
  const image = new Image();
  image.src = base64String;

  return new Promise<boolean>((resolve) => {
    image.onload = () => resolve(image.height !== 0 && image.width !== 0);
    image.onerror = () => resolve(false);
  });
};

export const formatNumbers = (number: number, digits = 0) =>
  number?.toLocaleString?.('pt-BR', { minimumFractionDigits: digits });

export const jsoncopy = function (obj) {
  return JSON.parse(JSON.stringify(obj));
};

export const getValueByArrayKeys = (obj: object, keys: string[]): object => {
  return keys.reduce((acc, act, index) => {
    if (!index) return obj[act];

    return acc[act];
  }, {});
};

export const goUntil = (arr: string[], target: string) => {
  const indexLimit = arr.indexOf(target);

  return arr.slice(0, indexLimit + 1);
};

/**
 * @fn Deve ser uma regular function, não uma arrow function, caso queria utilizar o **this.increment** e acessar o valor da iteração na callback
 * @loops Quantidade de vezes a executar a repetição
 * @example
 * // retorna ['posição: 1', 'posição: 2', 'posição: 3']
 * [...repeat(function () { return 'posição:' + this.increment }, 3)]
 * @example
 * // retorna { value: 'posição: 1', done: false }
 * repeat(function () { return 'posição:' + this.increment }, 3).next()
 */
export function repeat<T extends (...args: any) => any>(fn: T, loops: number): Generator<ReturnType<T>, void, unknown> {
  return _repeat.bind({ increment: 0 })(fn, loops);
}
function* _repeat<T extends (...args: any) => any>(
  this: any,
  fn: T,
  loops: number,
): Generator<ReturnType<T>, void, unknown> {
  for (let i = 1; i <= loops; i++) {
    if (this) {
      (this as any).increment = i;
    }

    yield fn.bind(this)();
  }
}

export const generate_key_by_attributes = (obj: object, keyAttribute: string) => {
  return keyAttribute.split(':').reduce((acc, act) => `${acc}-${obj[act]}`, '');
};

type IEachEstruturaCb<T = any> = (itemEstrutura: T, parent: T, index: number, arr: T[]) => boolean | void;

export const eachEstrutura = <T = any>(
  arr_estrutura: T[] = [],
  callbackItem: IEachEstruturaCb<T>,
  removeMemoryAddressing = false,
) => {
  const arrSerialized = Array.isArray(arr_estrutura) ? arr_estrutura : [];
  const arrLocal: T[] = !removeMemoryAddressing ? arrSerialized : JSON.parse(JSON.stringify(arrSerialized));

  const each = (arr: any[], parent) => {
    arr.some((itemEstrutura, index: number) => {
      const breakLoop = callbackItem?.(itemEstrutura, parent, index, arr);
      return breakLoop || each(itemEstrutura?.children || [], itemEstrutura);
    });
  };

  each(arrLocal, null);

  return arrLocal;
};

export interface IFileInfo {
  id: string;
  url?: string;
  file?: File;
  name: string;
  type: string;
  date: string;
}

export const serializeLocalFile = (file: FileList | File[]) => {
  const files = [...(file || [])];

  if (!files.length) return [] as IFileInfo[];

  const reader = new FileReader();
  const arquivosInfo: IFileInfo[] = [];

  const promise = new Promise<IFileInfo[]>((resolve, reject) => {
    try {
      const readFile = async (index: number) => {
        const file = files[index];

        reader.onload = (e) => {
          const url = e?.target?.result as string;
          const splitedName = file.name.split('.');
          const extension = splitedName[splitedName.length - 1];

          arquivosInfo.push({
            file,
            url,
            id: `${generateHash()}.${extension}`,
            type: file?.type,
            name: file?.name,
            date: new Date().toISOString(),
          });

          const isLastFile = index === files.length - 1;

          if (isLastFile) {
            resolve(arquivosInfo);
            return;
          }

          readFile(index + 1);
        };

        reader.readAsDataURL(file);
      };

      readFile(0);
    } catch (error) {
      reject(error);
    }
  });

  return promise;
};

interface ICoords {
  latitude: number;
  longitude: number;
}

export const checkPointDistanceByCoords = (coordsPoint: ICoords, coordsAnchor: ICoords) => {
  const ky = 40000 / 360;
  const kx = Math.cos((Math.PI * coordsAnchor.latitude) / 180.0) * ky;
  const dx = Math.abs(coordsAnchor.longitude - coordsPoint.longitude) * kx;
  const dy = Math.abs(coordsAnchor.latitude - coordsPoint.latitude) * ky;

  return Math.sqrt(dx * dx + dy * dy);
};

export const checkPointInAreaByCoords = (coordsPoint: ICoords, coordsAnchor: ICoords, km: number) => {
  return checkPointDistanceByCoords(coordsPoint, coordsAnchor) <= km;
};
