import React, { useContext } from 'react';
import { FrameData } from '../../components/FrameData';
import iframeContext, { IIframeContext } from '../../contexts/IframeContext';
import { useToast } from '../../hooks/toast';
import { RouteComponentProps } from 'react-router-dom';
import { prefix } from '../../router';
import { RadioSearchContainer } from './styles';
import { Title } from 'components/AutoRow/components/Title';
import { Paragraph } from 'components/AutoRow/components/Paragraph';
import { Button } from 'components/AutoRow/components/Button';
import { AutoRow } from 'components/AutoRow';

const IframeGenerate = ({ history }: RouteComponentProps) => {
  const toast = useToast();
  const { iframeState, setIframeState } = useContext<IIframeContext>(iframeContext);

  function handleSelectTipoBusca(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    if (setIframeState) {
      setIframeState((oldValue) => ({
        ...oldValue,
        tipoBusca: value,
      }));
    }
  }

  function handleRedirectToGenerateOcorrencia() {
    if (iframeState && iframeState.tipoBusca === 'plaqueta') {
      history.push(`${prefix}/generate/tag`);
    } //
    else if (iframeState && iframeState.tipoBusca === 'endereco') {
      history.push(`${prefix}/generate/address`);
    } //
    else {
      toast.addToast({
        type: 'warn',
        description: 'Tipo de busca invalido!',
      });
    }
  }

  function handleCancel() {
    history.push(`${prefix}/home`);
  }

  return (
    <FrameData>
      <AutoRow>
        <Title label="Geração de Ocorrência" />
        <Paragraph
          marginBottom={26}
          marginTop={-4}
          label="Caso não possua a etiqueta do poste, utiize o campo de endereço para prosseguir com a arbetura do chamado"
        />
        <RadioSearchContainer>
          <input
            checked={iframeState && iframeState.tipoBusca === 'plaqueta'}
            id="busca-plaqueta"
            name="tipo-busca"
            type="radio"
            value="plaqueta"
            onChange={handleSelectTipoBusca}
          />{' '}
          <label htmlFor="busca-plaqueta">Busca por plaqueta (Número presente no próprio ponto)</label>
        </RadioSearchContainer>
        <RadioSearchContainer>
          <input
            checked={iframeState && iframeState.tipoBusca === 'endereco'}
            id="busca-endereco"
            name="tipo-busca"
            type="radio"
            value="endereco"
            onChange={handleSelectTipoBusca}
          />{' '}
          <label htmlFor="busca-endereco">Busca por endereço (Endereço próximo ao ponto)</label>
        </RadioSearchContainer>
        <Button onClick={handleCancel} backgroundColor="light">
          Cancelar
        </Button>
        <Button onClick={handleRedirectToGenerateOcorrencia}>Continuar</Button>
      </AutoRow>
    </FrameData>
  );
};

export default IframeGenerate;
