import React from 'react';
import { RowGrid } from './styles';

interface IAutoRow {
  children?: React.ReactNode;
  margin?: string;
}

export const AutoRow: React.FC<IAutoRow> = (props) => {
  const { children, margin } = { ...props };
  return <RowGrid margin={margin}>{children}</RowGrid>;
};
