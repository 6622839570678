import { apiNominatim, IApiResponse } from '../index';

export async function getLatLonByUFAndCity(UF: string, cidade: string, street: string): Promise<IApiResponse> {
  const url = `/search?format=json&country=Brasil&state=${UF}&city=${cidade}&street=${street}`;

  return await apiNominatim.get(url);
}

export async function getAdressByLatLong(lat: number, long: number): Promise<IApiResponse> {
  return await apiNominatim.get(`/reverse?format=jsonv2&lat=${lat}&lon=${long}`);
}

export default {
  getLatLonByUFAndCity,
  getAdressByLatLong,
};
