import { IListOcorrencia, IResponseOcorrencia } from '../../dtos/Ocorrencia';
import React, { useCallback, useEffect, useState } from 'react';
import { cancelarOcorrencia, getOcorrenciasByIdReclamante, reclamanteLogout } from '../../api/services/Reclamante';
import { FrameData } from '../../components/FrameData';
import { IReclamante } from '../../dtos/Reclamante';
import { RouteComponentProps } from 'react-router-dom';
import { prefix } from '../../router';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useToast } from '../../hooks/toast';
import { LoadingComponent } from 'components/Loading';
import { AutoRow } from 'components/AutoRow';
import { Title } from 'components/AutoRow/components/Title';
import { Paragraph } from 'components/AutoRow/components/Paragraph';
import { Button } from 'components/AutoRow/components/Button';
import { TableHome } from './TableHome';

const IframeHome = ({ history }: RouteComponentProps) => {
  const toast = useToast();
  const [accessToken, setAccessToken] = useLocalStorage<string | undefined>('accessToken', '');
  const [refreshToken, setRefreshToken] = useLocalStorage<string | undefined>('refreshToken', '');
  const [codParqueServico] = useLocalStorage<number>('cod_parque_servico', -1);
  const [dadosReclamante, setDadosReclamante] = useLocalStorage<IReclamante>('reclamante', {});
  const [ocorrencias, setOcorrencias] = useState<IListOcorrencia>();
  const [loading, setLoading] = useState(true);

  const loadOcorrenciasReclamante = useCallback(async () => {
    const response = await getOcorrenciasByIdReclamante(Number(dadosReclamante.seq_reclamante), {
      cod_parque_servico: codParqueServico,
    });
    setLoading(false);

    if (response.err || response.resp.err) {
      toast.addToast({
        type: 'warn',
        description: 'Ocorreu um erro ao carregar as ocorrencias do usuario!',
      });
      return;
    }

    setOcorrencias(response.resp);
  }, [toast, dadosReclamante]);

  function handleRedirectToGenerate() {
    history.push('generate');
  }

  function handleRedirectToRating(item: IResponseOcorrencia) {
    history.push({
      pathname: '/home/rating',
      search: `?code=${item.seq_atendimento_ponto_servico}&cod_ops=${item.seq_ocorrencia_ponto_servico}`,
    });
  }

  const handleClickRemoveOcorrencia = async (item: IResponseOcorrencia) => {
    const { seq_ocorrencia_ponto_servico } = item;

    setLoading(true);
    const response = await cancelarOcorrencia(Number(seq_ocorrencia_ponto_servico));

    if (response.failed) {
      setLoading(false);
      toast.addToast({
        type: 'error',
        description: response.message,
      });
      return;
    }
    loadOcorrenciasReclamante();
    setLoading(false);
  };

  function handleLogout() {
    setAccessToken('');
    setRefreshToken('');
    setDadosReclamante({});

    history.push(`${prefix}/`);

    async function fetchData() {
      await reclamanteLogout(accessToken);
    }
    fetchData();
  }

  const handleIconBlock = useCallback(() => {
    toast.addToast({
      type: 'warn',
      description: 'No momento essa ocorrência não pode ser avaliado',
    });
  }, []);

  useEffect(() => {
    if (!accessToken && !refreshToken && !dadosReclamante) {
      history.push(`${prefix}/`);
    }
  }, [accessToken, refreshToken, dadosReclamante]);

  useEffect(() => {
    loadOcorrenciasReclamante();

    return () => setOcorrencias([]);
  }, [history, loadOcorrenciasReclamante]);

  return (
    <FrameData loading={loading}>
      <AutoRow>
        <Title label="Ocorrências" />

        <Paragraph marginBottom={16} marginTop={-4} label="Navegue por suas ocorrências" />

        <TableHome
          ocorrencias={ocorrencias || []}
          onClickRate={(item) => handleRedirectToRating(item)}
          onClickDelete={(item) => handleClickRemoveOcorrencia(item)}
        />
        <Button onClick={handleLogout} backgroundColor="light">
          Sair
        </Button>
        <Button onClick={handleRedirectToGenerate}>Criar uma ocorrência</Button>
      </AutoRow>
    </FrameData>
  );
};

export default IframeHome;
