import React, { useEffect } from 'react';
import { Container, Label } from './styles';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import { isNumber } from '../../hooks/helpers/methods';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { checkStringContains } from './utils';

interface IOption {
  value: string | number;
  label: string;
}

export type IValueSelectAutocomplete = (string | number)[] | string | number | boolean;
export type IHandleFunctionSelectAutocomplete = (event: { name: string; value: IValueSelectAutocomplete }) => void;

interface ISelectAutocomplete {
  label?: string | number;
  margin?: string;
  place?: string;
  handleFunction?: IHandleFunctionSelectAutocomplete;
  array: any;
  descricao: string;
  disabled?: boolean;
  noOptions?: string;
  defaultValue?: string;
  value?: IValueSelectAutocomplete;
  state?: any;
  setState?: any;
  placeHolder?: string;
  isMulti?: boolean;
  removeDefaultOption?: boolean;
  hideSelectedOptions?: boolean;
  keyAttribute?: string;
  defaultMessage?: string;
  stretch?: number;
  loading?: boolean;
  isClearable?: boolean;
  children?: any;
  name: string;
  filterItems?(value: string | number): boolean;
  onValueChange?: (item: any) => void;
  initialAutoSelection?: boolean;
  onInput?: (newValue: string) => void;
  hidden?: boolean;
  obra?: boolean;
}

export const SelectAutocomplete: React.FC<ISelectAutocomplete> = (props) => {
  const {
    label,
    margin,
    name,
    hideSelectedOptions,
    placeHolder = 'Selecione',
    noOptions,
    descricao,
    handleFunction,
    value,
    keyAttribute,
    defaultValue,
    isMulti,
    disabled,
    array,
    state,
    setState,
    place,
    loading,
    stretch,
    onValueChange,
    filterItems,
    initialAutoSelection: allowInitialAutoSelection = false,
    hidden,
    obra,
    onInput,
  } = { ...props };
  const containerRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const theme: any = useTheme();
  const [initialAutoSelection, setInitialAutoSelection] = React.useState(allowInitialAutoSelection);

  const options = React.useMemo(() => {
    return array?.map((arrayValue) => ({ value: arrayValue[keyAttribute || ''], label: arrayValue[descricao] })) || [];
  }, [array, keyAttribute, descricao]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: obra ? '#ffffff' : '#F5F5F5',
      borderRadius: '10px',
      borderWidth: obra ? 0 : '0.8px',
      borderColor: '#eae7e7',
      boxShadow: 'none',
      color: '#6e6e6e',
      fontFamily: 'Roboto',
      fontSize: '12px',
      minHeight: obra ? '20px' : '40px',
      maxHeight: obra ? '20px' : '40px',
      '@media (max-width: 1610px)': {
        minHeight: obra ? '20px' : '35px',
        maxHeight: obra ? '20px' : '35px',
      },
      '&:hover': {
        borderColor: '#7764e4',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: disabled ? '#a8a8a8' : '#6e6e6e',
      fontFamily: 'Roboto',
      fontSize: '10px',
    }),
    menu: (provided) => ({
      ...provided,
      color: '#6e6e6e',
      fontFamily: 'Roboto',
      fontSize: obra ? '10px' : '12px',
      borderRadius: '10px',
      border: '#eae7e7',
      backgroundColor: '#f5f5f5',
      zIndex: 11,
      overflow: 'hidden',
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: 'Roboto',
      fontSize: obra ? '10px' : '12px',
      color: '#6e6e6e',
      background: state.isSelected ? '#eaeaea' : '#f5f5f5',
      '&:hover': {
        background: '#e6e6e6',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      fontFamily: 'Roboto',
      fontSize: obra ? '10px' : '12px',
      color: '#6e6e6e',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: disabled ? '#a8a8a8' : '#6e6e6e',

      '& svg': {
        height: obra ? '14px' : '20px',
        width: obra ? '14px' : '20px',
      },
    }),
    clearIndicator: (base) => ({
      ...base,
      color: disabled ? '#a8a8a8' : '#6e6e6e',

      '& svg': {
        height: obra ? '14px' : '20px',
        width: obra ? '14px' : '20px',
      },
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: obra ? '20px' : '',
    }),
    inputContainer: (provided) => ({
      ...provided,
      height: obra ? '20px' : '',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0',
      padding: '0',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 8px',
    }),
  };

  const getDefaultValue = React.useMemo(() => (defaultValue ? defaultValue : 'DEFAULT'), [defaultValue]);

  const optionsSelected = React.useMemo(() => {
    const values = Array.isArray(value) ? value : [value];
    return options?.filter?.((option) => values.some((v) => option.value == v));
  }, [value, options]);

  const onChange = (data: IOption | IOption[]) => {
    const valueIsArray = (param): param is IOption[] => Array.isArray(data);
    const value = valueIsArray(data) ? data.map((option) => option?.value) : data?.value;

    if (handleFunction) {
      handleFunction({ name, value });
    }

    if (setState && state) {
      setState({ ...state, [name]: value });
    }

    if (onValueChange) {
      onValueChange({
        name,
        value,
        numberValue: !valueIsArray && isNumber(value) ? Number(value) : 0,
      });
    }
  };

  const filterOption = (option: FilterOptionOption<any>, inputValue: string): boolean => {
    const { label } = option;
    return checkStringContains(label, inputValue);
  };

  const menuPortalTarget = React.useMemo(() => {
    let parentElement = containerRef?.current?.parentElement;

    while (parentElement && parentElement?.tagName !== 'body') {
      if (parentElement?.classList?.contains('sidemodal')) {
        return parentElement;
      }

      parentElement = parentElement?.parentElement;
    }

    return null;
  }, [options]);

  React.useEffect(() => {
    setInitialAutoSelection(allowInitialAutoSelection);
  }, [array, allowInitialAutoSelection]);

  useEffect(() => {
    if (initialAutoSelection && array?.length == 1 && !optionsSelected?.length) {
      onChange({
        value: array[0]?.[keyAttribute || ''],
        label: array[0]?.[descricao],
      });
      setInitialAutoSelection(false);
    }
  }, [array, optionsSelected, initialAutoSelection]);

  return (
    <Container ref={containerRef} margin={margin} place={place} stretch={stretch} hidden={hidden} obra={obra}>
      {label && !obra && <Label>{label}</Label>}
      <Select
        className="react-select-container"
        onInputChange={onInput}
        classNamePrefix="react-select"
        menuPortalTarget={menuPortalTarget}
        onChange={onChange}
        isLoading={loading}
        name={name}
        filterOption={filterOption}
        value={optionsSelected}
        isDisabled={disabled || loading}
        defaultValue={getDefaultValue}
        isMulti={isMulti}
        hideSelectedOptions={hideSelectedOptions}
        styles={customStyles}
        options={typeof filterItems === 'function' ? options.filter((item) => filterItems(item.value)) : options}
        isClearable
        placeholder={placeHolder}
        noOptionsMessage={() => noOptions}
        menuPlacement={obra ? 'top' : 'bottom'}
      />
    </Container>
  );
};
