import styled from 'styled-components';

export const ContainerToast = styled.div`
  display: flex;

  svg {
    padding-left: 5px;
  }

  span {
    font-family: 'Roboto', serif;
    font-size: 14px;
    padding: 2px 10px;
    font-weight: 500;
  }
`;
